import dayjs, { Dayjs } from 'dayjs'; import moment from "moment";

export const convertTimeToUTC = (time: string) => {
    // Assuming current date for the time
    const currentDate = moment().format('YYYY-MM-DD');
    const dateTimeString = `${currentDate} ${time}`;

    // Create a moment object in local time zone with the given date and time
    const localDateTime = moment(dateTimeString, 'YYYY-MM-DD HH:mm');

    // Convert to UTC and format as ISO string, but only get the time part
    const utcTime = localDateTime.utc().format('HH:mm');

    return utcTime;
};

export const convertDateTimeToUTC = (date: Dayjs) => {
    // Convert the Dayjs object to a string in the required format
    const dateTimeString = date.format('YYYY-MM-DDTHH:mm:ss.SSS');

    // Create a moment object in local time zone with the given date and time string
    const localDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss.SSS');

    // Convert to UTC and format as ISO string
    const utcDateTime = localDateTime.utc().toISOString();

    return utcDateTime;
};

export const convertTimeFromUTC = (utcTime: string) => {
    // Create a moment object in UTC with the given time
    const utcDateTime = moment.utc(utcTime, 'HH:mm');

    // Convert to local time and format as desired
    const localTime = utcDateTime.local().format('HH:mm');

    return localTime;
};

export const convertUTCDateTimeToLocal = (utcDateTime: string) => {
    // Create a moment object in UTC with the given date-time string
    const utcMoment = moment.utc(utcDateTime);

    // Convert to local time and format as ISO string
    const localDateTime = utcMoment.local().toISOString();

    return localDateTime;
};