import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import CompneyInfo from "../compneyInfo";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  Modal,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate, useParams } from "react-router";
// import fetchWithHeaders from './manageInterceptor';
import axios, { AxiosResponse, AxiosError } from "axios";
import { GET_STORE_ADDRESS } from "../../../graphicalQl/usequery/locationQuery";
import { useMutation, useQuery } from "@apollo/client";
import ForgetPasswordPopup from "./forgetPasswordPopup";
import { useSelector } from "react-redux";
import TwoFactorVerification from "./twoFactorVerification";
import VerificationalCode from "./verificationalCode";
import SecureLS from "secure-ls";
import bcrypt from "bcryptjs";
// import  credentials  from 'credentials-store';
import "./index.css";
import {
  GENERATE_OTP,
  VALIDATE_OTP,
} from "../../../graphicalQl/mutation/customerDataMutation";
import { GETTING_IS_ENABLED } from "../../../graphicalQl/usequery/userProfileUseQuery";
import ReCAPTCHA from "react-google-recaptcha";
// import Cryptr from 'cryptr';
const defaultTheme = createTheme();

const SignUpWrapper = styled("div")({
  // ".MuiFormControlLabel-root": {
  //   // marginLeft: "4px",
  // },
  // ".css-ahj2mt-MuiTypography-root": {
  //   fontSize: "14px",
  // },
});

const initialValues = {
  email: "",
};

const initialValuesForSignIn = {
  email: null,
  password: null,
  isRemembered: false,
  recaptchaToken: "",
};
const initialValuesForOtp = {
  otp: [],
  mobile: null,
  remember: false,
};

const validation = Yup.object({
  email: Yup.string().required("Email Required"),
});

const validationForOtp = Yup.object({
  // otp: Yup.string().required("OTP Required"),
});

const validationForSignIn = Yup.object({
  email: Yup.string().required("Email Required"),
  password: Yup.string().required("Password Required"),
  recaptchaToken: Yup.string().required("Please check 'I'm not a robot'"),
});

export default function SignIn() {
  const ls = new SecureLS({ encodingType: "aes", isCompression: false });

  // const credentialsStore=credentials();
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [userPasswordError, setUserPasswordError] = React.useState(false);
  const [loginfailureError, setLoginFailureError] = useState<boolean>(false);
  const [OtpValidationFailure, setOtpValidationFailure] =
    useState<boolean>(false);
  const [enableTwoFactorVerification, setEnableTwoFactorVerification] =
    React.useState<boolean>(false);
  // const [otp, setOtp] = useState("");

  const [forgetPasswordOpen, setForgetPasswordOpen] =
    React.useState<boolean>(false);
  const { data, loading, error } = useQuery(GETTING_IS_ENABLED, {
    context: { clientName: "businessClient" },
    variables: { businessId: businessId && businessId },
  });
  useEffect(() => {
    if (data) {
      const { business } = data;
      if (business) {
        const { enableTwoStepVerification } = business;
        setEnableTwoFactorVerification(enableTwoStepVerification);
      }
    }
  }, [data]);
  const handleForgetPasswordClose = () => {
    setForgetPasswordOpen(false);
    formikForForgotPassword.resetForm();
  };

  const [generateOTPMutation] = useMutation(GENERATE_OTP, {
    onCompleted: (data) => {
      if (data) {
        const { customerOtpGeneration } = data;
        const { authenticate } = customerOtpGeneration;
        const { otp } = authenticate;

        if (otp && enableTwoFactorVerification) {
          setVerificationCode(true);
          setTwoStepVerification(false);
        } else if (otp && !enableTwoFactorVerification)
          setVerificationCode(true);
        setTwoStepVerification(false);
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
  });
  useEffect(() => {
    const myBooleanValueFor90Days = getCookie("rememberFor90Days");

    formikForOtpValidation.setFieldValue("remember", myBooleanValueFor90Days);
  }, []);
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;

  // Function to get the value of a cookie
  function getCookie(name: any) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return c.substring(nameEQ.length, c.length) === "1";
    }
    return null;
  }
  const myBooleanValueFor90Days = getCookie("rememberFor90Days");

  // Set the boolean value in a cookie for 90 days

  function setCookie(name: any, value: any, days: any) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value ? "1" : "0") + expires + "; path=/";
  }
  const [validateOTPMutation] = useMutation(VALIDATE_OTP, {
    onCompleted: (data) => {
      if (data) {
        const { customerAuthentication } = data;
        const { success } = customerAuthentication;
        if (success) {
          setOtpValidationFailure(false);
          navigate("/");
          if (formikForOtpValidation.values.remember) {
            setCookie("rememberFor90Days", true, 90);
          } else {
            setCookie("rememberFor90Days", false, 90);
          }
        } else {
          setOtpValidationFailure(true);
        }
      }
    },
    onError: (error) => {
      if (error.message) {
        setOtpValidationFailure(true);
      }
    },
    context: { clientName: "userClient" },
  });

  const [isEnabled, setIsEnabled] = useState<boolean>(false);
  const [countryName, setCountryName] = useState<null | string>(null);
  const [twoStepVerification, setTwoStepVerification] =
    React.useState<boolean>(false);
  const [verificationCode, setVerificationCode] =
    React.useState<boolean>(false);
  const handleCloseForVerificationCode = () => {
    setVerificationCode(false);
  };
  const generateOTP = () => {
    generateOTPMutation({
      variables: {
        mobile: formikForOtpValidation.values.mobile,
        businessId: +businessId,
      },
    });
  };

  const generateOTPIfEnableTwofactor = (mobile: string) => {
    generateOTPMutation({
      variables: {
        mobile: mobile,
        businessId: +businessId,
      },
    });
  };

  const verifyOTP = () => {
    validateOTPMutation({
      variables: {
        mobile: formikForOtpValidation.values.mobile,
        businessId: +businessId,
        otp: formikForOtpValidation.values.otp.join(""),
      },
    });
  };

  const handleCloseForTwoStepVerificationCode = () => {
    setTwoStepVerification(false);
  };

  const formikForForgotPassword = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      handleContinueButtonInForgotPassword();
    },
  });

  const formikForOtpValidation = useFormik({
    initialValues: initialValuesForOtp,
    validationSchema: validationForOtp,
    onSubmit: (values) => {
      verifyOTP();
    },
  });

  const formikForSignIn = useFormik({
    initialValues: initialValuesForSignIn,
    validationSchema: validationForSignIn,
    onSubmit: (values) => {
      handleSignupSubmitButton();
    },
  });

  const handleContinueButtonInForgotPassword = () => {
    const url = "https://apidomain.bayforall.co.uk/v1/user/password-recovery";
    // const url="http://51.75.170.243:5001/roles/passwordrecovery"
    const userEmail = { email: formikForForgotPassword.values.email };
    axios
      .put(url, userEmail, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-BUSINESS-ID": businessId,
        },
      })
      .then((response) => {})
      .catch((error) => {
        console.error("Error updating password recovery details:", error);
      });
    handleForgetPasswordClose();
  };

  const { locationId } = useSelector((state: any) => state.headerLocation);

  const handleRemindMeNextTimeButton = () => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  };

  const handleLoginApi = async () => {
    const apiUrl = "https://apidomain.bayforall.co.uk/v1/auth/token";
    const requestData = {
      username: formikForSignIn.values.email,
      password: formikForSignIn.values.password,
    };

    try {
      const response: AxiosResponse = await axios.post(apiUrl, requestData, {
        headers: {
          "Content-Type": "application/json",
          "X-BUSINESS-ID": businessId,
          // other headers
        },
      });
      if (response) {
        const { data, status } = response;

        if (status === 200) {
          if (data) {
            if (data.access) {
              setLoginFailureError(false);
              console.log(data, "data");
              const {
                access,
                employee_id,

                isSuperuser,
                refresh,
                country,
                user_id,
                role_name,
                business_id,
                mobile,
              } = data;
              if (country) {
                setCountryName(country);
              }
              if (mobile) {
                formikForOtpValidation.setFieldValue("mobile", mobile);
              }
              if (role_name) {
                if (!isSuperuser) {
                  if (access) {
                    sessionStorage.setItem("token", access);
                    sessionStorage.setItem("employeeId", employee_id);
                    navigate("/");
                    sessionStorage.setItem("refresh", refresh);
                    sessionStorage.setItem("userId", user_id);
                  }
                } else {
                  if (myBooleanValueFor90Days) {
                    sessionStorage.setItem("token", access);
                    sessionStorage.setItem("token", access);
                    sessionStorage.setItem("employeeId", employee_id);
                    sessionStorage.setItem("refresh", refresh);
                    sessionStorage.setItem("userId", user_id);
                    navigate("/");
                  } else {
                    if (enableTwoFactorVerification) {
                      generateOTPIfEnableTwofactor(mobile);
                    } else {
                      setTwoStepVerification(true);
                    }
                  }

                  sessionStorage.setItem("token", access);
                  sessionStorage.setItem("token", access);
                  sessionStorage.setItem("employeeId", employee_id);
                  sessionStorage.setItem("refresh", refresh);
                  sessionStorage.setItem("userId", user_id);
                }
              }
            } else if (data.status_code === 403) {
              setLoginFailureError(true);
            }
          }
        }
      }

      // Handle the successful response here
    } catch (error: any) {
      // Handle errors here
      if (axios.isAxiosError(error)) {
        // Axios-specific error handling
        const axiosError: AxiosError = error;
        if (axiosError.response) {
          // The request was made, but the server responded with a status code
          // other than 2xx.
          console.error(
            "Server responded with an error:",
            axiosError.response.data
          );
        } else if (axiosError.request) {
          // The request was made, but no response was received
          console.error("No response received from the server");
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error setting up the request:", axiosError.message);
        }
      } else {
        // Handle non-Axios errors
        console.error("Non-Axios error occurred:", error.message);
      }
    }
  };

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch(
          "https://restcountries.com/v3.1/name/united kingdom"
        );
        const data = await response.json();
        if (data && data.length > 0) {
          sessionStorage.setItem("countryCode", data[0].cca2);
        }
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };

    fetchCountryCode();
  }, [countryName]);

  useEffect(() => {
    const email = ls.get("email");
    const password = ls.get("password");
    if (email && password) {
      formikForSignIn.setFieldValue("email", email);
      formikForSignIn.setFieldValue("password", password);
      formikForSignIn.setFieldValue("isRemembered", true);
    }
  }, []);

  const handleSendCodeButton = () => {
    generateOTP();
  };
  const handleSignupSubmitButton = async () => {
    if (formikForSignIn.values.isRemembered) {
      ls.set("email", formikForSignIn.values.email);
      ls.set("password", formikForSignIn.values.password);
    } else {
      ls.remove("email");
      ls.remove("password");
    }

    handleLoginApi();
  };

  const { data: addressData } = useQuery(GET_STORE_ADDRESS, {
    context: { clientName: "addressClient" },
    variables: { locationId: locationId },
  });

  useEffect(() => {
    if (addressData) {
      const { addressList } = addressData;
      if (addressList) {
        const { edges } = addressList;
        const list =
          edges?.length > 0 ? edges.map((each: any) => each.node) : [];

        sessionStorage.setItem("address", JSON.stringify(list));
      }
    }
  }, [addressData]);

  const hanldeForgetPassword = () => {
    setForgetPasswordOpen(true);
    formikForForgotPassword.setFieldValue(
      "email",
      formikForSignIn.values.email
    );
  };

  const handleCopy = (e: any) => {
    e.preventDefault(); // Prevent default copy behavior
  };

  return (
    <Box className="sign-in-order">
      <TwoFactorVerification
        formik={formikForOtpValidation}
        generateOTP={generateOTP}
        handleSendCodeButton={handleSendCodeButton}
        handleRemindMeNextTimeButton={handleRemindMeNextTimeButton}
        open={twoStepVerification}
        handleClose={handleCloseForTwoStepVerificationCode}
      />
      <VerificationalCode
        OtpValidationFailure={OtpValidationFailure}
        setOtpValidationFailure={setOtpValidationFailure}
        generateOTP={generateOTP}
        verifyOTP={verifyOTP}
        formik={formikForOtpValidation}
        open={verificationCode}
        handleClose={handleCloseForVerificationCode}
      />
      <ForgetPasswordPopup
        formik={formikForForgotPassword}
        open={forgetPasswordOpen}
        handleClose={handleForgetPasswordClose}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <CompneyInfo />
        <Box
          sx={{
            padding: { xs: 0, md: "3%" },
            marginRight: { xs: 0, md: "2%" },
          }}
        >
          <Grid
            item
            width={"100%"}
            component={Paper}
            elevation={6}
            square
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: { xs: 0, md: 5 },
              width: "100%",
              maxWidth: { sm: "360px", xs: "1Remember0%" },
              marginLeft: "auto",
              marginRight: "auto",
              padding: "4%",
              borderRadius: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { md: "start", xs: "center" },
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#F38B08",
                  fontWeight: "600",
                  marginTop: { lg: "0px", xs: "100px" },
                }}
              >
                Sign in{" "}
                <span
                  style={{ color: "#707070" }}
                  className="sign-in-span-text"
                >
                  {" "}
                  to your account to manage all the services and explore our
                  tools.
                </span>
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={formikForSignIn.handleSubmit}
                sx={{ mt: "30px", width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  {/* <TextField
                    sx={{ width: "100%" }}
                    id="email"
                    label={
                      <Typography className="sign-up-email-label">
                        Email Address
                      </Typography>
                    }
                    color="warning"
                    type="email"

                    onChange={(e) => {
                      formikForSignIn.handleChange(e);
                    }}

                    onBlur={formikForSignIn.handleBlur}
                    value={formikForSignIn.values.email || ""}
                    name="email"
                  /> */}
                  <TextField
                    sx={{ width: "100%" }}
                    id="email"
                    label="Email Address"
                    InputLabelProps={{
                      classes: {
                        root: "sign-up-email-label",
                      },
                    }}
                    color="warning"
                    type="email"
                    autoComplete="new-password"
                    onChange={(e) => {
                      formikForSignIn.handleChange(e);
                    }}
                    onBlur={formikForSignIn.handleBlur}
                    value={formikForSignIn.values.email || ""}
                    name="email"
                  />
                  {formikForSignIn.touched.email &&
                    formikForSignIn.errors.email && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formikForSignIn.errors.email}
                        </Typography>
                      </Box>
                    )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <FormControl
                    sx={{ width: "100%", marginTop: "5%" }}
                    variant="outlined"
                  >
                    <InputLabel
                      className="sign-up-email-label"
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="password"
                      autoComplete="new-password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      onChange={(e) => {
                        formikForSignIn.handleChange(e);
                      }}
                      onBlur={formikForSignIn.handleBlur}
                      value={formikForSignIn.values.password || ""}
                      name="password"
                      color="warning"
                      onCopy={handleCopy}
                    />
                  </FormControl>
                  {formikForSignIn.touched.password &&
                    formikForSignIn.errors.password && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formikForSignIn.errors.password}
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  {loginfailureError ? (
                    <Typography
                      sx={{
                        fontfamily: "Roboto",
                        fontSize: "12px",
                        lineHeight: "16px",
                        textTransform: "none",
                        letterSpacing: "0.4px",
                        color: "#F5431C",
                        fontWeight: "500",
                        textDecoration: "none",
                        cursor: "pointer",
                        marginTop: "4px",
                      }}
                    >
                      Email or Password is incorrect
                    </Typography>
                  ) : (
                    <Typography></Typography>
                  )}
                  <Button
                    variant="text"
                    onClick={hanldeForgetPassword}
                    sx={{
                      fontfamily: "Roboto",
                      fontSize: "12px",
                      lineHeight: "16px",
                      textTransform: "none",
                      letterSpacing: "0.4px",
                      color: "#f38b08",
                      fontWeight: "500",
                      textDecoration: "none",
                      cursor: "pointer",
                      padding: "0px",
                      marginTop: "5px",
                      marginBottom: "32px",
                    }}
                  >
                    Forgot Password?
                  </Button>
                </Box>

                <FormControlLabel
                  sx={{
                    gap: "8px",
                    margin: "0px",
                    cursor: "pointer",
                    marginBottom: "12px",
                  }}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        formikForSignIn.handleChange(e);
                      }}
                      onBlur={formikForSignIn.handleBlur}
                      checked={formikForSignIn.values.isRemembered}
                      name="isRemembered"
                      sx={{
                        padding: "0px",
                        margin: "0px",
                        width: "18px",
                        height: "18px",
                        margan: "0px",
                        "&.Mui-checked": { color: "#F38B08 !important" },
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "14px",
                        lineHeight: "16px",
                        letterSpecing: "0.4px",
                        color: "#333333",
                      }}
                    >
                      Remember me
                    </Typography>
                  }
                />
                <Box sx={{ padding: "0px 0px 0px 0px" }}>
                  <ReCAPTCHA
                    sitekey={recaptchaKey as string}
                    onChange={(value: any) => {
                      formikForSignIn.setFieldValue("recaptchaToken", value);
                    }}
                  />
                  {formikForSignIn.touched.recaptchaToken &&
                    formikForSignIn.errors.recaptchaToken && (
                      <Box>
                        <Typography
                          className="create-a-user-formik-label"
                          sx={{ marginToP: "8px" }}
                        >
                          {formikForSignIn.errors.recaptchaToken}
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    fontFamily: "poppins",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#FEFEFE",
                    textTransform: "none",
                    fontWeight: "500",
                    height: "48px",
                    backgroundColor: "#F38B08",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#F38B08",
                    },
                  }}
                  // className="butun"
                >
                  Sign In
                </Button>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.5px",
                      color: "#333333",
                    }}
                  >
                    Don't have an account?
                  </Typography>
                  <Button
                    onClick={() => navigate("/sign-up")}
                    variant="text"
                    sx={{
                      fontFamily: "poppins",
                      color: "#F38B08",
                      textTransform: "none",
                      fontWeight: "600",
                      fontSize: "14px",
                      textDecoration: "none",
                      lineHeight: "24px",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    marginTop: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "#707070",
                    }}
                  >
                    Need assistance or have questions?
                  </Typography>
                  <Link
                    href="#"
                    variant="body2"
                    sx={{
                      color: "#F5431C",
                      fontWeight: "500",
                      textDecoration: "none",
                      fontSize: "12px",
                      fontFamily: "Poppins",
                      lineHeight: "24px",
                    }}
                  >
                    {"Contact Support"}
                  </Link>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
