// GraphQl queries

import { gql, useQuery } from "@apollo/client";

// export const GET_POST = gql`

//   query GET_SUGGESTION($where: String!,$limit: Int!) {
//     postsConnection (
//       limit:$limit,
//       start:0,
//       sort:"createdAt:asc",
//       where: {
//         profile:$where
//       }){
//       values {
//         id,
//         long_text,
//         images {previewUrl, url,},
//         profile {
//           id,
//           username,
//           image_avatar {
//             previewUrl,
//             id,
//             url,
//           }
//         },
//         attachments {
//           profile {
//             id,
//             username,
//             excerpt,
//             image_avatar {
//               previewUrl,
//               id,
//               url,
//             }
//           },
//           post_capture,
//           story{
//             profile {
//               id,
//               username,

//               full_name,
//               image_avatar {
//                 previewUrl,
//                 id,
//                 url,
//               }
//             },
//             video {
//               url,
//               previewUrl
//             },
//             caption,
//             images {url},
//           },
//           product{
//               id,
//               images{
//                 image{
//                   url
//                 }
//               },
//               title,
//               description,
//               price,
//               price_before,
//               profile {
//                 id,
//                 username,
//                 full_name,
//                 image_avatar {
//                   url,
//                   id
//                 }
//               },

//           },
//         },
//         weblink {
//           linkurl
//         }
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
// }
// `;
// export const GET_SUGGESTION = gql`
//   query GET_SUGGESTION($where: [String!]!,$limit: Int!) {
//     postsConnection (
//       limit:$limit,
//       start:0,
//       sort:"createdAt:asc",
//       where: {
//         profile_nin:$where
//       }){
//       values {
//         id,
//         long_text,
//         images {previewUrl, url,},
//         profile {
//           id,
//           username,
//           image_avatar {
//             previewUrl,
//             id,
//             url,
//           }
//         },
//         attachments {
//           profile {
//             id,
//             username,
//             excerpt,
//             image_avatar {
//               previewUrl,
//               id,
//               url,
//             }
//           },
//           post_capture,
//           story{
//             profile {
//               id,
//               username,
//               full_name,
//               image_avatar {
//                 previewUrl,
//                 id,
//                 url,
//               }
//             },
//             video {
//               url,
//               previewUrl,

//             },
//             caption,
//             images {url},
//           },
//           product{
//               id,
//               images{
//                 image{
//                   url
//                 }
//               },
//               title,
//               description,
//               price,
//               price_before,
//               profile {
//                 id,
//                 username,
//                 full_name,
//                 image_avatar {
//                   url,
//                   id
//                 }
//               },

//           },
//         },
//         weblink {
//           linkurl
//         }
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
// }
// `;

// export const GET_PROFILES = gql`
// query GET_PROFILES($where: String!) {
//     profilesConnection (
//     where: {
//     user:$where,
//     }){
//     values {
//       id,
//       username,
//       full_name,
//       profile_type,
//       excerpt,
//       profile_longtext,
//       image_avatar {
//       previewUrl,
//       url
//       },
//       video_avatar {
//         url
//       }
//     },
//     aggregate {
//       count,
//       totalCount
//     }
//   }
// }
// `;

// export const GET_FOLLOWS = gql`
//   query ($where: String!){
//     followsConnection (
//       limit:1000,
//       start:0,
//       sort:"createdAt:asc",
//       where: {
//         follower:$where
//       }){
//       values {
//         id,
//         profile {
//           username,
//         }
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
//   }
// `;

// export const GET_CONNECTS = gql`
//   query ($where: String!){
//     connectsConnection  (
//       limit:1000,
//       start:0,
//       sort:"createdAt:asc",
//       where: {
//         follower:$where
//       }){
//       values {
//         id,
//         profile {
//           username,
//         }
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
//   }
// `;

// export const SEARCH_POST = gql`
//   query ($where: String!,$limit: Int!) {
//     postsConnection (
//       limit:$limit,
//       start:0,
//       sort:"createdAt:desc",
//       where: {
//         long_text_contains: $where
//       }){
//       values {
//         id,
//         long_text,
//         images {previewUrl, url},
//         profile {
//           id,
//           username,
//           full_name,
//           image_avatar {
//             previewUrl,
//             id, url
//           }
//         },
//         attachments {
//           profile {
//             id,
//             username,
//             excerpt,
//             image_avatar {
//               previewUrl,
//               id
//             }
//           },
//           post_capture,
//           story{
//             profile {
//               id,
//               username,
//               full_name,
//               image_avatar {
//                 previewUrl,
//                 id
//               }
//             },
//             video {
//               url,
//               previewUrl
//             },
//             caption,
//             images {url},
//           },

//         }

//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
//   }
// `;

// // weblink {
// //   linkurl
// // }

// export const SEARCH_PROFILE = gql`
//   query ($where: String!,$limit: Int!) {
//     profilesConnection (
//       limit:$limit,
//       start:0,
//       sort:"createdAt:asc",
//       where: {
//         username_contains:$where,
//       }){
//       values {
//         id,
//         username,
//         full_name,
//         excerpt,
//         image_avatar {
//           previewUrl,
//           url
//         },
//         video_avatar {
//           url
//         }
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
//   }
// `;

// export const SLIDER_PROFILE = gql`
//   query ($where: String!) {
//       postsConnection (
//         limit:10,
//         start:0,
//         sort:"createdAt:desc",
//         where: {
//           profile_in:[$where],
//           createdAt_gt: "2018-03-19 16:21:07.161Z" #date today - 7 days
//         }){
//         values {
//           id,
//           long_text,
//           images {url},
//           profile {
//             id,
//             username,
//             full_name,
//             image_avatar {
//               url,
//               id
//             }
//           },
//           attachments {
//             profile {
//               id,
//               username,
//               excerpt,
//               image_avatar {
//                 url,
//                 id
//               }
//             },
//             post_capture,
//             story{
//               profile {
//                 id,
//                 username,
//                 full_name,
//                 image_avatar {
//                   url,
//                   id
//                 }
//               },
//               video {
//                 url,
//                 previewUrl
//               },
//               caption,
//               images {url},
//             },
//           },
//           weblink {
//             linkurl
//           },
//         },
//         aggregate {
//           count,
//           totalCount
//         }
//       }
//   }
// `;
// // profile_in:[$where]

// export const GET_STORIES = gql`
//   query ($where: String!) {
//     storiesConnection (
//       limit:10,
//       start:0,
//       sort:"createdAt:asc",
//       where: {

//         profile:$where
//       }){
//       values {
//         id,
//         images {url},
//         profile {
//           id,
//           username,
//           full_name,
//           image_avatar {
//             url,
//             id
//           }
//         },
//         video {
//           url
//         },
//         caption,
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
//   }
// `;

// export const GET_COMMENTS = gql`
//   query ($where: String!,$limit: Int!) {
//       commentsConnection (
//         limit:$limit,
//         start:0,
//         sort:"createdAt:desc",
//         where: {
//           post:$where
//         }){
//         values {
//           id,
//           profile {
//             id,
//             username,
//             full_name,
//             image_avatar {
//               url,
//               id
//             }
//           },
//           comment
//         },
//         aggregate {
//           count,
//           totalCount
//         }
//       }
//   }
// `;

// export const GET_CONVERSATION = gql`
//   query ($where: String!,$limit: Int!) {
//       conversationsConnection (
//         limit:$limit,
//         start:0,
//         sort:"messages.createdAt:desc",
//         where: {
//           profiles_in:$where
//         }){
//         values {
//           id,
//           profiles (
//               where: {id_nin:$where}
//             ) {
//             id,
//             full_name,
//             username,
//             image_avatar {
//               url
//             }
//           },
//           messages (limit:1,sort:"createdAt:desc") {
//             message,
//             createdAt
//           }
//         },
//         aggregate {
//           count,
//           totalCount
//         }
//       }
//   }
// `;

// export const GET_MESSAGE = gql`
// query ($where: String!,$limit: Int!) {
//     messagesConnection (
//       limit:$limit,
//       start:0,
//       sort:"createdAt:desc",
//       where: {
//         conversation_in:$where
//       }){
//       values {
//         id,
//         message,
//         sender {
//           id,
//           full_name,
//           username,
//           image_avatar {
//             url
//           }
//         },
//          attachment {
//           profile {
//             id,
//             username,
//             excerpt,
//             image_avatar {
//               url,
//               id
//             }
//           },
//           story {
//             profile {
//               id,
//               username,
//               full_name,
//               image_avatar {
//                 url,
//                 id
//               }
//             },
//             video {
//               url,
//               previewUrl
//             },
//             caption,
//             images {url},
//           },
//           product {
//             id,
//             images {
//               image {
//                 url
//               }
//             }
//             title,
//             description,
//             price,
//             price_before,
//             profile {
//               id,
//               username,
//               full_name,
//               image_avatar {
//                 url,
//                 id
//               }
//             },
//           }

//         },
//       },
//       aggregate {
//         count,
//         totalCount
//       }
//     }
// }
// `;
//$query:String
export const GET_ORDERS_LIST = gql`
  query (
    $orderStatus: String
    $orderType: String
    $businessId: ID
    $storeId: ID
    $query: String
    $byOrders: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    orders(
      businessId: $businessId
      first: $first
      after: $after
      last: $last
      before: $before
      query: $query
      storeId: $storeId
      orderType: $orderType
      orderStatus: $orderStatus
      byOrders: $byOrders
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          orderNo
          orderId
          createdOn
          createdBy
          totalAmount
          paymentStatus
          requestedTime
          orderType
          customerId
          customerDetails
          orderStatus
          cart {
            cartId
          }
        }
      }
    }
  }
`;

export const GET_ORDER_DETAIL = gql`
  query ($id: ID!) {
    order(orderId: $id) {
      tax
      createdOn
      deliveryTime
      collectionTime
      deliveryAddressId
      notes
      orderId
      orderNo
      orderStatus
      orderType
      customerDetails
      deliveredAt
      deliveryStatus
      assignedDriver
      paidAmount
      requestedTime
      payableAmount
      paymentType
      paymentStatus
      totalAmount
      itemTotal

      discountAmount
      tipAmount
      deliveryCharges
      storeId
      serviceCharges
      cart {
        cartId
        cartItems
        itemTotal
        loyaltyPoints
        loyaltyAmount
      }
    }
  }
`;

export const GET_STORE = gql`
  query store($storeId: ID) {
    store(storeId: $storeId) {
      storeId
      business {
        brandName
      }
      addressId
      storeName
      paymentTypesForCollection
      noOfKds
      storeStatus
      email
      mobile
    }
  }
`;

export const ORDER_STATUS_CHANGE = gql`
  mutation orderStatusChange(
    $orderId: Int!
    $orderStatus: String!
    $cancellationReason: String
  ) {
    orderStatusChange(
      orderId: $orderId
      orderStatus: $orderStatus
      cancellationReason: $cancellationReason
    ) {
      message
      check
      success
    }
  }
`;
export const GET_CART = gql`
  query ($cartId: String!) {
    cart(cartId: $cartId) {
      cartId
      cartItems
      isClosed
    }
  }
`;
export const GET_ADDRESS = gql`
  query ($addressId: ID, $uuid: UUID) {
    address(addressId: $addressId, uuid: $uuid) {
      addressId
      isDeleted
      address1
      receiverName
      receiverMobile
      address2
      addressType
      postCode
      city
      state
    }
  }
`;

export const DELIVERY_MAN = gql`
  query employee($uuid: UUID) {
    employee(uuid: $uuid) {
      employeeId
      lastName
      firstName
      mobile
      email
      pin
      clockInAt
      clockOutAt
      permissions
      driverpettycashSet {
        edges {
          node {
            employeeId
            pettycash
            date
          }
        }
      }
    }
  }
`;
