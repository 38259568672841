// import {
//   Box,
//   Button,
//   Checkbox,
//   Divider,
//   IconButton,
//   Modal,
//   Typography,
// } from "@mui/material";
// import React, { useRef } from "react";

// import CloseIcon from "@mui/icons-material/Close";

// import { useNavigate } from "react-router";

// interface State {
//   open: boolean;
//   handleClose: () => void;
//   formik: any;
//   verifyOTP: () => void;
//   generateOTP: () => void;
//   OtpValidationFailure: boolean;
// }

// const style = {
//   position: "absolute" as "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "45%",
//   // height: "55vh",
//   p: 3,
//   bgcolor: "background.paper",
//   borderRadius: "8px",
// };

// const VerificationalCode = (props: State) => {
//   const {
//     open,
//     handleClose,
//     formik,
//     verifyOTP,
//     generateOTP,
//     OtpValidationFailure,
//   } = props;

//   const maskPhoneNumber = (phoneNumber: any) => {
//     const lastFourDigits = phoneNumber.slice(-4);
//     const maskedPart = phoneNumber.slice(0, -4).replace(/./g, "*");
//     const maskedPhoneNumber = maskedPart + lastFourDigits;
//     return maskedPhoneNumber;
//   };

//   const label = { inputProps: { "aria-label": "Checkbox demo" } };
//   const codeBoxRefs = useRef<(HTMLInputElement | null)[]>([]);

//   const getCodeBoxElement = (index: number) => {
//     return codeBoxRefs.current[index];
//   };

//   const onKeyUpEvent = (
//     index: number,
//     event: React.KeyboardEvent<HTMLInputElement>
//   ) => {
//     const eventCode = event.keyCode || event.which;
//     if (getCodeBoxElement(index)?.value.length === 1) {
//       if (index !== 5) {
//         getCodeBoxElement(index + 1)?.focus();
//       } else {
//         getCodeBoxElement(index)?.blur();
//       }
//     }
//     if (eventCode === 8 && index !== 0) {
//       getCodeBoxElement(index - 1)?.focus();
//     }
//     formik.setFieldValue(`otp[${index}]`, getCodeBoxElement(index)?.value);
//   };

//   const onFocusEvent = (index: number) => {
//     for (let item = 0; item < index; item++) {
//       const currentElement = getCodeBoxElement(item);
//       if (currentElement && !currentElement.value) {
//         currentElement.focus();
//         break;
//       }
//     }
//   };

//   const buttonState =
//     formik.values.otp && formik.values.otp.join("")?.length === 4;
//   const navigate = useNavigate();
//   return (
//     <Box>
//       <Modal
//         open={open}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <form onSubmit={formik.handleSubmit}>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 alignItems: "center",
//                 width: "100%",
//               }}
//             >
//               {/* <IconButton
//                 onClick={() => navigate("/")}
//                 className="exit-container"
//               >
//                 <CloseIcon className="exitIcon" />
//               </IconButton> */}
//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   // width: "25%",
//                 }}
//               >
//                 {/* <Button
//                   sx={{ textTransform: "none", marginRight: "10px" }}
//                   variant="outlined"
//                   color="warning"
//                   onClick={() => navigate("/")}
//                 >
//                   Skip
//                 </Button> */}
//                 <Button
//                   type="submit"
//                   sx={{
//                     textTransform: "none",
//                   }}
//                   disabled={!buttonState}
//                   className={buttonState ? "verify-enable" : "verify-disable"}
//                   variant="contained"
//                 >
//                   Verify
//                 </Button>
//               </Box>
//             </Box>
//             <Divider
//               sx={{ width: "100%", marginTop: "1%", marginBottom: "1%" }}
//             />
//             <Box sx={{ padding: "2%" }}>
//               <Typography
//                 sx={{
//                   color: "#333",
//                   fontFamily: "Poppins",
//                   fontSize: "20px",
//                   fontStyle: "normal",
//                   fontWeight: 600,
//                   lineHeight: "normal",
//                   marginTop: "5px",
//                   marginBottom: "10px",
//                 }}
//               >
//                 Enter the verification code
//               </Typography>

//               <Typography
//                 sx={{
//                   color: "#707070",
//                   fontFamily: "Poppins",
//                   fontSize: "16px",
//                   fontStyle: "normal",
//                   fontWeight: 500,
//                   lineHeight: "normal",
//                 }}
//               >
//                 A verification code was sent to{" "}
//                 {formik.values.mobile && maskPhoneNumber(formik.values.mobile)}{" "}
//                 . If you haven't received a code within a short period. Please
//                 request again or try another method.
//                 <br />
//                 {/* <div style={{ color: "#F38B08" }}>Resend Code</div> */}
//               </Typography>
//               <Button
//                 onClick={generateOTP}
//                 sx={{
//                   textTransform: "none",
//                   fontSize: "16px",
//                   marginBottom: "1%",
//                 }}
//                 variant="text"
//                 color="warning"
//               >
//                 Resend Code
//               </Button>

//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "center",
//                   flexDirection: "column",
//                   alignItems: "center",
//                 }}
//               >
//                 <Box
//                   sx={{
//                     width: "350px",
//                     height: "60px",
//                     padding: "7px 10px 5px 79px",
//                     borderRadius: "4px",
//                     border: "1px solid #EEE",
//                   }}
//                 >
//                   {[0, 1, 2, 3].map((index) => (
//                     <input
//                       className="hide-number-input-icons input"
//                       key={index}
//                       ref={(el) => (codeBoxRefs.current[index] = el)}
//                       type="text"
//                       maxLength={1}
//                       color="#ffffff"
//                       onKeyUp={(e) => onKeyUpEvent(index, e)}
//                       onFocus={() => onFocusEvent(index)}
//                       style={{
//                         height: "45px",
//                         width: "45px",
//                         fontSize: "20px",
//                         textAlign: "center",
//                         border: "1px solid #ababab",
//                         marginRight: "5px",
//                         borderRadius: "8px",
//                         color: "#707070",
//                       }}
//                     />
//                   ))}
//                 </Box>
//                 {OtpValidationFailure && (
//                   <Typography
//                     sx={{
//                       fontfamily: "Roboto",
//                       fontSize: "12px",
//                       lineHeight: "16px",
//                       textTransform: "none",
//                       letterSpacing: "0.4px",
//                       color: "#F5431C",
//                       fontWeight: "500",
//                       textDecoration: "none",
//                       cursor: "pointer",
//                       marginTop: "4px",
//                     }}
//                   >
//                     Please Enter Valid OTP
//                   </Typography>
//                 )}
//               </Box>

//               <Box
//                 sx={{
//                   display: "flex",
//                   justifyContent: "space-between",
//                   alignItems: "center",
//                   width: "100%",
//                   marginTop: "3%",
//                 }}
//               >
//                 <Typography>Remember this device for 90 days</Typography>
//                 <Checkbox
//                   onChange={(e) => {
//                     formik.handleChange(e);
//                   }}
//                   name="remember"
//                   {...label}
//                   color="warning"
//                   checked={formik.values.remember}
//                   sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
//                 />
//               </Box>
//             </Box>
//           </form>
//         </Box>
//       </Modal>
//     </Box>
//   );
// };

// export default VerificationalCode;

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";

interface State {
  open: boolean;
  handleClose: () => void;
  formik: any;
  verifyOTP: () => void;
  generateOTP: () => void;
  OtpValidationFailure: boolean;
  setOtpValidationFailure: React.Dispatch<React.SetStateAction<boolean>>;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  p: 3,
  bgcolor: "background.paper",
  borderRadius: "8px",
};

const mobileDrawerStyle = {
  width: "100%",
  height: "auto",
  p: 2,
  bgcolor: "background.paper",
  borderRadius: "8px 8px 0 0",
};

const VerificationalCode = (props: State) => {
  const {
    open,
    handleClose,
    formik,
    verifyOTP,
    generateOTP,
    OtpValidationFailure,
    setOtpValidationFailure,
  } = props;

  const isMobile = useMediaQuery("(max-width:600px)");

  const maskPhoneNumber = (phoneNumber: any) => {
    const lastFourDigits = phoneNumber.slice(-4);
    const maskedPart = phoneNumber.slice(0, -4).replace(/./g, "*");
    const maskedPhoneNumber = maskedPart + lastFourDigits;
    return maskedPhoneNumber;
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const codeBoxRefs = useRef<(HTMLInputElement | null)[]>([]);

  const getCodeBoxElement = (index: number) => {
    return codeBoxRefs.current[index];
  };

  const onKeyUpEvent = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    setOtpValidationFailure(false);
    const eventCode = event.keyCode || event.which;
    if (getCodeBoxElement(index)?.value.length === 1) {
      if (index !== 3) {
        getCodeBoxElement(index + 1)?.focus();
      } else {
        getCodeBoxElement(index)?.blur();
      }
    }
    if (eventCode === 8 && index !== 0) {
      getCodeBoxElement(index - 1)?.focus();
    }
    formik.setFieldValue(`otp[${index}]`, getCodeBoxElement(index)?.value);
  };

  const onFocusEvent = (index: number) => {
    for (let item = 0; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (currentElement && !currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  };

  const buttonState =
    formik.values.otp && formik.values.otp.join("")?.length === 4;
  const navigate = useNavigate();

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: mobileDrawerStyle,
          }}
        >
          <Box sx={{ p: 2 }}>
            {/* Same content as in Modal */}
            <form onSubmit={formik.handleSubmit}>
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 600,
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                Enter the verification code
              </Typography>
              <Typography
                sx={{
                  color: "#707070",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                A verification code was sent to{" "}
                {formik.values.mobile && maskPhoneNumber(formik.values.mobile)}.
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "10px",
                  }}
                >
                  {[0, 1, 2, 3].map((index) => (
                    <input
                      key={index}
                      ref={(el) => (codeBoxRefs.current[index] = el)}
                      type="text"
                      maxLength={1}
                      onKeyUp={(e) => onKeyUpEvent(index, e)}
                      onFocus={() => onFocusEvent(index)}
                      style={{
                        height: "40px",
                        width: "40px",
                        fontSize: "20px",
                        textAlign: "center",
                        border: "1px solid #ababab",
                        marginRight: "5px",
                        borderRadius: "8px",
                        color: "#707070",
                      }}
                    />
                  ))}
                </Box>
                {OtpValidationFailure && (
                  <Typography
                    sx={{
                      fontFamily: "Roboto",
                      fontSize: "12px",
                      color: "#F5431C",
                      marginTop: "4px",
                    }}
                  >
                    Please Enter Valid OTP
                  </Typography>
                )}
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center", // Centers horizontally
                    alignItems: "center", // Centers vertically
                    gap: "5px", // Adds 5px gap between Typography and Button
                  }}
                >
                  <Typography>Didn't Receive the Code?</Typography>
                  <Button
                    onClick={generateOTP}
                    sx={{
                      textTransform: "none",
                      fontSize: "12px",
                      marginBottom: "1%",
                    }}
                    variant="text"
                    color="warning"
                  >
                    Resend Code
                  </Button>
                </Box>
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <Button
                  type="submit"
                  sx={{ textTransform: "none", marginTop: "10px" }}
                  disabled={!buttonState}
                  variant="contained"
                  className={buttonState ? "verify-enable" : "verify-disable"}
                >
                  Verify
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "3%",
                  }}
                >
                  <Typography>Remember this device for 90 days</Typography>
                  <Checkbox
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    name="remember"
                    {...label}
                    color="warning"
                    checked={formik.values.remember}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </Drawer>
      ) : (
        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {/* <IconButton
                onClick={() => navigate("/")}
                className="exit-container"
              >
                <CloseIcon className="exitIcon" />
              </IconButton> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // width: "25%",
                  }}
                >
                  {/* <Button
                  sx={{ textTransform: "none", marginRight: "10px" }}
                  variant="outlined"
                  color="warning"
                  onClick={() => navigate("/")}
                >
                  Skip
                </Button> */}
                  <Button
                    type="submit"
                    sx={{
                      textTransform: "none",
                    }}
                    disabled={!buttonState}
                    className={buttonState ? "verify-enable" : "verify-disable"}
                    variant="contained"
                  >
                    Verify
                  </Button>
                </Box>
              </Box>
              <Divider
                sx={{ width: "100%", marginTop: "1%", marginBottom: "1%" }}
              />
              <Box sx={{ padding: "2%" }}>
                <Typography
                  sx={{
                    color: "#333",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    marginTop: "5px",
                    marginBottom: "10px",
                  }}
                >
                  Enter the verification code
                </Typography>

                <Typography
                  sx={{
                    color: "#707070",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "normal",
                  }}
                >
                  A verification code was sent to{" "}
                  {formik.values.mobile &&
                    maskPhoneNumber(formik.values.mobile)}{" "}
                  . If you haven't received a code within a short period. Please
                  request again or try another method.
                  <br />
                  {/* <div style={{ color: "#F38B08" }}>Resend Code</div> */}
                </Typography>
                <Button
                  onClick={generateOTP}
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    marginBottom: "1%",
                  }}
                  variant="text"
                  color="warning"
                >
                  Resend Code
                </Button>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "350px",
                      height: "60px",
                      padding: "7px 10px 5px 79px",
                      borderRadius: "4px",
                      border: "1px solid #EEE",
                    }}
                  >
                    {[0, 1, 2, 3].map((index) => (
                      // <input
                      //   className="hide-number-input-icons input"
                      //   key={index}
                      //   ref={(el) => (codeBoxRefs.current[index] = el)}
                      //   type="text"
                      //   maxLength={1}
                      //   // color="#ffffff"
                      //   onKeyUp={(e) => onKeyUpEvent(index, e)}
                      //   onFocus={() => onFocusEvent(index)}
                      //   style={{
                      //     height: "45px",
                      //     width: "45px",
                      //     fontSize: "20px",
                      //     textAlign: "center",
                      //     border: "1px solid #ababab",
                      //     marginRight: "5px",
                      //     borderRadius: "8px",
                      //     color: "#707070",
                      //   }}
                      // />
                      <input
                        key={index}
                        ref={(el) => (codeBoxRefs.current[index] = el)}
                        type="text"
                        maxLength={1}
                        onKeyUp={(e) => onKeyUpEvent(index, e)}
                        onFocus={() => onFocusEvent(index)}
                        style={{
                          height: "45px",
                          width: "45px",
                          fontSize: "20px",
                          textAlign: "center",
                          border: "1px solid #ababab",
                          marginRight: "5px",
                          borderRadius: "8px",
                          color: "#707070",
                        }}
                      />
                    ))}
                  </Box>
                  {OtpValidationFailure && (
                    <Typography
                      sx={{
                        fontfamily: "Roboto",
                        fontSize: "12px",
                        lineHeight: "16px",
                        textTransform: "none",
                        letterSpacing: "0.4px",
                        color: "#F5431C",
                        fontWeight: "500",
                        textDecoration: "none",
                        cursor: "pointer",
                        marginTop: "4px",
                      }}
                    >
                      Please Enter Valid OTP
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    marginTop: "3%",
                  }}
                >
                  <Typography>Remember this device for 90 days</Typography>
                  <Checkbox
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    name="remember"
                    {...label}
                    color="warning"
                    checked={formik.values.remember}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default VerificationalCode;
