import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import LocationAlertPopup from "./locationComformationPopup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TextFieldForDebounce from "../../utils/textFieldForDebounce";
interface ContactImformationState {
  formik: any;
  editId: string | undefined;
  conditions: boolean[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formikForBusinessAddress: any;
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
  handleDuplicateEmail: (text: string) => void;
  handleDuplicatePhone: (text: string) => void;
}
const LocationContactInformation = (props: ContactImformationState) => {
  const {
    handleDuplicateEmail,
    handleDuplicatePhone,
    formik,
    editId,
    conditions,
    setActiveStep,
    formikForBusinessAddress,
    setConditions,
  } = props;
  const [alertPopup, setAlertPopup] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      (conditions[1] === true &&
        formikForBusinessAddress.values.address1 == null) ||
      (conditions[1] === true && formikForBusinessAddress.values.address1 == "")
    ) {
      setAlertPopup(true);
    } else {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[1] = false;
        return updatedConditions;
      });
    }
  }, []);

  const index = conditions.findIndex((condition) => condition === true);

  const handleAlertPopupClose = () => {
    setAlertPopup(false);
    setActiveStep(index);
  };

  const handleEmail = (value: string) => {
    console.log(value, "value");
    if (value === "" && !formik.values.phoneNumber) {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[2] = true;
        return updatedConditions;
      });
    }
    formik.setFieldValue("email", value);
    handleDuplicateEmail(value);
  };

  const handleMobile = (value: string) => {
    if (value === "" && !formik.values.email) {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[2] = true;
        return updatedConditions;
      });
    }
    formik.setFieldValue("phoneNumber", value);
    handleDuplicatePhone(value);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <LocationAlertPopup
        spantext={
          index === 0
            ? t("locations.basic_details")
            : t("locations.business_address")
        }
        beforeSpanText={t("locations.please_enter")}
        afterSpanText={t("locations.to_continue_next")}
        handleClose={handleAlertPopupClose}
        open={alertPopup}
      />
      <Paper
        elevation={2}
        sx={{ width: "90%", padding: "15px", borderLeft: "2px solid #BAE29B" }}
      >
        <Box>
          <Typography className="basic-details-heading">
            {t("locations.contact_information")}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "3%",
                alignItems: "flex-start",
                marginTop: "15px",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <TextFieldForDebounce
                  handleChange={handleEmail}
                  handleBlur={formik.handleBlur}
                  label={t("locations.email")}
                  name="email"
                  type="email"
                  width="100%"
                  formik={formik}
                  debounceVariable="debouncevariableForEmail"
                  value={formik.values.debouncevariableForEmail}
                />

                {
                  <Box>
                    {formik.errors.email ? (
                      <Typography className="create-a-user-formik-label">
                        {formik.errors.email === "Invalid email address"
                          ? t("error.invalid_email")
                          : t("error.required")}
                      </Typography>
                    ) : (
                      formik.values.duplicateEmailCheck && (
                        <Typography className="create-a-user-formik-label">
                          {t("error.already_existed")}
                        </Typography>
                      )
                    )}
                  </Box>
                }
              </Box>

              <Box sx={{ width: "40%" }}>
                <TextFieldForDebounce
                  handleChange={handleMobile}
                  handleBlur={formik.handleBlur}
                  label={t("locations.phone_number")}
                  name="phoneNumber"
                  type="text"
                  width="100%"
                  formik={formik}
                  debounceVariable="debouncevariableForMobile"
                  value={formik.values.debouncevariableForMobile}
                  mobile={true}
                />

                {
                  <Box>
                    {formik.errors.phoneNumber ? (
                      <Typography className="create-a-user-formik-label">
                        {formik.errors.phoneNumber === "Invalid Number"
                          ? t("error.invalid_mobile")
                          : t("error.required")}
                      </Typography>
                    ) : (
                      formik.values.duplicatePhoneNumberCheck && (
                        <Typography className="create-a-user-formik-label">
                          {t("error.already_existed")}
                        </Typography>
                      )
                    )}
                  </Box>
                }
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "3%",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  name="twitter"
                  value={formik.values.twitter || ""}
                  color="warning"
                  sx={{ width: "100%" }}
                  label={<Typography>{t("locations.twitter")}</Typography>}
                  variant="outlined"
                />
              </Box>

              <Box sx={{ width: "40%" }}>
                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  name="instagram"
                  value={formik.values.instagram || ""}
                  color="warning"
                  sx={{ width: "100%" }}
                  label={<Typography>{t("locations.instagram")}</Typography>}
                  variant="outlined"
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "3%",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "40%" }}>
                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  name="facebook"
                  value={formik.values.facebook || ""}
                  color="warning"
                  sx={{ width: "100%" }}
                  label={<Typography>{t("locations.facebook")}</Typography>}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ width: "40%" }}>
                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  name="website"
                  value={formik.values.website || ""}
                  color="warning"
                  sx={{ width: "100%" }}
                  label={<Typography>{t("locations.website")}</Typography>}
                  variant="outlined"
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ textAlign: "right", margin: "10px 5px 10px 5px" }}>
            {editId === undefined && (
              <Button
                sx={{ textTransform: "none" }}
                className="create-a-use-role-clear-button"
                variant="outlined"
                color="warning"
              >
                {t("buttons.clear")}
              </Button>
            )}
            <Button
              type="submit"
              sx={{ textTransform: "none", marginLeft: "10px" }}
              variant="contained"
              color="warning"
            >
              {editId === undefined ? t("product.next") : t("buttons.save")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LocationContactInformation;
