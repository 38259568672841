import {
  Box,
  MenuItem,
  Select,
  FormControl,
  Button,
  Paper,
  InputLabel,
  Typography,
  TextField,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useState, useEffect, useRef } from "react";
import "./createUser.css";
import { useMutation } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { User_Roles } from "../../graphicalQl/usequery/userProfileUseQuery";

import {
  CREATE_EMPLOYEE,
  UPDATE_USER,
} from "../../graphicalQl/mutation/userProfileMutation";
import { LOCATIONS_LIST } from "../../graphicalQl/usequery/userProfileUseQuery";
import {
  SEARCH_PERMISSIONS_BY_ROLE_ID,
  GET_UPDATE_USER_DETAILS,
} from "../../graphicalQl/usequery/userProfileUseQuery";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EmplpoyeesList } from "../../graphicalQl/usequery/userProfileUseQuery";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader";
import "react-toastify/dist/ReactToastify.css";
import "../options/createProduct.css";
import { InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createUserStore } from "../../store";
import { useTranslation } from "react-i18next";
import ErrorModal from "../errorHandling/errorModal";

interface Option {
  id: number | string;
  role: string;
}

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}

interface CreateUser {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  roleId: string;
  locationsList: string[];
}

interface StatePermissionsSearchByRoleList {
  appliesFor: [];
  name: string;
  permissionId: string;
}

function CreateUser() {
  const { phoneCountryCode } = useSelector(
    (state: any) => state.headerLocation
  );
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const params = useParams();
  const { editId } = params;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validationSchemaPart1 = Yup.object({
    firstName: Yup.string().trim().required("Required"),
    lastName: Yup.string().trim().required("Required"),
    email: Yup.string().trim().required("Required").email("Email is Invalid"),
    mobile: Yup.string()
      .required("Required")
      .matches(/^\d{10,11}$/, "Invalid Number"),
    roleId: Yup.number().required("Required"),
    locationsList: Yup.array()
      .min(1, "At least one location must be selected")
      .required("At least one location must be selected"),
  });

  // Example usage:

  const { firstName, lastName, email, mobile, roleId, locationsList, all } =
    useSelector((state: any) => state.users);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      firstName,
      lastName,
      email,
      mobile,
      roleId,
      locationsList,
    });
  }, [firstName, lastName, email, mobile, roleId]);

  const initialValuesOfTextFields: CreateUser = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    mobile: mobile,
    roleId: roleId,
    locationsList: [],
  };

  //Locations List
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });
  const {
    data: userRolesData,
    loading: userRolesLoading,
    error: userRolesError,
  } = useQuery(User_Roles, {
    context: { clientName: "userClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });
  const {
    data: updateUserData,
    loading: updateUserLoading,
    error: updateUserError,
  } = useQuery(GET_UPDATE_USER_DETAILS, {
    context: { clientName: "userClient" },
    variables: { employeeId: editId },
    fetchPolicy: "network-only",
  });
  const {
    data: permissionsSearchByRoleData,
    loading: loadingPermissionsSearchByRoleData,
    error: errorPermissionsSearchByRoleData,
  } = useQuery(SEARCH_PERMISSIONS_BY_ROLE_ID, {
    context: { clientName: "userClient" },
    variables: { roleId: roleId },
    fetchPolicy: "network-only",
  });

  const [initialLocationsList, setInitialLocationsList] = useState<Locations[]>(
    []
  );

  //Accordian settings
  const [allCheckboxChecked, setAllCheckboxChecked] = React.useState(all);
  const [searchInput, setSearchInput] = React.useState("");
  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const handleErrorModal = () => {
    setErrorModal(false);
  };

  function toPascalCase(str: any) {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  }

  const searchResults = initialLocationsList?.filter((eachLocation: any) =>
    eachLocation.storeName?.toLowerCase().includes(searchInput)
  );
  const handleSearchIcon = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchInput(e.target.value);
  };

  const handlePopupCheckbox = (
    field: string,
    id: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      const existedList = [...formik.values.locationsList, id];
      formik.setFieldValue("locationsList", existedList);
      dispatch(createUserStore("locationsList", existedList));
    } else {
      const updatedList = formik.values.locationsList.filter(
        (item) => item !== id
      );
      formik.setFieldValue("locationsList", updatedList);
      dispatch(createUserStore("locationsList", updatedList));
    }
  };

  const handlePopupCheckbo = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    if (checked) {
      const collectedLocationIds = initialLocationsList
        .filter((each: any) => each.storeId)
        .map((eachId: any) => eachId.storeId);

      formik.setFieldValue("locationsList", collectedLocationIds);
      dispatch(createUserStore("locationsList", collectedLocationIds));
    } else {
      formik.setFieldValue("locationsList", []);
      dispatch(createUserStore("locationsList", []));
    }
  };

  //handle All InputValues
  const handleInputChange = (
    field: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.target;
    dispatch(createUserStore(field, value));
  };

  //Roles List
  const [rolesList, seRolesList] = useState<Option[]>([]);

  const [createUser] = useMutation(CREATE_EMPLOYEE, {
    onCompleted: (data) => {
      if (data) {
        const { createEmployee } = data;
        if (createEmployee) {
          const { success } = createEmployee;
          if (success) {
            window.history.back();

            setPosPermissionList([]);
            setAdminPermissionList([]);
            setGeneralPermissionList([]);
            formik.resetForm();
            dispatch(createUserStore("firstName", ""));
            dispatch(createUserStore("lastName", ""));
            dispatch(createUserStore("email", ""));
            dispatch(createUserStore("mobile", ""));
            dispatch(createUserStore("roleId", ""));
            dispatch(createUserStore("locationsList", []));
            toast.success(
              <div>
                <Typography>{t("toast.created")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message) {
        if (error.message.trim() === "Mobile should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.mobile")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        } else if (error.message.trim() === "Email should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.email")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        }
      }
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList, LOCATIONS_LIST, User_Roles],
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data) {
        const { updateEmployee } = data;
        if (updateEmployee) {
          const { success } = updateEmployee;
          if (success) {
            window.history.back();
            formik.resetForm();

            setPosPermissionList([]);
            setAdminPermissionList([]);
            setGeneralPermissionList([]);
            dispatch(createUserStore("firstName", ""));
            dispatch(createUserStore("lastName", ""));
            dispatch(createUserStore("email", ""));
            dispatch(createUserStore("mobile", ""));
            dispatch(createUserStore("roleId", ""));
            dispatch(createUserStore("locationsList", []));

            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message) {
        if (error.message.trim() === "Mobile should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.mobile")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        } else if (error.message.trim() === "Email should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.email")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        }
      }
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList, LOCATIONS_LIST, User_Roles],
  });

  const handleEditSaveSubitButton = () => {
    const intArray = formik.values.locationsList.map(Number);
    const data = {
      businessId: +businessId,
      employeeId: editId && +editId,
      firstName: formik.values.firstName.trim(),
      lastName: formik.values.lastName.trim(),
      email: formik.values.email.trim(),
      mobile: `${phoneCountryCode}${formik.values.mobile}`,
      // mobile: mobile,
      role: +formik.values.roleId,
      locations: JSON.stringify(intArray),
    };

    updateUser({
      variables: data,
    });
  };

  const handleCreateSaveSubitButton = () => {
    const intArray = formik.values.locationsList.map(Number);
    const data = {
      businessId: +businessId,
      firstName: formik.values.firstName.trim(),
      lastName: formik.values.lastName.trim(),
      email: formik.values.email.trim(),
      mobile: `${phoneCountryCode}${formik.values.mobile}`,
      role: +formik.values.roleId,
      locations: JSON.stringify(intArray),
    };

    createUser({
      variables: data,
    });
  };

  //Automcomplete functions
  const [permissionsSearchByRoleList, setPermissionsSearchByRoleList] =
    useState<StatePermissionsSearchByRoleList[]>([]);

  const handleArrowBackIcon = () => {
    dispatch(createUserStore("firstName", ""));
    dispatch(createUserStore("lastName", ""));
    dispatch(createUserStore("email", ""));
    dispatch(createUserStore("mobile", ""));
    dispatch(createUserStore("roleId", ""));
    dispatch(createUserStore("locationsList", []));
    dispatch(createUserStore("all", false));
    window.history.back();
    formik.resetForm();
  };

  useEffect(() => {
    if (permissionsSearchByRoleData) {
      if (
        permissionsSearchByRoleData.role !== null &&
        permissionsSearchByRoleData !== undefined
      ) {
        const requiredDataList =
          permissionsSearchByRoleData.role.permissions.map((each: any) => ({
            appliesFor: each.appliesFor,
            permissionId: each.permissionId,
            name: each.name,
          }));
        setPermissionsSearchByRoleList(requiredDataList);
      }
    }
  }, [permissionsSearchByRoleData]);

  const [posPermissionList, setPosPermissionList] = useState<any>([]);
  const [adminPermissionList, setAdminPermissionList] = useState<any>([]);
  const [generalPermissionList, setGeneralPermissionList] = useState<any>([]);

  useEffect(() => {
    if (permissionsSearchByRoleList) {
      const emptyPosPermissionList = permissionsSearchByRoleList.filter(
        (each: any) => JSON.stringify(each.appliesFor).includes("pos")
      );
      const emptyAdminPermissionList = permissionsSearchByRoleList.filter(
        (each: any) => JSON.stringify(each.appliesFor).includes("admin")
      );
      const emptyGeneralPermissionList = permissionsSearchByRoleList.filter(
        (each: any) => JSON.stringify(each.appliesFor).includes("general")
      );
      setPosPermissionList(emptyPosPermissionList);
      setAdminPermissionList(emptyAdminPermissionList);
      setGeneralPermissionList(emptyGeneralPermissionList);
    }
  }, [permissionsSearchByRoleList]);

  const handleRoleChange = (field: string, value: string) => {
    dispatch(createUserStore(field, value));
  };
  const handleClearButton = () => {
    setPosPermissionList([]);
    setAdminPermissionList([]);
    setGeneralPermissionList([]);
    dispatch(createUserStore("firstName", ""));
    dispatch(createUserStore("lastName", ""));
    dispatch(createUserStore("email", ""));
    dispatch(createUserStore("mobile", ""));
    dispatch(createUserStore("roleId", ""));
    dispatch(createUserStore("locationsList", []));
    dispatch(createUserStore("all", false));
    formik.resetForm();
  };
  //handle View All Permissions Button
  const handleViewAllPermissions = () => {
    if (roleId) {
      navigate(`/roles/${roleId}/update-role`);
    }
  };
  const formik = useFormik({
    initialValues: initialValuesOfTextFields,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (editId === undefined) {
        handleCreateSaveSubitButton();
      } else {
        handleEditSaveSubitButton();
      }
    },
  });

  const gettingLocationsCount = (list: any) => {
    if (list.length === 0) {
      return t("users.add_location");
    } else if (list.length === 1) {
      return `${list.length} ${t("users.location")}`;
    } else {
      return `${list.length} ${t("users.locations")}`;
    }
  };

  useEffect(() => {
    if (locationsData) {
      const { storeList } = locationsData;
      if (storeList) {
        const { edges } = storeList;
        if (edges) {
          const requiredDataList = edges.map((each: any) => ({
            storeId: each.node.storeId,
            storeName: each.node.storeName,
            isChecked: false,
          }));
          setInitialLocationsList(requiredDataList);
        }
      }
    }
  }, [locationsData]);

  useEffect(() => {
    if (updateUserData) {
      const { employee } = updateUserData;
      if (employee) {
        const { firstName, lastName, email, locations, role, mobile } =
          employee;
        formik.setFieldValue("firstName", firstName);
        formik.setFieldValue("lastName", lastName);
        formik.setFieldValue("email", email);
        if (mobile) {
          const tenDigits = mobile.replace(phoneCountryCode, "");
          formik.setFieldValue("mobile", tenDigits);
          dispatch(createUserStore("mobile", tenDigits));
        } else {
          formik.setFieldValue("mobile", "");
          dispatch(createUserStore("mobile", ""));
        }

        formik.setFieldValue("roleId", role?.roleId);
        const locationsArray = JSON.parse(locations);
        const stringArray = locationsArray?.map(String);
        formik.setFieldValue("locationsList", stringArray);

        dispatch(createUserStore("locationsList", stringArray));
        dispatch(createUserStore("firstName", firstName));
        dispatch(createUserStore("lastName", lastName));
        dispatch(createUserStore("email", email));

        if (role) {
          dispatch(createUserStore("roleId", role.roleId));
        }
      }
    }
  }, [updateUserData]);

  useEffect(() => {
    if (userRolesData) {
      const { roles } = userRolesData;
      if (roles) {
        const { edges } = roles;
        if (edges) {
          const requiredDataList = edges.map((each: any) => ({
            id: each.node.roleId,
            role: each.node.roleName,
          }));
          seRolesList(requiredDataList);
        }
      }
    }
  }, [userRolesData]);

  const [expanded, setExpanded] = useState<boolean>(false);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accordionRef.current &&
      !accordionRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Box
      sx={{ backgroundColor: "#f9f9f9", padding: "20px" }}
      className="create-user-bg-container"
    >
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="create-a-use-role-header-order"
          sx={{ padding: "15px" }}
        >
          <IconButton
            onClick={handleArrowBackIcon}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <ArrowBackIcon
              style={{ backgroundColor: "#EEEEEE", color: "#333333" }}
            />
          </IconButton>
          <Typography className="create-a-use-role-heading">
            {editId !== undefined
              ? t("users.edit_user")
              : t("users.create_a_user")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            {editId === undefined && (
              <Button
                sx={{ textTransform: "none" }}
                onClick={handleClearButton}
                className="create-a-use-role-clear-button"
                variant="outlined"
                color="warning"
              >
                {t("users.clear")}
              </Button>
            )}
            <Button
              type="submit"
              sx={{ textTransform: "none" }}
              variant="contained"
              className="create-user-save-button"
            >
              {t("users.save")}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            zIndex: "1",
            alignItems: "flex-start",
            padding: "20px",
          }}
        >
          <Paper
            elevation={1}
            sx={{
              width: "70%",
              maxHeight: "49vh",
              zIndex: "10",
              padding: "15px 15px 20px 15px",
              borderLeft: "2px solid #AE9BE2",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <Box>
              <Typography className="create-user-details-heading">
                {t("users.details")}
              </Typography>
            </Box>
            <Box style={{ padding: "15px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                  margin: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    margin: "2px 5px 0px 5px",
                  }}
                >
                  <TextField
                    onChange={(e) => {
                      if (e.target.value.length <= 16) {
                        handleInputChange("firstName", e);
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    name="firstName"
                    value={formik.values.firstName || ""}
                    color="warning"
                    id="demo-helper-text-misaligned"
                    label={
                      <Typography className="create-a-use-role-label">
                        {t("users.first_name")}
                      </Typography>
                    }
                    sx={{ width: "95%" }}
                  />
                  {/* Only show helper text if there's no error */}
                  {formik.values.firstName.length >= 1 && (
                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginRight: "20px",
                        marginTop: "2px",
                      }}
                    >
                      <Typography className="create-a-use-role-label">
                        {formik.values.firstName.length}/16
                      </Typography>
                    </Box>
                  )}
                  {/* Show error message if there's an error */}
                  {formik.touched.firstName && formik.errors.firstName && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    margin: "2px 5px 0px 5px",
                  }}
                >
                  <TextField
                    onChange={(e) => {
                      if (e.target.value.length <= 16) {
                        handleInputChange("lastName", e);
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    name="lastName"
                    value={formik.values.lastName || ""}
                    color="warning"
                    id="demo-helper-text-misaligned"
                    label={
                      <Typography className="create-a-use-role-label">
                        {t("users.last_name")}
                      </Typography>
                    }
                    sx={{ width: "95%" }}
                  />
                  {formik.values.lastName.length >= 1 && (
                    <Box
                      sx={{
                        marginLeft: "auto",
                        marginRight: "20px",
                        marginTop: "2px",
                      }}
                    >
                      <Typography className="create-a-use-role-label">
                        {formik.values.lastName.length}/16
                      </Typography>
                    </Box>
                  )}
                  {formik.touched.lastName && formik.errors.lastName && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "90%",
                  margin: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    margin: "2px 5px 0px 5px",
                  }}
                >
                  <TextField
                    onChange={(e) => {
                      handleInputChange("email", e);
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    name="email"
                    value={formik.values.email}
                    color="warning"
                    id="demo-helper-text-misaligned"
                    label={
                      <Typography className="create-a-use-role-label">
                        {t("users.email_address")}
                      </Typography>
                    }
                    sx={{ width: "95%" }}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {formik.touched.email &&
                          (formik.errors.email === "Email is Invalid"
                            ? t("users.invalid_email")
                            : t("error.required"))}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                    margin: "2px 5px 0px 5px",
                  }}
                >
                  <TextField
                    onBlur={formik.handleBlur}
                    name="mobile"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 12)
                      ) {
                        handleInputChange("mobile", e);
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.mobile || ""}
                    color="warning"
                    id="demo-helper-text-misaligned"
                    className="hide-number-input-icons"
                    InputProps={{
                      inputProps: {
                        minLength: 10,
                        maxLength: 11,
                      },
                    }}
                    label={
                      <Typography className="create-a-use-role-label">
                        {t("users.phone_number")}
                      </Typography>
                    }
                    sx={{ width: "95%" }}
                  />
                  {formik.touched.mobile && formik.errors.mobile && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {formik.errors.mobile === "Invalid Number"
                          ? t("users.mobile_10_digits")
                          : t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  padding: "10px",
                  width: "92%",
                  margin: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <FormControl sx={{ width: "93%" }}>
                    <InputLabel
                      className="create-a-use-role-label"
                      id="assign-role-label"
                    >
                      {t("users.assign_role")}
                    </InputLabel>
                    <Select
                      color="warning"
                      id="assign-role-label"
                      label={<Typography>{t("users.assign_role")}</Typography>}
                      value={formik.values.roleId}
                      onBlur={formik.handleBlur}
                      name="name"
                      onChange={(e) => {
                        handleRoleChange("roleId", e.target.value);
                        formik.handleChange(e);
                      }}
                    >
                      {rolesList.map((each) => (
                        <MenuItem
                          disabled={each.role?.trim() === "Admin"}
                          sx={{ textTransform: "capitalize" }}
                          key={each.id}
                          id=""
                          value={each.id}
                        >
                          {each.role}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.roleId && formik.errors.roleId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ width: "100%", height: "5vh" }}>
                    <Accordion
                      expanded={expanded}
                      ref={accordionRef}
                      onChange={(event, isExpanded) => handleChange(isExpanded)}
                      sx={{
                        width: "93%",
                        border: "1px solid #d9d9d9",
                        padding: "1.5px",
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        sx={{ width: "100%", marginBottom: 0 }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          className="create-a-use-role-label"
                          sx={{ marginBottom: "0px" }}
                        >
                          {gettingLocationsCount(formik.values.locationsList)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <TextField
                            onChange={(e) => handleSearchIcon(e)}
                            //@ts-ignore
                            placeholder={t("users.search_by_name")}
                            id="outlined-start-adornment"
                            sx={{
                              width: "100%",
                            }}
                            color="warning"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            height: "37vh",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              overflowY: "auto",
                              maxHeight: "32vh",
                              marginTop: "10px",
                              minHeight: "10vh",
                            }}
                          >
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="all"
                                    checked={
                                      formik.values.locationsList.length ===
                                      initialLocationsList.length
                                    }
                                    onChange={(e) =>
                                      handlePopupCheckbo("locationsList", e)
                                    }
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "1.75rem",
                                      },
                                    }}
                                    color="warning"
                                  />
                                }
                                label={
                                  <Typography className="driver-petty-cash-popup-checkbox">
                                    {t("buttons.all")}
                                  </Typography>
                                }
                              />
                              {searchResults.map((each: any) => (
                                <FormControlLabel
                                  id="assign-role-label"
                                  key={each.storeId}
                                  control={
                                    <Checkbox
                                      onChange={(e) => {
                                        handlePopupCheckbox(
                                          "locationsList",
                                          each.storeId,
                                          e.target.checked
                                        );
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "1.75rem",
                                        },
                                      }}
                                      color="warning"
                                      checked={formik.values.locationsList.includes(
                                        each.storeId
                                      )}
                                    />
                                  }
                                  label={
                                    <Typography
                                      sx={{ textTransform: "capitalize" }}
                                      className="create-a-user-label"
                                    >
                                      {each.storeName}
                                    </Typography>
                                  }
                                />
                              ))}
                            </FormGroup>
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  {formik.touched.locationsList &&
                    formik.errors.locationsList && (
                      <Box sx={{ zIndex: 10, marginTop: "10px" }}>
                        <Typography className="create-a-user-formik-label">
                          {t("error.atleast_one_location")}
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </form>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: "20px",
          width: "100%",
        }}
      >
        <Paper
          elevation={1}
          sx={{
            padding: "15px",
            borderRadius: "5px",
            width: "100%",
            borderLeft: "2px solid #BAE29B",
            marginTop: "15px",
          }}
        >
          <Box>
            <Typography className="create-user-second-table-heading">
              {t("users.user_access_permissions_for_assigned_role")}
            </Typography>
          </Box>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Paper
              elevation={0}
              sx={{
                width: "30%",
                padding: "5px",
                border: "1px solid #ABABAB",
                height: "50vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography className="create-user-checkbox-heading">
                    {t("users.pos")}
                  </Typography>
                  <Typography className="create-user-checkbox-paragraph">
                    {t("users.users_can_log")}
                  </Typography>
                </div>
              </div>
              <Divider />

              <Box
                sx={{
                  padding: "10px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  height: "32vh",
                }}
              >
                <FormGroup>
                  {posPermissionList?.map((each: any) => (
                    <FormControlLabel
                      key={each.permissionId}
                      defaultChecked
                      checked
                      control={<Checkbox disabled color="warning" />}
                      label={
                        <Typography className="create-user-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.75rem" } }}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>

            <Paper
              elevation={0}
              sx={{ width: "30%", border: "1px solid #ABABAB", height: "50vh" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography className="create-user-checkbox-heading">
                    {t("users.back_office")}
                  </Typography>
                  <Typography className="create-user-checkbox-paragraph">
                    {t("users.users_can_log_into_admin")}
                  </Typography>
                </div>
              </div>
              <Divider />
              <Box
                sx={{
                  padding: "10px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  height: "30vh",
                }}
              >
                <FormGroup sx={{ padding: "10px" }}>
                  {adminPermissionList?.map((each: any) => (
                    <FormControlLabel
                      key={each.permissionId}
                      defaultChecked
                      checked
                      control={<Checkbox disabled color="warning" />}
                      label={
                        <Typography className="create-user-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.75rem" } }}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>

            <Paper
              elevation={0}
              sx={{ width: "30%", border: "1px solid #ABABAB", height: "50vh" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  alignItems: "center",
                }}
              >
                <div>
                  <Typography className="create-user-checkbox-heading">
                    {t("users.general")}
                  </Typography>
                  <Typography className="create-user-checkbox-paragraph">
                    {t("users.general_permissions")}
                  </Typography>
                </div>
              </div>
              <Divider />
              <Box
                sx={{
                  padding: "10px",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  height: "35vh",
                }}
              >
                <FormGroup sx={{ padding: "10px" }}>
                  {generalPermissionList?.map((each: any) => (
                    <FormControlLabel
                      key={each.permissionId}
                      defaultChecked
                      checked
                      control={<Checkbox disabled color="warning" />}
                      label={
                        <Typography className="create-user-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: "1.75rem" } }}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>
          </div>

          <Box sx={{ textAlign: "right", margin: "15px" }}>
            <Button
              className="create-a-user-view-all-text"
              sx={{ textTransform: "none" }}
              onClick={handleViewAllPermissions}
              variant="text"
            >
              {t("users.view_all_permissions")}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Box>
  );
}

export default CreateUser;
