import * as React from "react";
import { useState } from "react";
import { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { MoreVert } from "@mui/icons-material";
import { Button, Switch, Typography } from "@mui/material";
import "./menuListing.css";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { useMutation, useQuery } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import { DUPLICATE_MENU } from "../../graphicalQl/mutation/menuMutation";
import DeleteDailouge from "../category/deleteDailouge";
import { MENU_STATUS_CHANGE } from "../../graphicalQl/mutation/menuMutation";
import { DELETE_MENU } from "../../graphicalQl/mutation/menuMutation";
import { useSelector } from "react-redux";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PopupWithTitle from "../popups/popupWithTitle";
import { SPECIALS_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import moment from "moment";
import { localeToFormat } from "../../utils/dateFormat";
import CustomFooter from "../category/footer";
import { PUBLISH_MENU_IN_LIST } from "../../graphicalQl/mutation/menuMutation";

interface Data {
  id: number;
  menuName: string;
  status: boolean;
  currentVersion: number;
  updatedOn: any;
}

const rows: Data[] = [];

const MenuListing = () => {
  const { t } = useTranslation();

  const [updatedRows, setUpdatedRows] = useState(rows);
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
  const [deletemodal, setDeleteModal] = useState(false);
  const [delteBundleId, setDeleteBundleId] = useState("");

  const { menu } = useSelector((state: any) => state.mainData);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const [deletMenu] = useMutation(DELETE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [menuStatusChange] = useMutation(MENU_STATUS_CHANGE, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const getDeleteMenuId = (id: string) => {
    setDeleteBundleId(id);
  };
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    setDeleteBundleId("");
  };
  const handleDelete = (id: string) => {
    deletMenu({ variables: { menuIds: JSON.stringify([+id]) } });
    setDeleteBundleId("");
  };

  const [statusId, setStatusId] = useState<number | null>(null);
  const [statusState, setStatusState] = useState<string>("");

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setStatusId(id);
    // setStatusState(event.target.checked)
  };

  const handleStatusCall = () => {
    const status = statusState;
    menuStatusChange({
      variables: { menuId: statusId && +statusId, menuStatus: status },
    });
  };

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const filterRows = updatedRows.filter((row) =>
      rowSelectionModel.includes(row.id)
    );
    const menuIds = filterRows.map((each) => +each.id);
    deletMenu({ variables: { menuIds: JSON.stringify(menuIds) } });
    setRowSelectionModel([]);
  };
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const { loading, data } = useQuery(MENU_LIST, {
    context: { clientName: "categoryClient" },
    variables: {
      businessId: +businessId,
      locationId: +locationId,
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (data && data.menuList) {
      const list = data.menuList.edges.map((each: any) => ({
        id: each.node.menuId,
        menuName: each.node.name,
        // status: each.node.menuStatus === "Active" ? true : false,
        status: each.node.menuStatus,
        currentVersion: each.node.version,
        updatedOn: each.node.createdOn,
        qrCode: each.node.qrCode,
      }));
      setUpdatedRows(list);
    }
  }, [data]);
  const [duplicateMenu] = useMutation(DUPLICATE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleDuplicate = (id: number) => {
    duplicateMenu({ variables: { menuId: id } });
  };
  const [menuPublish, { loading: publishLoading }] = useMutation(
    PUBLISH_MENU_IN_LIST,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [MENU_LIST],
      onCompleted: () => {
        toast.success(
          <div>
            <Typography>{t("toast.published")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
    }
  );

  const handlePublish = (id: number) => {
    menuPublish({ variables: { menuId: id } });
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.menuList?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.menuList?.pageInfo.startCursor || null);
  };
  const [editId, setEditId] = useState<number | null>(null);
  const handleEdit = (id: number) => {
    setEditId(id);
    navigate(`/menu/${id}/menu-edit`);
  };

  const handleId = () => {
    setEditId(null);
  };

  function CustomPopoverCell({
    menuId,
    qrCode,
    name,
    status,
  }: {
    menuId: number;
    qrCode: string;
    name: string;
    status: string;
  }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const navigate = useNavigate();

    const handleHistoryNavigate = (menuId: number) => {
      navigate(`/menu/${menuId}/menu-history`);
    };

    const handleNavigation2 = () => {
      navigate(`/menu/${qrCode}/digital-menu/?name=${name}`);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const popoverContent = (
      <List style={{ padding: "12px 0px 12px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleEdit(menuId)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.edit")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleDeleteModal();
            getDeleteMenuId(menuId.toString());
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.delete")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleHistoryNavigate(menuId)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.history")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleDuplicate(menuId)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.duplicate")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={handleNavigation}
        >
          <ListItem
            style={{ width: "200px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.go_to_setUp")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={handleNavigation2}
        >
          <ListItem
            style={{ width: "200px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu_setUp.generate_qr_code")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          disabled={publishLoading}
          onClick={() => {
            if (status === "Pending") {
              handlePublish(menuId);
            } else {
              setIsModalOpen1(true);
              setStatusState(
                status === "Active"
                  ? "InActive"
                  : status === "Inactive"
                  ? "Active"
                  : "Pending"
              );
              setStatusId(menuId);
            }
          }}
        >
          <ListItem
            style={{ width: "200px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            {/* <ListItemText primary="Generate QR Code" /> */}
            <Typography>
              {status === "Active"
                ? t("buttons.in_active")
                : status === "InActive"
                ? t("buttons.active")
                : t("menu_setUp.publish")}
            </Typography>
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "200px",
              height: "380px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/menuSetup/menu-setup");
  };

  const handleRowClass = () => {
    return "menuListingRow";
  };
  const userLang = navigator.language;

  const handleCellClass = () => {
    return "menuListigCell";
  };
  const columns: GridColDef[] = [
    {
      field: "menuName",
      //@ts-ignore
      headerName: t("menu_setUp.menu_name"),
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("menu_setUp.status"),

      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
      isSortable: false,
      hideSortIcons: true,
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <Switch
      //       checked={params.row.status}
      //       onChange={(event) => { handleStatus(event, params.row.id); setIsModalOpen1(true) }}
      //       color='warning'
      //     />
      //   )
      // }
      renderCell: (params) => {
        let activeClassnames;
        if (params.value === "Active") {
          activeClassnames = "user-list-active-text";
        } else if (params.value === "InActive") {
          activeClassnames = "user-list-inactive-text";
        }
        return (
          <Typography className={activeClassnames}>
            {params.value === "Active"
              ? t("buttons.active")
              : params.value === "InActive"
              ? t("buttons.in_active")
              : "Pending"}
          </Typography>
        );
      },
    },

    {
      field: "currentVersion",
      //@ts-ignore
      headerName: t("menu_setUp.current_version"),

      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      flex: 1,
      disableColumnMenu: true,
      isSortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        return `version ${params.row.currentVersion}`;
      },
    },
    {
      field: "updatedOn",
      //@ts-ignore
      headerName: t("menu_setUp.update_on"),

      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      flex: 1,
      disableColumnMenu: true,
      isSortable: false,
      hideSortIcons: true,
      renderCell: (params) => {
        // Convert the string to a Date object
        const dateObject = new Date(params.row.updatedOn);

        // Check if the conversion was successful and dateObject is a valid Date
        if (!isNaN(dateObject.getTime())) {
          // Format the Date object to "YYYY-MM-DD"
          const formattedDate = `${dateObject.getFullYear()}-${String(
            dateObject.getMonth() + 1
          ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(2, "0")}`;
          return moment(params.row.updatedOn).format(
            `${localeToFormat[userLang]}`
          );
        } else {
          // Handle the case where the conversion failed

          return "Invalid Date";
        }
      },
    },
    {
      field: ".",
      //@ts-ignore
      headerName: t("menu.bundle.action"),
      disableColumnMenu: true,
      width: 120,
      filterable: false,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      hideSortIcons: true,
      isSortable: false,
      renderCell: (params) => {
        return (
          <CustomPopoverCell
            menuId={params.row.id}
            qrCode={params.row.qrCode}
            name={params.row.menuName}
            status={params.row.status}
          />
        );
      },
    },
  ];
  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);

  const addMenu = () => {
    navigate("/menu/create-menu");
  };
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "slugName",
      sort: "asc",
    },
  ]);

  // ... your other state variables and functions ...

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  if (loading) {
    return <CircularLoader />;
  }

  const handleForQr = () => {
    navigate(`/menu/digital-menu/?name=common-menu`);
  };

  return (
    <Box margin="2%">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="menuListigmenu">
          {t("menu_setUp.menu_listing")}
        </Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            className="generate-qr-menu"
            sx={{ textTransform: "none" }}
            variant="outlined"
            onClick={handleForQr}
          >
            Generate QR Menu
          </Button>
          <Button
            className="main-button"
            sx={{ textTransform: "none" }}
            onClick={addMenu}
          >
            {t("menu_setUp.create_menu")}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button
              className="main-button"
              sx={{ textTransform: "none", padding: "0.6rem" }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              {t("menu_setUp.delete")}
            </Button>
          )}
        </Box>
      </Box>
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDelete}
        id={delteBundleId}
        handleDeleteClick={handleDeleteClick}
        type="menu"
      />

      <Box sx={{ height: "70vh", marginTop: "20px" }}>
        <DataGrid
          rows={updatedRows}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columns={columns}
          columnHeaderHeight={50}
          sortModel={sortModel}
          // initialState={{
          //   pagination: {
          //     paginationModel: {
          //       pageSize: 25,
          //     },
          //   },
          // }}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={[5, 15, 25]}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={data?.menuList?.totalCount || 0}
          paginationModel={paginationModel}
          onPaginationModelChange={(newModel) => {
            if (newModel.page > currentPage) {
              handleEndCursor();
            } else if (newModel.page < currentPage) {
              handleStartCursor();
            }

            setCurrentPage(newModel.page); // Update the current page
            setPaginationModel(newModel);
          }}
          paginationMode="server"
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          components={{
            Footer: (props) => (
              <CustomFooter
                totalRowCount={Math.ceil(
                  (data?.menuList?.totalCount || 0) / paginationModel.pageSize
                )}
                {...props}
                page={paginationModel.page}
              />
            ),
          }}
          // sx={{

          //   '.MuiTablePagination-displayedRows': {
          //     display: 'none', // 👈 to hide huge pagination number
          //   },
          // }}
          sx={{
            ".MuiTablePagination-displayedRows": {
              display: "none", // 👈 to hide huge pagination number
            },
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
              {
                outline: "solid #1976d2 0px !important",
              },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
            // marginLeft: "20px",
          }}
        />
      </Box>

      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Menu?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => {
          setIsModalOpen1(false);
          handleStatusCall();
        }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
    </Box>
  );
};
export default MenuListing;
