import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ColorPicker from "../../utils/colorPicker";
import { UPDATE_APP_SETTINGS } from "../../graphicalQl/mutation/locationMutation";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import "./webSettings.css";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { GET_DATA_FOR_MANAGING_APP } from "../../graphicalQl/usequery/locationQuery";

interface StateForColors {
  primaryColor: string;
  secondaryColor: string;
  backgroundColor: string;
}

const initialValuesForColors: StateForColors = {
  primaryColor: "#f38b08",
  secondaryColor: "#f38b08",
  backgroundColor: "#f38b08",
};

const validationSchemaForColors = Yup.object({
  primaryColor: Yup.string().required("required"),
  secondaryColor: Yup.string().required("required"),
  backgroundColor: Yup.string().required("required"),
});

interface StateForCustomerApp {
  appLink: string;
  iosLink: string;
  appVersion: string;
  iosAppVersion: string;
  forceUpdateApp: boolean;
  appMaintenance: boolean;
}

const initialValuesForCustomerApp: StateForCustomerApp = {
  appLink: "",
  iosLink: "",
  appVersion: "",
  iosAppVersion: "",
  forceUpdateApp: false,
  appMaintenance: false,
};

const validationSchemaForCustomerApp = Yup.object({});

const validationSchemaForDriverApp = Yup.object({});

interface StateForDriverApp {
  appLink: string;
  iosLink: string;
  appVersion: string;
  iosAppVersion: string;
  forceUpdateApp: boolean;
  appMaintenance: boolean;
}

const initialValuesForDriverApp: StateForDriverApp = {
  appLink: "",
  iosLink: "",
  appVersion: "",
  iosAppVersion: "",
  forceUpdateApp: false,
  appMaintenance: false,
};

const WebSettings = () => {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const filteredCategory = {
    businessId: businessId,
  };
  const {
    loading: loading,
    error: error,
    data: data,
  } = useQuery(GET_DATA_FOR_MANAGING_APP, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      const { business } = data;
      if (business) {
        const { webSettings } = business;
        if (webSettings) {
          const {
            colour_settings,
            driver_app_settings,
            customer_app_settings,
          } = JSON.parse(webSettings);
          if (colour_settings) {
            const { primary_colour, secondary_colour, background_colour } =
              colour_settings;
            formikForColors.setValues({
              primaryColor: primary_colour,
              secondaryColor: secondary_colour,
              backgroundColor: background_colour,
            });
          }
          if (driver_app_settings) {
            const {
              app_link,
              ios_link,
              android_app_version,
              ios_app_version,
              force_update_app,
              app_maintainance,
            } = driver_app_settings;
            formikForDriverApp.setValues({
              appLink: app_link,
              iosLink: ios_link,
              appVersion: android_app_version,
              iosAppVersion: ios_app_version,
              forceUpdateApp: force_update_app,
              appMaintenance: app_maintainance,
            });
          }
          if (customer_app_settings) {
            const {
              app_link,
              ios_link,
              android_app_version,
              ios_app_version,
              force_update_app,
              app_maintainance,
            } = customer_app_settings;
            formikForCustomerApp.setValues({
              appLink: app_link,
              iosLink: ios_link,
              appVersion: android_app_version,
              iosAppVersion: ios_app_version,
              forceUpdateApp: force_update_app,
              appMaintenance: app_maintainance,
            });
          }
        }
      }
    }
  }, [data]);

  const [updateWebSettings] = useMutation(UPDATE_APP_SETTINGS, {
    onCompleted: (data) => {
      if (data) {
        const { updateBusiness } = data;
        if (updateBusiness) {
          const { success } = updateBusiness;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.log(error.message);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [],
  });
  const formikForColors = useFormik({
    initialValues: initialValuesForColors,
    validationSchema: validationSchemaForColors,
    onSubmit: (values) => {
      const variables = {
        primary_colour: formikForColors.values.primaryColor,
        secondary_colour: formikForColors.values.secondaryColor,
        background_colour: formikForColors.values.backgroundColor,
      };

      updateWebSettings({
        variables: {
          businessId: businessId,
          colourSettings: JSON.stringify(variables),
        },
      });
    },
  });

  const formikForCustomerApp = useFormik({
    initialValues: initialValuesForCustomerApp,
    validationSchema: validationSchemaForCustomerApp,

    onSubmit: (values) => {
      const variables = {
        app_link: formikForCustomerApp.values.appLink,
        ios_link: formikForCustomerApp.values.iosLink,
        android_app_version: formikForCustomerApp.values.appVersion,
        ios_app_version: formikForCustomerApp.values.iosAppVersion,
        force_update_app: formikForCustomerApp.values.forceUpdateApp,
        app_maintainance: formikForCustomerApp.values.appMaintenance,
      };

      updateWebSettings({
        variables: {
          businessId: businessId,
          customerAppSettings: JSON.stringify(variables),
        },
      });
    },
  });

  const formikForDriverApp = useFormik({
    initialValues: initialValuesForDriverApp,
    validationSchema: validationSchemaForDriverApp,
    onSubmit: (values) => {
      const variables = {
        app_link: formikForDriverApp.values.appLink,
        ios_link: formikForDriverApp.values.iosLink,
        android_app_version: formikForDriverApp.values.appVersion,
        ios_app_version: formikForDriverApp.values.iosAppVersion,
        force_update_app: formikForDriverApp.values.forceUpdateApp,
        app_maintainance: formikForDriverApp.values.appMaintenance,
      };

      updateWebSettings({
        variables: {
          businessId: businessId,
          driverAppSettings: JSON.stringify(variables),
        },
      });
    },
  });

  const handleColorChangeForPrimaryColor = (color: string) => {
    formikForColors.setFieldValue("primaryColor", color);
  };
  const handleColorChangeForSecondaryColor = (color: string) => {
    formikForColors.setFieldValue("secondaryColor", color);
  };
  const handleColorChangeForBackgroundColor = (color: string) => {
    formikForColors.setFieldValue("backgroundColor", color);
  };

  useEffect(() => {
    console.log(formikForCustomerApp);
  }, [formikForCustomerApp]);

  return (
    <Box className="web-settings-container">
      <Typography className="web-settings-header">
        Manage Web Settings
      </Typography>
      <Paper className="web-settings-paper" elevation={2}>
        <form onSubmit={formikForColors.handleSubmit}>
          <Box className="web-settings-header">
            <Typography className="web-settings-sub-header">
              Color Settings
            </Typography>
            <Button
              type="submit"
              className="web-settings-save-button"
              variant="contained"
            >
              Save
            </Button>
          </Box>
          <Box className="web-settings-textfields-container">
            <Box sx={{ width: "100%" }}>
              <ColorPicker
                colorValue={formikForColors.values.primaryColor}
                handleColorChange={handleColorChangeForPrimaryColor}
                label="Primary Color"
              />
              {formikForColors.errors.primaryColor && (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {t("error.required")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <ColorPicker
                colorValue={formikForColors.values.secondaryColor}
                handleColorChange={handleColorChangeForSecondaryColor}
                label="Secondary Color"
              />
              {formikForColors.errors.secondaryColor && (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {t("error.required")}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <ColorPicker
                colorValue={formikForColors.values.backgroundColor}
                handleColorChange={handleColorChangeForBackgroundColor}
                label="Background Color"
              />
              {formikForColors.errors.backgroundColor && (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {t("error.required")}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      </Paper>

      <Paper className="web-settings-paper" elevation={2}>
        <form onSubmit={formikForCustomerApp.handleSubmit}>
          <Box className="web-settings-header">
            <Typography className="web-settings-sub-header">
              Customer App Settings
            </Typography>
            <Button
              type="submit"
              className="web-settings-save-button"
              variant="contained"
            >
              Save
            </Button>
          </Box>
          <Box className="web-settings-app-settings-container">
            <Box sx={{ width: "100%" }}>
              <Typography className="web-settings-textfield-label">
                App Link
              </Typography>
              <TextField
                name="appLink"
                onChange={formikForCustomerApp.handleChange}
                onBlur={formikForCustomerApp.handleBlur}
                id="outlined-basic"
                variant="outlined"
                className="websettings-textfield"
                value={formikForCustomerApp.values.appLink}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography className="web-settings-textfield-label">
                iOS Link
              </Typography>
              <TextField
                onChange={formikForCustomerApp.handleChange}
                onBlur={formikForCustomerApp.handleBlur}
                id="outlined-basic"
                variant="outlined"
                className="websettings-textfield"
                value={formikForCustomerApp.values.iosLink}
                name="iosLink"
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Version(Android)
                </Typography>
                <TextField
                  onChange={formikForCustomerApp.handleChange}
                  onBlur={formikForCustomerApp.handleBlur}
                  id="outlined-basic"
                  variant="outlined"
                  className="websettings-app-textfield"
                  name="appVersion"
                  value={formikForCustomerApp.values.appVersion}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Version(iOS)
                </Typography>
                <TextField
                  onChange={formikForCustomerApp.handleChange}
                  onBlur={formikForCustomerApp.handleBlur}
                  id="outlined-basic"
                  variant="outlined"
                  className="websettings-app-textfield"
                  name="iosAppVersion"
                  value={formikForCustomerApp.values.iosAppVersion}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  Force Update App
                </Typography>
                <FormControlLabel
                  onChange={formikForCustomerApp.handleChange}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  control={<Checkbox color="warning" />}
                  label="Force Update App"
                  name="forceUpdateApp"
                  checked={formikForCustomerApp.values.forceUpdateApp}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Maintenance
                </Typography>
                <FormControlLabel
                  onChange={formikForCustomerApp.handleChange}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  control={<Checkbox color="warning" />}
                  label="App Maintenance"
                  name="appMaintenance"
                  checked={formikForCustomerApp.values.appMaintenance}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
      <Paper className="web-settings-paper" elevation={2}>
        <form onSubmit={formikForDriverApp.handleSubmit}>
          <Box className="web-settings-header">
            <Typography className="web-settings-sub-header">
              Driver App Settings
            </Typography>
            <Button
              type="submit"
              className="web-settings-save-button"
              variant="contained"
            >
              Save
            </Button>
          </Box>
          <Box className="web-settings-app-settings-container">
            <Box sx={{ width: "100%" }}>
              <Typography className="web-settings-textfield-label">
                App Link
              </Typography>
              <TextField
                onChange={formikForDriverApp.handleChange}
                onBlur={formikForDriverApp.handleBlur}
                id="outlined-basic"
                variant="outlined"
                className="websettings-app-textfield"
                name="appLink"
                value={formikForDriverApp.values.appLink}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Typography className="web-settings-textfield-label">
                iOS Link
              </Typography>
              <TextField
                onChange={formikForDriverApp.handleChange}
                onBlur={formikForDriverApp.handleBlur}
                id="outlined-basic"
                variant="outlined"
                className="websettings-app-textfield"
                name="iosLink"
                value={formikForDriverApp.values.iosLink}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Version(Android)
                </Typography>
                <TextField
                  onChange={formikForDriverApp.handleChange}
                  onBlur={formikForDriverApp.handleBlur}
                  id="outlined-basic"
                  variant="outlined"
                  className="websettings-app-textfield"
                  name="appVersion"
                  value={formikForDriverApp.values.appVersion}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Version(iOS)
                </Typography>
                <TextField
                  onChange={formikForDriverApp.handleChange}
                  onBlur={formikForDriverApp.handleBlur}
                  id="outlined-basic"
                  variant="outlined"
                  className="websettings-app-textfield"
                  name="iosAppVersion"
                  value={formikForDriverApp.values.iosAppVersion}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  Force Update App
                </Typography>
                <FormControlLabel
                  onChange={formikForDriverApp.handleChange}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  control={<Checkbox color="warning" />}
                  label="Force Update App"
                  name="forceUpdateApp"
                  checked={formikForDriverApp.values.forceUpdateApp}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography className="web-settings-textfield-label">
                  App Maintenance
                </Typography>
                <FormControlLabel
                  onChange={formikForDriverApp.handleChange}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  control={<Checkbox color="warning" />}
                  label="App Maintenance"
                  name="appMaintenance"
                  checked={formikForDriverApp.values.appMaintenance}
                />
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default WebSettings;
