import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./comfirmationPopup.css";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleSaveButton: () => void;
  accessText: string;
}

const ComfirmationPopup = (props: Props) => {
  const { open, handleClose, accessText, handleOpen, handleSaveButton } = props;
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <IconButton
            onClick={handleClose}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider
          sx={{ marginTop: "5px", marginBottom: "5px", width: "100%" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <Typography
            className="comfirmation-popup-text"
            sx={{ color: "#707070" }}
          >
            {t("deliveries.selecting_this_checkbox")}{" "}
            <span style={{ color: "#F38B08" }}>{accessText} </span>
            {t("deliveries.as_your_default")}{" "}
            <span style={{ color: "#333333" }}>
              {t("deliveries.are_you_sure?")}
            </span>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleClose}
            color="warning"
            variant="outlined"
            sx={{ textTransform: "none", marginRight: "10px" }}
          >
            {t("buttons.no_dont")}
          </Button>
          <Button
            onClick={handleSaveButton}
            color="warning"
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            {t("buttons.yes_continue")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ComfirmationPopup;
