import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Typography,
  Checkbox,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RenderGoogleMap from "../utils/googleMaps";
import AlertPopupForLastRuleDelete from "./alertPopupForLastRuleDelete.tsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  DELIVERY_RULES_LIST,
  DEFAULT_ZONE,
} from "../../graphicalQl/usequery/deliveryManagementQuery.tsx";
import Modal from "@mui/material/Modal";
import {
  ADD_DELIVERY_RULE,
  UPDATE_DELIVERY_RULE,
  DELETE_DELIVERY_RULE,
  CHANGE_DEFAULT_DELIVERY_RULE,
} from "../../graphicalQl/mutation/deliveryManagementMutation.tsx";
import { useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

import { useQuery } from "@apollo/client";
import ComfirmationPopup from "./comfirmationPopup.tsx";
import TimeRangePicker from "../popups/timeRangePicker.tsx";
import "./distanceWise.css";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader.tsx";
import DeletePopup from "../popups/deletePopup.tsx";
import { useTranslation } from "react-i18next";
import NodataImage from "../../utils/nodataImage.tsx";

interface Newrow {
  id: Date;
  color: string | null;
  distance: string | null;
  cartAmount: string | null;
  shippingRate: string | null;
  extraShippingRate: string | null;
  timeTaken: string | null;
  startTime: string | null;
  endTime: string | null;
  isEdited: boolean;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",

  pt: 2,
  px: 4,
  pb: 3,
};

interface TimeRange {
  startTime: string;
  endTime: string;
}

interface Location {
  lat: number;
  lng: number;
}

interface Areawise {
  deliverySettingId: string | null;
  updatedDefaultZone: string | null;
  updatedDefaultZoneForStar: string | null;
  editId: undefined | string;
  storeLocation: Location | null;
  editRowId: string | null;
  setEditRowId: React.Dispatch<React.SetStateAction<string | null>>;
  locationKey: string | null;
}
function DistanceWise(props: Areawise) {
  const {
    deliverySettingId,
    locationKey,
    setEditRowId,
    editRowId,
    updatedDefaultZone,
    updatedDefaultZoneForStar,
    editId,
    storeLocation,
  } = props;
  const { t } = useTranslation();
  const [addingDeliveryRule] = useMutation(ADD_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { addDeliveryRule } = data;
        const { success } = addDeliveryRule;
        if (success) {
          setActiveDistanceButton(false);
          setHandleAddButton(false);
          setHandleTime(false);
          setActiveDistanceButton(false);
          toast.success(
            <div>
              <Typography>{t("toast.created")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );

          formikForDeliveryRule.resetForm();
          formik.resetForm();
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [updateDeliveryRule] = useMutation(UPDATE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { editDeliveryRule } = data;
        const { success } = editDeliveryRule;
        if (success) {
          toast.success(
            <div>
              <Typography>{t("toast.updated")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );
          formikForDeliveryRule.resetForm();
          formik.resetForm();
          const filtereddata = rows.map((each: any) => {
            return { ...each, isEdited: false };
          });
          setRows(filtereddata);
          setActiveDistanceButton(false);
          setHandleAddButton(false);
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [deleteDeliveryRule] = useMutation(DELETE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [changeDefaultDeliveryRule] = useMutation(
    CHANGE_DEFAULT_DELIVERY_RULE,
    {
      onCompleted: (data) => {
        if (data) {
          const { addDeliveryRule } = data;
          const { success } = addDeliveryRule;
          if (success) {
            setHandleAddButton(true);
            // setNewRowCheckboxValue(initialAddTimeRow)
            // setActiveDistanceButton(false)
            // setHandleAddButton(false)
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "businessClient" },
      refetchQueries: [DEFAULT_ZONE],
    }
  );

  const {
    loading: deliveryRulesListLoading,
    error: deliveryRulesListError,
    data: deliveryRulesListData,
  } = useQuery(DELIVERY_RULES_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      settingId: deliverySettingId && +deliverySettingId,
      distanceOrder: true,
    },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (deliveryRulesListData) {
      const { deliveryRules } = deliveryRulesListData;
      const { edges } = deliveryRules;
      const filteredData =
        edges &&
        edges.length > 0 &&
        edges.map((eachRule: any) => ({
          areaName: eachRule.node.areaName,
          cartAmount: eachRule.node.cartAmount,
          color: eachRule.node.colourCode,
          deliveryRuleId: eachRule.node.deliveryRuleId,
          deliveryTime: JSON.parse(eachRule.node.deliveryTime),
          startTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["from_time"],
          endTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["to_time"],
          distance: eachRule.node.distance,
          extraShippingRate: eachRule.node.extraShippingRate,
          postCode: eachRule.node.postCode,
          postCodesForZone: eachRule.node.postCodesForZone,
          shippingRate: eachRule.node.shippingRate,
          timeTaken: eachRule.node.timeTaken,
          zoneName: eachRule.node.zoneName,
          zoneShape: eachRule.node.zoneShape,
          zoneType: eachRule.node.zoneType,
          isEdited: false,
          isNew: false,
        }));
      const filteredAreaList =
        filteredData &&
        filteredData.filter(
          (eachZone: any) => eachZone.zoneType === "distance"
        );
      setRows(filteredAreaList);
    }
  }, [deliveryRulesListData]);

  const [handleAddButton, setHandleAddButton] = useState<boolean>(false);
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const [activeDistanceButton, setActiveDistanceButton] = useState(false);
  const [checkboxOpen, setCheckboxOpen] = useState<boolean>(false);
  const [handleTime, setHandleTime] = useState<boolean>(false);
  const [deltedId, setDeletedId] = useState<string | null>(null);
  const [rows, setRows] = useState<any[]>([]);
  const initialValues = {
    startTime: null,
    endTime: null,
  };

  const validation = Yup.object({
    startTime: Yup.string().required("Start Time Required"),
    endTime: Yup.string()
      .test(
        "is-greater",
        "End time must be greater than start time",
        function (value) {
          const { startTime } = this.parent; // Access the values of other fields
          if (startTime && value) {
            return startTime <= value;
          }
          return true; // Return true if either field is empty
        }
      )
      .required("End Time Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      handlePopupTimeDoneButton();
    },
  });

  const [alertPopupForLastRuleDelete, setAlertPopupForLastRuleDelete] =
    useState<boolean>(false);
  const handleCloseForLastDeleteRule = () => {
    setAlertPopupForLastRuleDelete(false);
  };

  const handleCheckboxPopupOpen = () => {
    setCheckboxOpen(true);
  };

  const handleCheckboxPopupClose = () => {
    setCheckboxOpen(false);
    setCheckkboxState(false);
  };

  const validationForNewRow = Yup.object({
    distance: Yup.string().required("Required"),
    cartAmount: Yup.string().required("Required"),
    shippingRate: Yup.string().required("Required"),
  });

  const validationForNewRowForEdit = Yup.object({
    distance: Yup.string().required("Required"),
    cartAmount: Yup.string().required("Required"),
    shippingRate: Yup.string().required("Required"),
  });

  const initialNewRow: Newrow = {
    id: new Date(),
    color: "#333333",
    distance: null,
    cartAmount: null,
    shippingRate: null,
    extraShippingRate: null,
    timeTaken: null,
    startTime: null,
    endTime: null,
    isEdited: false,
  };

  const formikForDeliveryRule = useFormik({
    initialValues: initialNewRow,
    validationSchema: validationForNewRow,
    onSubmit: (values) => {
      handleCreateSaveButton();
    },
  });

  const formikForDeliveryRuleForEdit = useFormik({
    initialValues: initialNewRow,
    validationSchema: validationForNewRowForEdit,
    onSubmit: (values) => {
      if (editRowId) {
        handleEditSaveButton();
      }
    },
  });

  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);

  const [checkkboxState, setCheckkboxState] = useState<boolean>(false);

  //time range picker functions

  const [timePickerOpen, setTimePickerOpen] = React.useState(false);
  const handleTimePickerOpen = () => setTimePickerOpen(true);
  const handleTimePickerClose = () => {
    formik.resetForm();
    setTimePickerOpen(false);
  };

  const combineOpenAndPassingData = (id: string) => {
    if (rows.length === 1) {
      setAlertPopupForLastRuleDelete(true);
      setDeletedId(null);
    } else {
      setDeletePopupOpen(true);
      setDeletedId(id);
    }
  };

  const handleSetTimeButton = () => {
    setTimePickerOpen(true);
    formik.setFieldValue("startTime", formikForDeliveryRule.values.startTime);
    formik.setFieldValue("endTime", formikForDeliveryRule.values.endTime);
  };

  const handleCreateSaveButton = () => {
    const variables = {
      deliverySetting: deliverySettingId && +deliverySettingId,
      shippingRate: formikForDeliveryRule.values.shippingRate
        ? +formikForDeliveryRule.values.shippingRate
        : null,
      distance: formikForDeliveryRule.values.distance
        ? +formikForDeliveryRule.values.distance
        : null,
      // deliveryTime: JSON.stringify({
      //   from_time: formikForDeliveryRule.values.startTime,
      //   to_time: formikForDeliveryRule.values.endTime,
      // }),
      deliveryTime:
        formikForDeliveryRule.values.startTime &&
        formikForDeliveryRule.values.endTime
          ? JSON.stringify({
              from_time: formikForDeliveryRule.values.startTime,
              to_time: formikForDeliveryRule.values.endTime,
            })
          : null,
      cartAmount: formikForDeliveryRule.values.cartAmount
        ? +formikForDeliveryRule.values.cartAmount
        : null,
      colourCode: formikForDeliveryRule.values.color,
      extraShippingRate: formikForDeliveryRule.values.extraShippingRate
        ? +formikForDeliveryRule.values.extraShippingRate
        : null,
      timeTaken: formikForDeliveryRule.values.timeTaken
        ? +formikForDeliveryRule.values.timeTaken
        : null,
      zoneType: "distance",
      isDefault: updatedDefaultZone === "distance" ? true : false,
    };

    addingDeliveryRule({ variables: variables });
  };

  const handleEditSaveButton = () => {
    setHandleTime(false);
    const variables = {
      deliveryRule:
        formikForDeliveryRuleForEdit.values.id &&
        +formikForDeliveryRuleForEdit.values.id,
      shippingRate: formikForDeliveryRuleForEdit.values.shippingRate
        ? +formikForDeliveryRuleForEdit.values.shippingRate
        : null,
      distance: formikForDeliveryRuleForEdit.values.distance
        ? +formikForDeliveryRuleForEdit.values.distance
        : null,
      // deliveryTime: JSON.stringify({
      //   from_time: formik.values.startTime,
      //   to_time: formik.values.endTime,
      // }),
      deliveryTime:
        formikForDeliveryRule.values.startTime &&
        formikForDeliveryRule.values.endTime
          ? JSON.stringify({
              from_time: formikForDeliveryRule.values.startTime,
              to_time: formikForDeliveryRule.values.endTime,
            })
          : null,
      cartAmount: formikForDeliveryRuleForEdit.values.cartAmount
        ? +formikForDeliveryRuleForEdit.values.cartAmount
        : null,
      colourCode: formikForDeliveryRuleForEdit.values.color,
      extraShippingRate: formikForDeliveryRuleForEdit.values.extraShippingRate
        ? +formikForDeliveryRuleForEdit.values.extraShippingRate
        : null,
      timeTaken: formikForDeliveryRuleForEdit.values.timeTaken
        ? +formikForDeliveryRuleForEdit.values.timeTaken
        : null,
      zoneType: "distance",
    };

    const filteredVariables = Object.fromEntries(
      Object.entries(variables).filter(([_, value]) => value !== null)
    );

    updateDeliveryRule({ variables: filteredVariables });
  };

  const handleEditIcon = (id: string, row: any, e: any) => {
    e.preventDefault();
    setActiveDistanceButton(false);
    const filteredRows = rows.map((eachRow: any) => {
      if (eachRow.deliveryRuleId === id) {
        return { ...eachRow, isEdited: true };
      } else {
        return { ...eachRow, isEdited: false };
      }
    });
    setRows(filteredRows);
    formik.setFieldValue("startTime", row.startTime);
    formik.setFieldValue("endTime", row.endTime);
    formikForDeliveryRuleForEdit.setFieldValue("id", row.deliveryRuleId);
    formikForDeliveryRuleForEdit.setFieldValue("color", row.color);
    formikForDeliveryRuleForEdit.setFieldValue("distance", row.distance);
    formikForDeliveryRuleForEdit.setFieldValue("cartAmount", row.cartAmount);
    formikForDeliveryRuleForEdit.setFieldValue("startTime", row.startTime);
    formikForDeliveryRuleForEdit.setFieldValue("endTime", row.endTime);
    formikForDeliveryRuleForEdit.setFieldValue(
      "shippingRate",
      row.shippingRate
    );
    formikForDeliveryRuleForEdit.setFieldValue(
      "extraShippingRate",
      row.extraShippingRate
    );
    formikForDeliveryRuleForEdit.setFieldValue("timeTaken", row.timeTaken);
    setHandleAddButton(true);
    setEditRowId(id);
  };

  const handleDontRemoveButton = () => {
    setDeletedId(null);
    setDeletePopupOpen(false);
  };

  const [showCloseIcon, setShowCloseIcon] = React.useState(
    rows.length > 0 ? rows.map((each: any) => false) : []
  );
  const handleMouseEnter = (index: number) => {
    const updatedShowData =
      rows.length > 0 ? rows.map((each: any) => false) : [];
    updatedShowData[index] = true;
    setShowCloseIcon(updatedShowData);
  };

  const handleMouseLeave = (index: number) => {
    const updatedShowData = [...showCloseIcon];
    updatedShowData[index] = false;
    setShowCloseIcon(updatedShowData);
  };

  const combineDeleteRowAndPopupClose = () => {
    deleteDeliveryRule({
      variables: {
        deliveryRule: deltedId && +deltedId,
      },
    });
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const handlePopupTimeDoneButton = () => {
    setHandleTime(true);
    setTimePickerOpen(false);
    if (editRowId) {
      const filtredRow = rows.find((row) => row.deliveryRuleId === editRowId);
      if (filtredRow) {
        const updatedRow = {
          ...filtredRow,
          startTime: formik.values.startTime,
          endTime: formik.values.endTime,
        };
        const indexOfExistingRow = rows.indexOf(filtredRow);
        rows[indexOfExistingRow] = updatedRow;
        setRows(rows);
        setTimePickerOpen(false);
        formikForDeliveryRule.setFieldValue(
          "startTime",
          formik.values.startTime
        );
        formikForDeliveryRule.setFieldValue("endTime", formik.values.endTime);
      }
    } else {
      formikForDeliveryRule.setFieldValue("startTime", formik.values.startTime);
      formikForDeliveryRule.setFieldValue("endTime", formik.values.endTime);
    }
  };

  const handleOnDistanceButton = () => {
    setActiveDistanceButton(true);
    setHandleAddButton(true);
  };

  const handleCloseIconCancelButton = (id: string) => {
    const filteredRows = rows.map((eachRow: any) => {
      if (eachRow.id === id) {
        return { ...eachRow, isEdited: false };
      } else {
        return { ...eachRow, isEdited: false };
      }
    });
    setRows(filteredRows);
    setHandleAddButton(false);
    formik.resetForm();
    formikForDeliveryRule.resetForm();
  };

  const handlePopupClearButton = () => {};

  const handleAddButtonCloseIcon = () => {
    setActiveDistanceButton(false);
    formikForDeliveryRule.resetForm();
    setHandleAddButton(false);
  };
  const hadleCheckboxForDefault = (isChecked: boolean) => {
    if (isChecked) {
      const collectedData = rows.filter(
        (eachZone: any) => eachZone.isNew === false
      );

      if (collectedData.length === 0) {
        toast.error(
          <div>
            <Typography>Add data to set as default</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      } else {
        setCheckboxOpen(true);
      }
    }
    setCheckkboxState(isChecked);
  };

  const handleSaveButton = () => {
    changeDefaultDeliveryRule({
      variables: {
        deliverySetting: deliverySettingId && +deliverySettingId,
        zoneType: "distance",
        isDefault: true,
        locationId: editId && +editId,
      },
    });
    handleCheckboxPopupClose();
  };

  const handleEditTime = (id: string) => {
    const filteredData = rows.filter((each: any) => each.deliveryRuleId === id);
    const time = {
      startTime: filteredData[0].startTime,
      endTime: filteredData[0].endTime,
    };
    formik.setValues(time);
    setTimePickerOpen(true);
  };

  const imperialCountries: string[] = ["US", "LR", "MM", "GB"];

  // Function to get the distance unit based on the country code
  function getDistanceUnit(countryCode: string): string {
    // Check if the country code is in the list of imperial countries
    if (imperialCountries.includes(countryCode.toUpperCase())) {
      return t("buttons.miles"); // Imperial units (miles)
    }

    // Default to metric units (kilometers)
    return t("buttons.km");
  }

  // Example usage
  const countryCode: string | null = sessionStorage.getItem("country");
  const distanceUnit: string = getDistanceUnit(
    countryCode === null ? "" : countryCode
  );

  return (
    <Box sx={{ padding: "15px" }}>
      <AlertPopupForLastRuleDelete
        open={alertPopupForLastRuleDelete}
        handleClose={handleCloseForLastDeleteRule}
      />
      <TimeRangePicker
        formik={formik}
        open={timePickerOpen}
        handleClose={handleTimePickerClose}
      />

      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleDontRemoveButton}
        handleOpen={() => setDeletePopupOpen(true)}
        handleSaveButton={combineDeleteRowAndPopupClose}
        mainText={`${t("buttons.are_you_sure_delete")} ${t("deliveries.rule")}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={updatedDefaultZone === "distance"}
              onChange={(e) => hadleCheckboxForDefault(e.target.checked)}
              color="warning"
              sx={{ "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
            />
          }
          label={t("deliveries.make_this_as_default")}
        />
      </Box>
      <ComfirmationPopup
        accessText={t("deliveries.distance_wise")}
        handleSaveButton={handleSaveButton}
        open={checkboxOpen}
        handleClose={handleCheckboxPopupClose}
        handleOpen={handleCheckboxPopupOpen}
      />
      {deliveryRulesListLoading ? (
        <CircularLoader />
      ) : (
        <>
          <Box
            sx={{ borderRadius: "5px", width: "100%" }}
            className="mapContainer"
            id="map-canvas"
          >
            {/* <LocationIdentifier /> */}
            <RenderGoogleMap
              locationKey={locationKey}
              storeLocation={storeLocation}
              setRows={setRows}
              rows={rows.length > 0 ? rows : []}
            />
          </Box>
          <Box
            sx={{
              marginTop: "1%",
            }}
          >
            <TableContainer
              sx={{
                padding: "2px",
                border: "2px solid #eee",
                minHeight: "20vh",
                maxHeight: "70vh", // You can adjust this height as needed
                overflowY: "auto",
                width: "100%",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              component={Paper}
              elevation={0}
            >
              <form onSubmit={formikForDeliveryRuleForEdit.handleSubmit}>
                <div
                  style={{
                    position: "sticky",
                    padding: "10px",
                    top: 0,
                    backgroundColor: "#f5f5f5",
                    zIndex: 1,
                  }}
                  className="header-wrapper-for-distance-wise"
                >
                  <div className="area-wise-table-column">
                    {t("deliveries.color")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.distance")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.cart_amount")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.shipping_rate")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.extra_shipping_for_less_cart_amt")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.time_taken_for_delivery")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.delivery_time")}
                  </div>
                  <div className="area-wise-table-column"></div>
                </div>
                {rows.length > 0 ? (
                  rows.map((row: any, index: number) => (
                    <div
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      key={row.deliveryRuleId}
                      style={{
                        marginBottom: 2,
                        border: "1px solid rgba(224, 224, 224, 1)",
                        height: "70px",
                        borderRadius: "5px",
                        padding: "15px 5px 15px 5px",
                        marginTop: "5px",
                      }}
                      className="header-wrapper-for-distance-wise"
                    >
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              size="small"
                              color="warning"
                              onChange={(e) => {
                                formikForDeliveryRuleForEdit.handleChange(e);
                              }}
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.color || ""
                              }
                              type="color"
                              sx={{ width: "100%" }}
                              name="color"
                              //@ts-ignore
                              placeholder={t("deliveries.color")}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {row.color}
                          </Typography>
                        )}
                      </div>
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.distance ||
                                ""
                              }
                              size="small"
                              name="distance"
                              //@ts-ignore
                              placeholder={t("deliveries.distance")}
                            />
                            {formikForDeliveryRuleForEdit.touched.distance &&
                              formikForDeliveryRuleForEdit.errors.distance && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {" "}
                            {`${row.distance} ${distanceUnit}`}
                          </Typography>
                        )}
                      </div>
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .cartAmount || ""
                              }
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              name="cartAmount"
                              //@ts-ignore
                              placeholder={t("deliveries.cart_amount")}
                            />
                            {formikForDeliveryRuleForEdit.touched.cartAmount &&
                              formikForDeliveryRuleForEdit.errors
                                .cartAmount && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {currencySymbol}{" "}
                            {row.cartAmount ? row.cartAmount : "0"}
                          </Typography>
                        )}
                      </div>
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .shippingRate || ""
                              }
                              size="small"
                              name="shippingRate"
                              //@ts-ignore
                              placeholder={t("deliveries.shipping_rate")}
                            />
                            {formikForDeliveryRuleForEdit.touched
                              .shippingRate &&
                              formikForDeliveryRuleForEdit.errors
                                .shippingRate && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {currencySymbol}{" "}
                            {row.shippingRate ? row.shippingRate : "0"}
                          </Typography>
                        )}
                      </div>
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              size="small"
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .extraShippingRate || ""
                              }
                              name="extraShippingRate"
                              //@ts-ignore
                              placeholder={t(
                                "deliveries.extra_shipping_for_less_cart_amt"
                              )}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {currencySymbol}{" "}
                            {row.extraShippingRate
                              ? row.extraShippingRate
                              : "0"}
                          </Typography>
                        )}
                      </div>
                      <div>
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.timeTaken ||
                                ""
                              }
                              size="small"
                              name="timeTaken"
                              //@ts-ignore
                              placeholder={t(
                                "deliveries.time_taken_for_delivery"
                              )}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "12px" }}>
                            {row.timeTaken ? row.timeTaken : "0"}
                          </Typography>
                        )}
                      </div>
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {" "}
                        <Button
                          sx={{
                            "&.MuiButton-root": {
                              padding: "0px 8px",
                            },
                          }}
                        >
                          <IconButton
                            onClick={
                              row.isEdited
                                ? () => handleEditTime(row.deliveryRuleId)
                                : undefined
                            }
                          >
                            <AccessTimeIcon color="warning" />
                          </IconButton>
                          {row.startTime && row.endTime ? (
                            <Typography className="distance-wise-popup-time-text">
                              {row.startTime} to {row.endTime}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "#ababab",
                              }}
                            >
                              {t("deliveries.set_time")}
                            </Typography>
                          )}
                        </Button>
                      </div>
                      <div>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "2px",
                          }}
                        >
                          {row.isEdited === true ? (
                            <IconButton
                              type="submit"
                              sx={{ marginRight: "5px" }}
                              className="area-wise-add-done-icone"
                            >
                              <DoneIcon />
                            </IconButton>
                          ) : (
                            showCloseIcon[index] && (
                              <IconButton
                                onClick={(e) =>
                                  handleEditIcon(row.deliveryRuleId, row, e)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            )
                          )}
                          {row.isEdited === true ? (
                            <IconButton
                              type="button"
                              onClick={() =>
                                handleCloseIconCancelButton(row.deliveryRuleId)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            showCloseIcon[index] && (
                              <IconButton
                                type="button"
                                onClick={() =>
                                  combineOpenAndPassingData(row.deliveryRuleId)
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            )
                          )}
                        </Box>
                      </div>
                    </div>
                  ))
                ) : (
                  <NodataImage
                    height="150px"
                    width="150px"
                    heightForContainer="25vh"
                    fontSizeForNoDataText="14px"
                  />
                )}
              </form>
            </TableContainer>

            {activeDistanceButton && (
              <form onSubmit={formikForDeliveryRule.handleSubmit}>
                <div
                  style={{ padding: "8px", marginTop: "8px" }}
                  className="header-wrapper-for-distance-wise"
                >
                  <div>
                    <TextField
                      color="warning"
                      type="color"
                      onChange={(e) => {
                        formikForDeliveryRule.handleChange(e);
                      }}
                      onBlur={formikForDeliveryRule.handleBlur}
                      value={formikForDeliveryRule.values.color}
                      sx={{ width: "100%" }}
                      name="color"
                      //@ts-ignore
                      placeholder={t("deliveries.color")}
                    />
                  </div>
                  <div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        color="warning"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val || /^[0-9]+$/.test(val)) {
                            formikForDeliveryRule.handleChange(e);
                          }
                        }}
                        type="text"
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={formikForDeliveryRule.values.distance || ""}
                        name="distance"
                        //@ts-ignore
                        placeholder={t("deliveries.distance")}
                      />
                      {formikForDeliveryRule.touched.distance &&
                        formikForDeliveryRule.errors.distance && (
                          <Box>
                            <Typography
                              sx={{ marginBottom: "5px", textAlign: "start" }}
                              className="create-a-user-formik-label"
                            >
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </div>
                  <div>
                    {" "}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        color="warning"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val || /^[0-9]+$/.test(val)) {
                            formikForDeliveryRule.handleChange(e);
                          }
                        }}
                        type="text"
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={formikForDeliveryRule.values.cartAmount || ""}
                        name="cartAmount"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        //@ts-ignore
                        placeholder={t("deliveries.cart_amount")}
                      />
                      {formikForDeliveryRule.touched.cartAmount &&
                        formikForDeliveryRule.errors.cartAmount && (
                          <Box>
                            <Typography
                              sx={{ textAlign: "start" }}
                              className="create-a-user-formik-label"
                            >
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </div>
                  <div>
                    {" "}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <TextField
                        color="warning"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val || /^[0-9]+$/.test(val)) {
                            formikForDeliveryRule.handleChange(e);
                          }
                        }}
                        type="text"
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={formikForDeliveryRule.values.shippingRate || ""}
                        name="shippingRate"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        //@ts-ignore
                        placeholder={t("deliveries.shipping_rate")}
                      />
                      {formikForDeliveryRule.touched.shippingRate &&
                        formikForDeliveryRule.errors.shippingRate && (
                          <Box>
                            <Typography
                              sx={{ marginBottom: "5px", textAlign: "start" }}
                              className="create-a-user-formik-label"
                            >
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </div>
                  <div>
                    <TextField
                      color="warning"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!val || /^[0-9]+$/.test(val)) {
                          formikForDeliveryRule.handleChange(e);
                        }
                      }}
                      type="text"
                      onBlur={formikForDeliveryRule.handleBlur}
                      value={
                        formikForDeliveryRule.values.extraShippingRate || ""
                      }
                      name="extraShippingRate"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {currencySymbol}
                          </InputAdornment>
                        ),
                      }}
                      //@ts-ignore
                      placeholder={t(
                        "deliveries.extra_shipping_for_less_cart_amt"
                      )}
                    />
                  </div>
                  <div>
                    {" "}
                    <TextField
                      color="warning"
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!val || /^[0-9]+$/.test(val)) {
                          formikForDeliveryRule.handleChange(e);
                        }
                      }}
                      type="text"
                      onBlur={formikForDeliveryRule.handleBlur}
                      value={formikForDeliveryRule.values.timeTaken || ""}
                      name="timeTaken"
                      //@ts-ignore
                      placeholder={t("deliveries.time_taken_for_delivery")}
                    />
                  </div>
                  <div>
                    <Button>
                      <IconButton
                        onClick={handleSetTimeButton}
                        color="warning"
                        className="area-wise-table-clock-button"
                      >
                        <AccessTimeIcon sx={{ marginRight: "10px" }} />
                        {formikForDeliveryRule.values.startTime &&
                        formikForDeliveryRule.values.endTime ? (
                          <Typography>
                            {formikForDeliveryRule.values.startTime} :{" "}
                            {formikForDeliveryRule.values.endTime}
                          </Typography>
                        ) : (
                          t("deliveries.set_time")
                        )}
                      </IconButton>
                    </Button>
                  </div>
                  <div>
                    {" "}
                    <Box>
                      <IconButton
                        type="submit"
                        className="area-wise-add-done-icone"
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton onClick={handleAddButtonCloseIcon}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </div>
                </div>
              </form>
            )}

            <Button
              disabled={handleAddButton}
              onClick={handleOnDistanceButton}
              className={
                handleAddButton
                  ? "area-wise-add-distance-button-inactive"
                  : "area-wise-add-distance-button-active"
              }
            >
              {t("deliveries.add_distance")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default DistanceWise;
