import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { resetState, setName, setPricing } from "../../store";

interface ProtectedRouteProps {
  children: any;
}

const Protected: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlSearchString = window.location.pathname;
  const urlList = urlSearchString.split("/");
  if (urlSearchString !== "/bulk/product") {
    sessionStorage.removeItem("editBulkProductIds");
  }
  const urlSearchString2 = window.location.search;
  const params = new URLSearchParams(urlSearchString2);
  const create = params.get("create");
  const edit = params.get("edit");

  if (urlSearchString === "/assign/options/to/product") {
    if (edit !== null && edit !== "null" && edit !== undefined && edit) {
      dispatch(setName("productIdByEdit", edit));
      dispatch(setName("tabState", 2));
    } else {
      dispatch(setName("tabState", 0));
    }
  }
  if (
    urlSearchString.split("/")[1] !== "product" &&
    urlSearchString !== "/assign/options/to/product/"
  ) {
    dispatch(resetState());
  }

  // if (urlSearchString === "/product/create-product") {
  //   dispatch(setPricing("productId", []));
  //   dispatch(resetState());
  // }

  // if (urlList.length > 3 && urlList[3] === "update-product") {

  //   dispatch(setPricing("productId", []));
  //   dispatch(resetState());
  // }

  const accessToken = sessionStorage.getItem("token");
  const isAuthenticated = accessToken !== null;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/sign-in");
    }
  }, [isAuthenticated, navigate]);

  return <Box>{children}</Box>;
};

export default Protected;

// // ProtectedRoute.tsx
// import React from 'react';
// import { Route,useNavigate } from 'react-router-dom';

// interface ProtectedRouteProps {
//   // Add any necessary props
//   path: string;
//   element: React.ReactNode; // Assuming you're using React Router v6
// }

// const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ path, element }) => {

//   const navigate=useNavigate()

//     const accessToken=sessionStorage.getItem("token")

//        const isAuthenticated = accessToken !== null;

//        return isAuthenticated ? <Route path={path} element={element} /> : navigate('/sign-in');
// };

// export default ProtectedRoute;

// ProtectedRoute.tsx
// ProtectedRoute.tsx
// import React from 'react';
// import { Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
// import { Box } from '@mui/material';

// interface ProtectedRouteProps {
//   children: any
// }

// const Protected: React.FC<ProtectedRouteProps> = ({ children }) => {
//   const navigate=useNavigate()
//   const accessToken = sessionStorage.getItem('token');
//   const isAuthenticated = accessToken !== null;
//   console.log(isAuthenticated)
//   if (!isAuthenticated) {
//     navigate("/sign-in")

//   }

//   return (
//     <Box>
//       {children}
//     </Box>)

// };

// export default Protected;

{
  /* <Route
path="/dash-board"
element={<Protected><Dashboard/></Protected>}
/> */
}
