import React, { useEffect, useState } from "react";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from "@mui/x-data-grid";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import StatusModal from "./StatusModal";
import EditIcon from "@mui/icons-material/Edit";
import { getStatusColor } from "../orderUtils/helperFunctions";
import { useNavigate } from "react-router-dom";

import CircularLoader from "../../loader/loader";
import CurrencyDisplay from "../utils/currencySymbol";
import moment from "moment";
import { localeToFormat } from "../../utils/dateFormat";
import DataGridTable from "../../utils/dataGridTable";
import { useTranslation } from "react-i18next";

const OrdersTable = (props: any) => {
  const { t } = useTranslation();

  const [stepsForCollectionForDropDown, setStepsForCollectionForDropDown] =
    useState<any[]>([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  const [stepsForDeliveryForDropDown, setStepsForDeliveryForDropDown] =
    useState<any[]>([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Dispatched",
        text: t("orders.dispatched"),
      },
      {
        value: "OnTheWay",
        text: t("orders.on_the_way"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  useEffect(() => {
    setStepsForDeliveryForDropDown([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Dispatched",
        text: t("orders.dispatched"),
      },
      {
        value: "OnTheWay",
        text: t("orders.on_the_way"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
    setStepsForCollectionForDropDown([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  }, [t]);

  const {
    orderList,
    handleSavePress,
    setChangeId,
    ordersData,
    handleUpdatingValuesToNull,
    shouldUpdatePaginationModelIsTrue,
    formik,
    handleEndCursor,
    open,
    handleStartCursor,
    currentPage,
    paginationModel,
    setOpen,
    loader,
  }: any = props;
  const navigate = useNavigate();
  const userLang = navigator.language;
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const [orderType, setOrderType] = useState<string>("");
  const [statusForRendering, setStatusForRendering] = useState("");
  const [activeStepsForDropdown, setActiveStepsForDropdown] = useState<any[]>(
    []
  );

  // structure of row data
  interface RowData extends GridRowModel {
    customer: string;
    type: string;
    pay_type: String;
    amount: number | null;
    status: string; // Status field added
    date: string;
  }

  const disableList = ["Cancelled", "Void", "Rejected"];
  React.useEffect(() => {
    if (orderType === "delivery") {
      if (formik.values.status === "Completed") {
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveStepsForDropdown(stepsForDeliveryForDropDown);
      }
    } else if (orderType === "collection") {
      if (formik.values.status === "Completed") {
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    } else if (orderType === "dine_in") {
      if (formik.values.status === "Completed") {
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    } else if (orderType === "shop") {
      if (formik.values.status === "Completed") {
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    }
  }, [
    statusForRendering,
    orderType,
    stepsForDeliveryForDropDown,
    stepsForCollectionForDropDown,
  ]);

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  // Define the columns
  const columns: GridColDef[] = [
    {
      field: "id",
      headerClassName: "user-role-header-names",
      headerName: t("orders.order_no") || "",
      flex: 2,
      disableColumnMenu: true,
    },
    {
      field: "customerDetails",
      headerClassName: "user-role-header-names",

      headerName: t("orders.customer") || "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        const data = JSON.parse(params?.value);
        return (
          <Typography sx={{ textTransform: "capitalize" }}>
            {data?.customer_name ? data.customer_name : t("orders.guest")}
          </Typography>
        );
      },
    },
    {
      field: "orderType",
      headerClassName: "user-role-header-names",

      headerName: t("orders.type") || "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const data = params?.value;
        function toPascalCase(str: any) {
          return str
            .split("_")
            .map(
              (word: any) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
            )
            .join(" ");
        }

        function capitalizeFirstLetter(str: any) {
          return str.charAt(0).toUpperCase() + str.slice(1);
        }

        let formattedType;
        if (data?.includes("_")) {
          // If the orderType contains '_', it's snake_case
          formattedType = toPascalCase(data);
        } else {
          // Otherwise, it's already in PascalCase or camelCase
          formattedType = capitalizeFirstLetter(data);
        }

        return (
          <Typography sx={{ textTransform: "capitalize" }}>
            {formattedType}
          </Typography>
        );
      },
    },
    {
      field: "paymentStatus",
      headerClassName: "user-role-header-names",

      headerName: t("orders.pay_type") || "",
      flex: 1,

      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params?.value}
        </Typography>
      ),
    },
    {
      field: "totalAmount",
      headerClassName: "user-role-header-names",
      headerName: t("orders.amount") || "",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        // <CurrencyDisplay countryName={"UnitedKingdom"} amount={params.value} />
        <Typography>
          {currencySymbol} {params.value}
        </Typography>
      ),
    },
    {
      field: "orderStatus",
      headerName: t("orders.status") || "",
      headerClassName: "user-role-header-names",

      flex: 1,

      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) => (
        <Button
          disabled={disableList.includes(params.row.orderStatus)}
          variant="outlined"
          endIcon={params.value === "In Progress" ? <EditIcon /> : ""}
          sx={{
            width: "120px",
            borderRadius: "4px",
            borderColor: getStatusColor(params.value, "border-color"),
            color: getStatusColor(params.value, "text-color"),
            textTransform: "capitalize",
            fontSize: "14px",
            whiteSpace: "nowrap", // Prevent text wrapping
            overflow: "hidden", // Hide overflow
            textOverflow: "ellipsis",
            "&:hover": {
              borderColor: getStatusColor(params.value, "border-color"),
              backgroundColor: "rgba(255, 165, 0, 0.04)", // Light orange background on hover
            },
            background: getStatusColor(params.value, "background"),
            height: 30,
            fontWeight: "400",
          }}
          onClick={() => handleOpen(params)}
        >
          {params.value}
        </Button>
      ),
    },
    {
      field: "createdOn",
      headerClassName: "user-role-header-names",

      headerName: t("orders.booking_date") || "",
      flex: 1,

      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>
            {moment(params.value).format(`${localeToFormat[userLang]}`)}
          </Typography>
        );
      },
    },
    {
      field: "requestedTime",
      headerClassName: "user-role-header-names",
      headerName: t("orders.requested_time") || "",
      flex: 1.5,
      sortable: false,
      disableColumnMenu: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Typography>
            {params.value
              ? moment(params.value).format(
                  `${localeToFormat[userLang]}, hh:mm A`
                )
              : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "view",
      headerName: t("orders.view") || "",
      headerClassName: "user-role-header-names",
      width: 60,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Tooltip title={t("orders.view_summary")} placement="top" arrow>
            <div
              style={{
                paddingRight: "20px",
                opacity: 0.5,
              }}
              onClick={() =>
                navigate(`/orders/${params.row.orderId}/order-summary`, {
                  state: params.row,
                })
              }
            >
              <VisibilityIcon />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  const reasonsListForHidingTextfield = ["Cancelled"];

  const handleOpen = (data: any) => {
    setOpen(true);
    setChangeId(data?.row?.orderId);
    setOrderType(data?.row?.orderType);
    const status = data?.row?.orderStatus;
    formik.setFieldValue("status", status);
    setStatusForRendering(status);
  };

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  console.log(orderList, "orderList");

  const handleStatusChange = (event: any) => {
    if (reasonsListForHidingTextfield.includes(event.target.value)) {
      formik.setFieldValue("status", event.target.value);
    } else {
      formik.resetForm();
      formik.setFieldValue("status", event.target.value);
    }
  };

  return (
    <div style={{ height: "70vh" }}>
      {loader ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            padding: "10px",
            height: "70vh",
            width: "100%",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={orderList}
            columns={columns}
            getRow={getRow}
            totalCount={ordersData?.orders?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
      <StatusModal
        activeSteps={activeStepsForDropdown}
        setActiveStepsForDropdown={setActiveStepsForDropdown}
        open={open}
        formik={formik}
        handleOpen={handleOpen}
        handleClose={handleClose}
        handleStatusChange={handleStatusChange}
        handleSavePress={handleSavePress}
      />
    </div>
  );
};

export default OrdersTable;
