import { TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

interface State {
  label: string;
  handleChange: (value: string) => void;
  handleBlur: () => void;
  name: string;
  width?: string;
  type?: string;
  formik: any;
  debounceVariable: string;
  value: string | null;
  mobile?: boolean;
}

const TextFieldForDebounce = (props: State) => {
  const {
    label,
    value,
    handleChange,
    handleBlur,
    width,
    type,
    name,
    formik,
    debounceVariable,
    mobile,
  } = props;

  const [value2] = useDebounce(formik.values[debounceVariable], 1000);

  useEffect(() => {
    handleChange(value2);
  }, [value2]);

  const handleChangeForUpdating = (text: string) => {
    if (mobile) {
      if (!text || (/^[0-9]+$/.test(text) && text.length < 12)) {
        formik.setFieldValue(debounceVariable, text);
      }
    } else {
      formik.setFieldValue(debounceVariable, text);
    }
  };

  return (
    <TextField
      onChange={(e) => {
        handleChangeForUpdating(e.target.value);
      }}
      onBlur={handleBlur}
      name={name}
      type={type}
      value={value || ""}
      color="warning"
      sx={{ width: width }}
      label={<Typography>{label}</Typography>}
      variant="outlined"
    />
  );
};

export default TextFieldForDebounce;
