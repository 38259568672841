//reciept listing function component
import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { RECIEPT_LIST_QUERY } from "../../graphicalQl/usequery/recieptQuery";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import image from "../../assets/prouctAddImage.png";
import Drawer from "@mui/material/Drawer";
import { MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router";
import RecieptNameModal from "./recieptNameModal";
import { styled } from "@mui/system";
import { DELETE_RECIEPT_MUTATION } from "../../graphicalQl/mutation/recieptMutation";
import DeleteDailouge from "../category/deleteDailouge";
import { DUPLICATE_RECIEPT_MUTATION } from "../../graphicalQl/mutation/recieptMutation";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import { localeToFormat } from "../../utils/dateFormat";
import NodataImage from "../../utils/nodataImage";
import CircularLoader from "../../loader/loader";
import { useTranslation } from "react-i18next";

interface Reciept {
  name: string;
  id: number;
  reciept: any;
  recieptData: any;
  recieptType: string;
  createdOn: string;
  status: string;
}
const RecieptListing = () => {
  const { t } = useTranslation();
  const [reciepts, setReciepts] = useState<Reciept[]>([]);

  const businessId: string = sessionStorage.getItem("businessId") as string;

  //fetch reciepts using RECIEPT_LIST_QUERY
  const { data, loading } = useQuery(RECIEPT_LIST_QUERY, {
    variables: { businessId: +businessId },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  const [deleteBundle] = useMutation(DELETE_RECIEPT_MUTATION, {
    context: { clientName: "businessClient" },
    refetchQueries: [RECIEPT_LIST_QUERY],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [duplicateReciept] = useMutation(DUPLICATE_RECIEPT_MUTATION, {
    context: { clientName: "businessClient" },
    refetchQueries: [RECIEPT_LIST_QUERY],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [totalList, setToatalList] = useState([
    {
      id: 1,
      value: "Sub Total",
      isChecked: true,
      amount: 70,
      variableName: "sub_total",
    },
    {
      id: 2,
      value: "Tax",
      isChecked: true,
      amount: 10,
      variableName: "tax_breakup",
    },
    {
      id: 3,
      value: "Discount",
      isChecked: true,
      amount: 5,
      variableName: "discount_amount",
    },
    { id: 4, value: "Tip", isChecked: true, amount: 5, variableName: "tip" },

    // { id: 4, value: "Charges", isChecked: true, amount: 5, variableName: "charges" },
    {
      id: 5,
      value: "Delivery Fee",
      isChecked: true,
      amount: 5,
      variableName: "delivery_fee",
    },
    // { id: 6, value: "Total", isChecked: true, amount: 80, variableName: "grand_total" }
  ]);

  useEffect(() => {
    if (data && data.receiptList && data.receiptList.edges) {
      const recieptList = data.receiptList.edges.map((reciept: any) => ({
        name: reciept.node.receiptName,
        id: reciept.node.receiptId,
        reciept: reciept.node.receipt,
        recieptType: reciept.node.receiptType,
        recieptData: JSON.parse(reciept.node.receiptData),
        createdOn: reciept.node.createdOn,
        status: reciept.node.receiptStatus,
      }));
      setReciepts(recieptList);
    }
  }, [data]); // include orderDetailsHeader in the dependency array
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const [selectedList, setSelectedList] = useState<any>([]);
  const currencySybol = sessionStorage.getItem("currencySymbol");

  const drawerList = (
    <Box sx={{ width: "300px", padding: "12px" }}>
      {selectedList &&
        selectedList.map((item: any, index: number) => (
          <Box
            sx={{
              width: "100%",
              textAlign: item.align,
              margin: "4px 0 4px 0",
            }}
          >
            {item.name === "image" ? (
              <img src={image} alt="restaurant" style={{ width: "20%" }} />
            ) : item.name === "date" ? (
              <Typography style={{ ...item.style }}>
                {" "}
                Date:{item.value}
              </Typography>
            ) : item.name === "solidLine" ? (
              <Box sx={{ padding: "4px", display: "-moz-initial" }}>
                {" "}
                <Divider
                  sx={{ borderTop: "1px solid", position: "relative" }}
                />
                {/* {deleteButton === item.id && <IconButton sx={{ position: 'absolute', right: "0" }} onClick={() => handleDelteLine(item.id)}><DeleteIcon /></IconButton>} */}
              </Box>
            ) : item.name === "dottedLine" ? (
              <Box sx={{ padding: "4px", display: "-moz-initial" }}>
                {" "}
                <Divider
                  sx={{ borderTop: "1px dotted", position: "relative" }}
                />
                {/* {deleteButton === item.id && <IconButton sx={{ position: 'absolute', right: "0" }} onClick={() => handleDelteLine(item.id)}><DeleteIcon /></IconButton>}</Box> : item.name === "empty" ? <Box sx={{ padding: "4px", display: "-moz-initial" }} onMouseEnter={() => { handleMouseEnter(item.id) }} onMouseLeave={() => { handleMouseLeave() }}> */}
              </Box>
            ) : item.name === "empty" ? (
              <Box sx={{ padding: "4px", display: "-moz-initial" }}></Box>
            ) : item.name === "items" ? (
              <Box>
                {item.value &&
                  item.value.map((each: any, index: number) => {
                    return (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          style={{ ...item.style }}
                        >{`${each.qty}  *  ${each.name}`}</Typography>
                        {/* <Typography style={{ ...basicStyle.find((style: StyleList) => style.id === item.id), flex: 3 }}></Typography> */}
                        <Typography style={{ ...item.style }}>
                          {currencySybol} {each.price}
                        </Typography>
                      </Box>
                    );
                  })}
              </Box>
            ) : item.name === "total" ? (
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {totalList
                  .filter((item) => item.isChecked === true)
                  .map((each: any) => {
                    return (
                      <Typography
                        style={{ ...item.style }}
                      >{`${each.value}   ${currencySybol}${each.amount}`}</Typography>
                    );
                  })}
              </Box>
            ) : item.name === "header" ? (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ ...item.style }}
                >{`Qty   Name`}</Typography>
                <Typography style={{ ...item.style }}>Price</Typography>
              </Box>
            ) : (
              <Box>
                {item.label !== null ? (
                  <Typography
                    style={{
                      ...item.style,
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.label}: {item.value}
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      ...item.style,
                      overflow: "hidden",
                      width: "100%",
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    }}
                  >
                    {item.value}
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        ))}
    </Box>
  );

  const handleGetRecieptData = (id: any) => {
    const selectedReciept = reciepts.find((reciept) => reciept.id === id);
    setSelectedList(selectedReciept?.recieptData);
  };
  const handleButtonClick = (id: string) => {
    setOpen(true);
    handleGetRecieptData(id);
  };
  const navigate = useNavigate();

  const handleEditNavigate = (id: string) => {
    navigate(`/reciept/${id}/update-reciept-builder`);
  };
  const [editId, setEditId] = useState<string | null>(null);

  function CustomPopoverCell({ bundleId }: { bundleId: string }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const popoverContent = (
      <List style={{ padding: "0px 0px 0px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleEditNavigate(bundleId);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="bundle-Listing-pover-text-style"
          >
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  Edit
                </Typography>
              }
            />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleDeleteModal();
            getCategoryId(bundleId);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  Delete
                </Typography>
              }
            />
          </ListItem>
        </Button>
        <Divider />

        <Button
          style={{
            padding: "0px 0px 0px 0px",
            width: "100%",
            textTransform: "none",
          }}
          onClick={() =>
            duplicateReciept({ variables: { receiptId: +bundleId } })
          }
        >
          <ListItem className="bundle-Listing-pover-text-style">
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  Duplicate
                </Typography>
              }
            />
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }
  const userLang = navigator.language;

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Reciept Name",
      headerClassName: "table-column-header ",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: "recieptType",
      headerClassName: "table-column-header ",
      headerName: "Reciept Type",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "table-column-header ",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params.value === "NOTINUSE" ? (
          <Button
            onClick={() => {
              setEditId(params.row.id);
              setModalOpen(true);
            }}
            className="reciept-edit-button2"
          >
            Not In Use
          </Button>
        ) : (
          <Button
            onClick={() => {
              setEditId(params.row.id);
              setModalOpen(true);
            }}
            className="reciept-edit-button2"
          >
            In Use
          </Button>
        );
      },
    },
    {
      field: "createdOn",
      headerName: "Created On",
      headerClassName: "table-column-header ",
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        // Convert the string to a Date object
        const dateObject = new Date(params.value);

        // Check if the conversion was successful and dateObject is a valid Date
        if (!isNaN(dateObject.getTime())) {
          // Format the Date object to "YYYY-MM-DD HH:mm"
          const formattedDate = `${dateObject.getFullYear()}-${String(
            dateObject.getMonth() + 1
          ).padStart(2, "0")}-${String(dateObject.getDate()).padStart(
            2,
            "0"
          )} ${String(dateObject.getHours()).padStart(2, "0")}:${String(
            dateObject.getMinutes()
          ).padStart(2, "0")}`;
          return moment(params.value).format(`${localeToFormat[userLang]}`);
        } else {
          // Handle the case where the conversion failed
          return "Invalid Date";
        }
      },
    },

    {
      field: "c",
      headerName: "Actions",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
      headerAlign: "center",
      flex: 1,
      filterable: false,
      align: "center",
      hideSortIcons: true,

      renderCell: (params) => {
        return (
          // <CustomPopoverCell2 value={params.row.recieptData}
          //     id={params.row.id}
          // />
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => handleButtonClick(params.row.id)}>
              <VisibilityIcon />
            </IconButton>
            <CustomPopoverCell bundleId={params.row.id} />
          </Box>
        );
      },
    },
  ];
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [modalOpen, setModalOpen] = useState(false);
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell-less-wt";
  };

  const [deletemodal, setDeleteModal] = useState(false);

  // Toggle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const [categoryIdValue, setCategoryIdValue] = useState("");
  const getCategoryId = (id: string) => {
    setCategoryIdValue(id);
  };
  const handleDelete = (id: string) => {
    deleteBundle({ variables: { receiptIds: JSON.stringify([+id]) } });
    setCategoryIdValue("");
  };

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const filterRows = reciepts.filter((row) =>
      rowSelectionModel.includes(row.id)
    );
    const menuIds = filterRows.map((each) => +each.id);
    deleteBundle({ variables: { receiptIds: JSON.stringify(menuIds) } });
    setRowSelectionModel([]);
  };

  const handleClose = () => {
    setModalOpen(false);
    setEditId(null);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px 20px 20px 32px",
          alignItems: "center",
        }}
      >
        <Typography style={{}} className="reciept-list-head">
          Receipt Templates
        </Typography>
        <Box
          sx={{ display: "flex", justifyContent: "space-evenly", gap: "12px" }}
        >
          <Button onClick={() => setModalOpen(true)} className="main-button">
            Add New
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button className="main-button" onClick={handleDeleteModal} sx={{}}>
              Delete
            </Button>
          )}
        </Box>
      </Box>
      {/* <CustomStyles>
                <Box sx={{
                    width: '100%', overflowY: "scroll", WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
                        display: 'none',
                    }
                }}> */}
      <Box sx={{ height: "70vh", width: "97%", marginLeft: "1.25rem" }}>
        {loading ? (
          <CircularLoader />
        ) : (
          <DataGrid
            rows={reciepts}
            columns={columns}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            columnHeaderHeight={48}
            rowHeight={54}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[5, 15, 25]}
            checkboxSelection
            disableRowSelectionOnClick
            components={{
              NoRowsOverlay: () => (
                <NodataImage
                  height="250px"
                  width="300px"
                  heightForContainer="50vh"
                />
              ),
            }}
            onRowSelectionModelChange={(
              newRowSelectionModel: GridRowSelectionModel
            ) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#f5f5f5",
              },
            }}
          />
        )}
      </Box>
      {/* </Box>

            </CustomStyles> */}

      <Drawer open={open} onClose={toggleDrawer(false)} anchor="right">
        {drawerList}
      </Drawer>

      <RecieptNameModal
        modalOpen={modalOpen}
        handleClose={handleClose}
        editId={editId}
      />
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDelete}
        id={categoryIdValue}
        handleDeleteClick={handleDeleteClick}
        type="reciept"
      />
    </div>
  );
};

export default RecieptListing;
