//basic component structure

import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router";
import "./digital.css";
import DarkTheme from "../../assets/menuQr/darkTheme.svg";
import LightTheme from "../../assets/menuQr/lightTheme.svg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import QRCode from "qrcode.react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { GET_ONLINE_ORDERING_SETTINGS } from "../../graphicalQl/mutation/onlineOrdering";
import { useQuery } from "@apollo/client";
import { Card, CardHeader, CardContent, Grid, Container } from "@mui/material";
import QrCodePopup from "./qrCodePopup";
import ImportPopup from "./importPopup";
import { useSelector } from "react-redux";

interface QRCode {
  tableNo: number;
  qrCodeUrl: string;
}

interface QRCodeGalleryProps {
  qrCodes: QRCode[];
  url: string;
  handleClickToUpadteTableNumber: (tableNo: number) => void;
  locationId: string;
}

interface State {
  tableNo: number;
  handleClickToUpadteTableNumber: (tableNo: number) => void;
  url: string;
  locationId: string;
}

const QRCodeCard: React.FC<State> = ({
  tableNo,
  handleClickToUpadteTableNumber,
  url,
  locationId,
}) => (
  <Card
    onClick={() => {
      handleClickToUpadteTableNumber(tableNo);
    }}
    sx={{
      width: "260px",
      height: "260px",
      cursor: "pointer",
      transition: "background-color 0.3s",
      "&:hover": {
        // backgroundColor: "var(--prime-orange-100, #F38B08)",
        border: "1px solid #F38B08",
      },
    }}
  >
    <CardHeader
      sx={{ padding: 0 }}
      title={
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: "10px",
          }}
          align="center"
        >
          Table {tableNo}
        </Typography>
      }
    />
    <CardContent sx={{ padding: 1, textAlign: "center" }}>
      {/* <img
        src={qrimagemenu}
        alt={`QR Code for Table ${tableNo}`}
        style={{ width: "100%", height: "auto" }}
      /> */}
      <QRCode
        value={`${url}table=${tableNo}&location=${locationId}`}
        size={200}
        level={"H"}
        renderAs="canvas"
        includeMargin={true}
      />
    </CardContent>
  </Card>
);

const QRCodeGallery: React.FC<QRCodeGalleryProps> = ({
  qrCodes,
  handleClickToUpadteTableNumber,
  url,
  locationId,
}) => {
  return (
    <Container sx={{ paddingY: 2 }}>
      <Box
        sx={{
          backgroundColor: "#FFF3CD",
          width: "100%",
          padding: "15px",
          textAlign: "center",
          marginBottom: "40px",
          color: "#F38B08",
          borderRadius: "6px",
        }}
      >
        This section displays the unique QR codes for each table in your
        restaurant. These QR codes link to your digital menu system. You can
        print these QR codes to place on their respective tables. Receive orders
        directly to your Kitchen Display System (KDS) through QR Menu ordering,
        enhancing customer experience with speedy service.
      </Box>
      <Grid container spacing={4}>
        {qrCodes.map((qrCode) => (
          <Grid item xs={12} sm={4} md={3} lg={2} xl={3} key={qrCode.tableNo}>
            <QRCodeCard
              locationId={locationId}
              url={url}
              handleClickToUpadteTableNumber={handleClickToUpadteTableNumber}
              {...qrCode}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

const DigitalMenu = () => {
  const [popupForImport, setPopupForImport] = useState<boolean>(false);
  const { id } = useParams();
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const location = useLocation();
  const componentRef = React.useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const [tableNumber, setTableNumber] = useState<number | null>(null);
  const [tablePopup, setTablePopup] = useState<boolean>(false);

  const queryParams = new URLSearchParams(location.search);
  const handleClickToUpadteTableNumber = (tableNo: number) => {
    setTableNumber(tableNo);
    setTablePopup(true);
  };

  const businessId = sessionStorage.getItem("businessId") as string;

  const { data: settingsData } = useQuery(GET_ONLINE_ORDERING_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: {
      businessId: parseInt(businessId),
    },
  });
  const [domainName, setDomainName] = useState<string | null>(null);
  const [subDomainName, setSubDomainName] = useState<string | null>(null);
  const [url, setUrl] = useState("");

  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [file, setFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [failedMsg, setFailedMsg] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string>("");
  const [downlaodFileName, setDownlaodFileName] = useState<string>("");
  const handleCloseForImportPopup = () => {
    setPopupForImport(false);
    setDownloadUrl(null);
    setFile(null);
    setUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
    setFailedMsg(false);
    setUploadMessage("");
  };

  useEffect(() => {
    if (settingsData && settingsData.business) {
      setDomainName(settingsData.business.domain);
      setSubDomainName(settingsData.business.subDomain);
      if (settingsData.business.domain) {
        if (id !== undefined) {
          setUrl(
            `https://${settingsData.business.domain}/#/qrmenu/?menu=${id}&`
          );
        } else {
          setUrl(`https://${settingsData.business.domain}/#/qrmenu/?`);
        }
      } else {
        if (id !== undefined) {
          setUrl(
            `https://${settingsData.business.subDomain}.smartserve.solutions/#/qrmenu/?menu=${id}&`
          );
        } else {
          setUrl(
            `https://${settingsData.business.subDomain}.smartserve.solutions/#/qrmenu/?`
          );
        }
      }
    }
  }, [settingsData]);

  const name = queryParams.get("name");

  const [list, setList] = useState<
    {
      id: number;
      name: string;
      isChecked: boolean;
      image: string;
    }[]
  >([]);

  useEffect(() => {
    const list = [
      {
        id: 1,
        name: t("menuQr.dark_theme"),
        isChecked: false,
        image: DarkTheme,
      },
      {
        id: 2,
        name: t("menuQr.light_theme"),
        isChecked: true,
        image: LightTheme,
      },
    ];
    setList(list);
  }, [t]);

  const [selectedId, setSelectedId] = useState(2);
  const handleCheckBox = (id: number) => {
    setList(
      list.map((each) =>
        each.id === id
          ? { ...each, isChecked: true }
          : { ...each, isChecked: false }
      )
    );
    setSelectedId(id);
  };

  const downloadPdfDocument = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current, {
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const navigate = useNavigate();

  const qrCodes: QRCode[] = Array.from({ length: 24 }, (_, i) => ({
    tableNo: i + 1,
    qrCodeUrl: `/api/placeholder/150/150`,
  }));

  const handleCloseTablePopup = () => {
    setTablePopup(false);
    setTableNumber(null);
  };

  const handleImportButton = () => {
    setPopupForImport(true);
  };

  return (
    <>
      <ImportPopup
        setDownlaodFileName={setDownlaodFileName}
        downlaodFileName={downlaodFileName}
        setFile={setFile}
        setUploading={setUploading}
        setUploadProgress={setUploadProgress}
        setUploadSuccess={setUploadSuccess}
        setUploadMessage={setUploadMessage}
        setFailedMsg={setFailedMsg}
        downloadUrl={downloadUrl}
        file={file}
        uploadProgress={uploadProgress}
        uploading={uploading}
        uploadSuccess={uploadSuccess}
        failedMsg={failedMsg}
        uploadMessage={uploadMessage}
        setDownloadUrl={setDownloadUrl}
        menuId={id}
        locationId={locationId}
        domainName={domainName}
        subDomainName={subDomainName}
        handleCloseForImportPopup={handleCloseForImportPopup}
        openForImportPopup={popupForImport}
      />
      <div style={{ padding: "12px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              className="exit-container"
              onClick={() => {
                navigate("/menu/menu-list");
              }}
            >
              <CloseIcon className="exitIcon" />
            </IconButton>
            <Typography className="digital-header">
              {/* {t("menuQr.generate_digital_menu")}
               */}
              Table QR Code Management
            </Typography>
            <Button
              onClick={handleImportButton}
              variant="contained"
              className="import-button"
            >
              Import
            </Button>
          </Box>
        </Box>
        {/* <Divider sx={{ marginTop: "12px" }} /> */}
      </div>
      <Box></Box>
      <QrCodePopup
        locationId={locationId}
        id={id}
        url={url}
        selectedId={selectedId}
        handleCloseTablePopup={handleCloseTablePopup}
        open={tablePopup}
        downloadPdfDocument={downloadPdfDocument}
        handlePrint={handlePrint}
        handleCheckBox={handleCheckBox}
        tableNumber={tableNumber}
      />

      <QRCodeGallery
        handleClickToUpadteTableNumber={handleClickToUpadteTableNumber}
        qrCodes={qrCodes}
        url={url}
        locationId={locationId}
      />
    </>
  );
};

export default DigitalMenu;
