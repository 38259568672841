import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./product.css";
import { KDS_LIST } from "../../graphicalQl/usequery/productsListQuery";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable";
import uuid from "react-uuid";
import { useQuery } from "@apollo/client";
import { setsettings } from "../../store";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import AutoCompleteDropdown from "../popups/autoCompleteDropdown";

const ProductKds = () => {
  const { storeIds, ingredients } = useSelector((state: any) => state.settings);
  const [Kds, setKds] = useState<any[]>([]);

  // const Kds = [{
  //     value: "kds1", label: "KDS1"
  // },
  // { value: "kds2", label: "KDS2" },
  // { value: "kds3", label: "KDS3" },
  // {
  //     value: "kds4", label: "KDS4"
  // },
  // { value: "kds5", label: "KDS5" },
  // ]

  const [selectedKDS, setSelectedKDS] = useState<{ id: string; label: string }>(
    { id: "", label: "" }
  );

  // useEffect(() => {
  //     const list = Kds.filter((each) => (storeIds.includes(each.value)))
  //     setSelectedKDS(list as { value: string; label: string; }[])

  // }, [storeIds])
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(!isMounted);
  }, []);

  useEffect(() => {
    // const list = Kds.filter((each) =>
    //   storeIds.toString().includes(each.id.toString())
    // );

    const list1 = Kds.filter((each: any) => each.id === storeIds);

    if (list1.length === 0) {
      setSelectedKDS({ id: "", label: "" });
    } else {
      setSelectedKDS(list1[0]);
      console.log(list1[0]);
    }

    //   setSelectedKDS( { id: string; label: string });
  }, [storeIds, isMounted, Kds]);

  const dispatch = useDispatch();

  //   const handleKDSChange = (
  //     event: React.SyntheticEvent<Element, Event>,
  //     newValue: { value: string; label: string } | null,

  //   ) => {
  //     if (newValue) {
  //       setSelectedKDS(newValue);
  //       dispatch(setsettings("storeIds", newValue.value));
  //     }
  //   };

  const handleKDSChange = (newData: any) => {
    if (newData) {
      const { id } = newData;
      setSelectedKDS(newData);
      dispatch(setsettings("storeIds", id));
    } else {
      setSelectedKDS({ id: "", label: "" });
      dispatch(setsettings("storeIds", null));
    }
  };

  //   const onDragEnd = (result: any) => {
  //     if (!result.destination) return; // Item was dropped outside of the list

  //     const updatedItems = [...selectedKDS];
  //     const [reorderedItem] = updatedItems.splice(result.source.index, 1);
  //     updatedItems.splice(result.destination.index, 0, reorderedItem);

  //     setSelectedKDS(updatedItems);
  //   };

  const [name, setName] = useState("");
  const [qty, setQty] = useState<number | string>("");

  const [tableData, setTableData] = useState<
    {
      id: string;
      name: string;
      selectedOption: string;
      qty: string | number;
    }[]
  >(ingredients);

  useEffect(() => {
    dispatch(setsettings("ingredients", tableData));
  }, [tableData]);
  const [add, setAdd] = useState(false);

  const handleSaveClick = () => {
    // Generate a new unique id for the new row
    const newId = uuid();

    // Create a new row object with dummy data (you can replace this with actual data)
    const newRow = {
      id: newId,
      name: name,

      selectedOption: "Option New",
      qty: qty,
    };

    // Update the tableData state by appending the new row
    setTableData((prevTableData) => [...prevTableData, newRow]);
    setAdd(false);
    setQty("");
    setName("");
  };
  const handleCancel = () => {
    setAdd(false);
    setQty("");
    setName("");
  };

  const handleDeleteRow6 = (id: any) => {
    const updatedData = tableData.filter((item) => item.id !== id);
    setTableData(updatedData);
  };

  const handleOptionChange = (id: any, value: any) => {
    const updatedData =
      tableData &&
      tableData.map((item) =>
        item.id === id ? { ...item, selectedOption: value } : item
      );
    setTableData(updatedData);
  };

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { data: kdsList } = useQuery(KDS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId, $storeId: locationId && +locationId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (kdsList && kdsList.pairedDevicesList) {
      const list =
        kdsList.pairedDevicesList.edges &&
        kdsList.pairedDevicesList.edges.map((each: any) => ({
          id: each.node.serialNumber,
          label: each.node.deviceName,
        }));
      setKds(list);
    }
  }, [kdsList]);

  return (
    <Box sx={{ background: "var(--background-light-200, #FBFBFB)" }}>
      <Box style={{ padding: "1%", backgroundColor: "#FCFCFC" }}>
        <Box>
          {/* <Autocomplete
            // multiple={true}
            id="tags-outlined"
            options={Kds}
            getOptionLabel={(option: any) => option.label}
            filterSelectedOptions
            value={selectedKDS}
            onChange={handleKDSChange}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Select KDS station"
                placeholder="Select KDS station"
                color="warning"
              />
            )}
            style={{ marginTop: "2%", background: "#FFF" }}
          /> */}

          <AutoCompleteDropdown
            handleChange={handleKDSChange}
            data={Kds}
            width="100%"
            label="Select KDS station"
            value={selectedKDS}
          />
        </Box>

        {/* {selectedKDS.length > 1 && (
                    <Box sx={{ mt: "2%", background: "#FFF", display: "flex" }}>
                        <Typography className='create-product-switch-text'>Set KDS Sequence</Typography>
                        <div style={{ width: "50%", marginLeft: "15%" }}>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <StrictModeDroppable droppableId="itemList">
                                    {(provided) => (
                                        <ul
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{ listStyle: 'none', padding: 0 }}
                                        >
                                            {selectedKDS && selectedKDS.map((item, index) => (
                                                <Draggable key={item.value} draggableId={item.value} index={index}>
                                                    {(provided) => (
                                                        <li
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                border: '1px solid #ccc',
                                                                margin: '4px',
                                                                padding: '8px',
                                                                background: 'white',
                                                                ...provided.draggableProps.style,
                                                            }}
                                                        >
                                                            <Typography sx={{ textTransform: "capitalize" }}> {item.label}</Typography>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </StrictModeDroppable>
                            </DragDropContext>
                        </div>

                    </Box>
                )} */}

        <TableContainer sx={{ marginTop: "12px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "25%" }} align="center">
                  <Typography className="table-head-ingredient">
                    Ingredients
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "25%" }} align="center">
                  <Typography className="table-head-ingredient">Qty</Typography>
                </TableCell>
                <TableCell sx={{ width: "25%" }} align="center">
                  {" "}
                  <Typography className="table-head-ingredient">
                    Unit
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "25%" }} align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData &&
                tableData.map((row) => (
                  <TableRow key={row.id} sx={{}}>
                    <TableCell
                      sx={{ width: "25%", padding: "6px" }}
                      align="center"
                    >
                      {/* <img src={row.image} alt={`Item ${row.id}`} style={{ width: 50, height: 50 }} /> */}
                      <Typography
                        className="tablecell-text"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.name}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ width: "25%", padding: "6px" }}
                      align="center"
                    >
                      <Typography className="tablecell-text">
                        {row.qty}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ width: "25%", padding: "6px" }}
                      align="center"
                    >
                      <FormControl sx={{ width: "120px" }} size="small">
                        <Select
                          value={row.selectedOption}
                          onChange={(e) =>
                            handleOptionChange(row.id, e.target.value)
                          }
                        >
                          <MenuItem value="gram">Gram</MenuItem>
                          <MenuItem value="mil ltr">Milli ltr</MenuItem>
                          {/* Add more options as needed */}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ width: "25%" }} align="center">
                      <IconButton onClick={() => handleDeleteRow6(row.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {add && (
          <Box sx={{ mt: "12px", dispaly: "flex" }}>
            <TextField
              label="Ingredient"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <TextField
              sx={{ ml: "12px" }}
              label="Qty"
              type="text"
              onChange={(e) => {
                const val = e.target.value;

                if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                  setQty(e.target.value);
                }
              }}
              value={qty}
            />
            <Button
              onClick={handleCancel}
              className="cancel-button"
              sx={{ ml: "12px" }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveClick}
              className={name === "" ? "main-button-disable" : "main-button"}
              disabled={name === ""}
              sx={{ ml: "12px" }}
            >
              Save
            </Button>
          </Box>
        )}
        <Box>
          {!add && (
            <Button
              onClick={() => {
                setAdd(true);
              }}
              sx={{ marginTop: "8px" }}
              className="add-button"
            >
              +Add more
            </Button>
          )}
        </Box>
      </Box>
      {/* <Paper elevation={2} style={{ margin: '1% 15% 0% 10%', padding: '1%', borderLeft: '2px solid var(--stroke-color-3-green, #BAE29B)', backgroundColor: '#FCFCFC' }}>

               


            </Paper> */}
    </Box>
  );
};
export default ProductKds;
