// import { Box, Typography } from "@mui/material";
// import React, { ReactNode } from "react";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";
// interface State {
//   data?: any[];
//   width: string;
//   label: string | ReactNode;
//   value: any;
//   handleChange: (newData: string) => void;
// }
// const AutoCompleteDropdown = (props: State) => {
//   const { data, width, label, value, handleChange } = props;
//   console.log(data);

//   return (
//     <Autocomplete
//       color="warning"
//       id="combo-box-demo"
//       value={value}
//       onChange={(event, newInputValue) => handleChange(newInputValue)}
//       options={data || []}
//       sx={{ width: width }}
//       renderOption={(props, option) => (
//         <Box
//           component="li"
//           sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
//           {...props}
//         >
//           <Typography sx={{ textTransform: "capitalize" }}>
//             {option.label}
//           </Typography>
//         </Box>
//       )}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={
//             <Typography className="autocomplete-dropdown-label">
//               {label}
//             </Typography>
//           }
//         />
//       )}
//     />
//   );
// };

// export default AutoCompleteDropdown;

// import { Box, Typography } from "@mui/material";
// import React, { ReactNode } from "react";
// import TextField from "@mui/material/TextField";
// import Autocomplete from "@mui/material/Autocomplete";

// interface Option {
//   id: string;
//   label: string;
// }

// interface State {
//   data?: Option[];
//   width: string;
//   label: string | ReactNode;
//   value: Option | null; // Ensure value is of type Option or null
//   handleChange: (newData: Option | null) => void;
// }

// const AutoCompleteDropdown = (props: State) => {
//   const { data, width, label, value, handleChange } = props;
//   console.log(data, "data");

//   return (
//     <Autocomplete
//       color="warning"
//       id="combo-box-demo"
//       value={value}
//       onChange={(event, newInputValue) => handleChange(newInputValue)}
//       options={data || []}
//       getOptionLabel={(option) => option.label}
//       isOptionEqualToValue={(option, value) => option.id === value?.id} // Compare option.id with value.id
//       sx={{ width: width }}
//       renderOption={(props, option) => {
//         return (
//           <Box
//             component="li"
//             sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
//             {...props}
//           >
//             <Typography sx={{ textTransform: "capitalize" }}>
//               {option.label}
//             </Typography>
//           </Box>
//         );
//       }}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           label={
//             <Typography className="autocomplete-dropdown-label">
//               {label}
//             </Typography>
//           }
//         />
//       )}
//     />
//   );
// };

// export default AutoCompleteDropdown;

import { Box, Typography } from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

interface Option {
  id: string;
  label: string;
}

interface State {
  data?: Option[];
  width: string;
  label: string | ReactNode;
  value: Option | null;
  handleChange: (newData: Option | null) => void;
}

const AutoCompleteDropdown = (props: State) => {
  const { data, width, label, value, handleChange } = props;

  // Create a map to count duplicate labels
  const labelCounts = useMemo(() => {
    const counts = new Map<string, number>();
    data?.forEach((item) => {
      counts.set(item.label, (counts.get(item.label) || 0) + 1);
    });
    return counts;
  }, [data]);

  // Create a map of display labels for items with duplicates
  const displayLabels = useMemo(() => {
    const labels = new Map<string, string>();
    data?.forEach((item) => {
      if (labelCounts.get(item.label)! > 1) {
        // If there are duplicates, append the ID to make it unique
        labels.set(item.id, `${item.label} [ID: ${item.id}]`);
      } else {
        labels.set(item.id, item.label);
      }
    });
    return labels;
  }, [data, labelCounts]);

  return (
    <Autocomplete
      color="warning"
      id="combo-box-demo"
      value={value}
      onChange={(event, newInputValue) => handleChange(newInputValue)}
      options={data || []}
      getOptionLabel={(option) => displayLabels.get(option.id) || option.label}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      sx={{ width: width }}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <Typography sx={{ textTransform: "capitalize" }}>
              {displayLabels.get(option.id)}
            </Typography>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <Typography className="autocomplete-dropdown-label">
              {label}
            </Typography>
          }
        />
      )}
    />
  );
};

export default AutoCompleteDropdown;
