import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_ONLINE_ORDERING_SETTINGS,
  ONLINE_ORDERING_SETTINGS,
} from "../../graphicalQl/mutation/onlineOrdering";
import { useMutation, useQuery } from "@apollo/client";
import DomainFinalModal from "./domianFinalModal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const validationSchemaPart1 = Yup.object({
  subDomain: Yup.string().required("value is Required"),
});

const CustomizeDomain = () => {
  const businessId = sessionStorage.getItem("businessId") as string;

  const { t } = useTranslation();

  const initialState = {
    subDomain: "",
    adminDomain: "",
    customerDomain: "",
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,

    onSubmit: (values) => {
      upateDomain({
        variables: {
          businessId: parseInt(businessId),
          domain: values.customerDomain === "" ? null : values.customerDomain,
          subDomain: values.subDomain === "" ? null : values.subDomain,
          adminDomain: values.adminDomain === "" ? null : values.adminDomain,
        },
        onCompleted: () => {
          setFinalModal(true);
        },
      });
    },
  });

  const [upateDomain] = useMutation(ONLINE_ORDERING_SETTINGS, {
    context: { clientName: "businessClient" },
  });
  const { data: settingsData } = useQuery(GET_ONLINE_ORDERING_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: {
      businessId: parseInt(businessId),
    },
  });

  const [DomainName, setDomainName] = useState<string | null>(null);

  useEffect(() => {
    if (settingsData && settingsData.business) {
      setDomainName(settingsData.business.domain);
      formik.setValues({
        subDomain: settingsData.business.subDomain,
        adminDomain: settingsData.business.adminDomain,
        customerDomain: settingsData.business.domain,
      });
    }
  }, [settingsData]);
  const [finalModal, setFinalModal] = useState(false);
  const navigate = useNavigate();

  const handleNaviagte = () => {
    if (DomainName) {
      navigate("/online/doamins-listing");
    } else {
      navigate("/online/start-setUp");
    }
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            gap: "30%",
            alignItems: "center",
            padding: "12px",
          }}
        >
          <IconButton
            className="exit-container"
            onClick={() => {
              handleNaviagte();
            }}
          >
            <CloseIcon className="exitIcon" />
          </IconButton>
          <Typography
            className="main-head"
            sx={{ textTransform: "capitalize" }}
          >
            {t("online_ordering.customize_domain")}
          </Typography>
        </Box>
        <Divider sx={{ marginTop: "12px" }} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ marginTop: "32px", width: "70%" }}>
            <Typography className="main-head16 ">
              {" "}
              {t("online_ordering.domain")}
            </Typography>
            <Typography className="main-para" sx={{ marginTop: "12px" }}>
              {t("online_ordering.domain_offered")}{" "}
              <span className="url-text-style">
                {t("online_ordering.business_mail")}
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                marginTop: "12px",
              }}
            >
              <Box>
                <TextField
                  name="subDomain"
                  value={formik.values.subDomain}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(
                    formik.touched.subDomain && formik.errors.subDomain
                  )}
                />
                {formik.touched.subDomain && formik.errors.subDomain && (
                  <Typography
                    style={{ color: "red", fontSize: "12px", marginTop: "8px" }}
                  >
                    {formik.errors.subDomain}
                  </Typography>
                )}
              </Box>
              <Typography className="main-head2">
                {t("online_ordering.mail_end")}
              </Typography>
            </Box>

            <Typography className="main-para" sx={{ marginTop: "24px" }}>
              {t("online_ordering.mail_para")}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "18px" }} />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ marginTop: "32px", width: "70%" }}>
            <Typography className="main-head16 ">
              {t("online_ordering.custom_domain")}
              <span className="normal-grey-text-12">
                ({t("online_ordering.optional")})
              </span>
            </Typography>
            <Typography className="main-para" sx={{ marginTop: "12px" }}>
              {t("online_ordering.optimize_domain")}{" "}
              <span className="url-text-style-orange">
                {t("online_ordering.mail_com")}
              </span>
            </Typography>
            <Box sx={{ marginTop: "24px" }}>
              <Typography className="main-head16">
                {t("online_ordering.enter_customer_name")}
              </Typography>
              <TextField
                fullWidth
                placeholder="mystore.co.in"
                sx={{ marginTop: "6px" }}
                name="customerDomain"
                value={formik.values.customerDomain}
                onChange={formik.handleChange}
              />

              <Typography sx={{ marginTop: "12px" }} className="main-head16">
                {t("online_ordering.enter_domain_name")}
              </Typography>
              <TextField
                fullWidth
                placeholder="mystore.co.in"
                sx={{ marginTop: "6px" }}
                name="adminDomain"
                value={formik.values.adminDomain}
                onChange={formik.handleChange}
              />

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  padding: "12px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "20px",
                  marginTop: "12px",
                  background: "#FBFBFB",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: "4px 8px",
                    alignItems: "flex-start",
                    gap: "10px",
                    marginTop: "12px",
                    borderRadius: "4px",
                    background: "#EEE",
                  }}
                >
                  <Typography className="url-text-style-grey">
                    {t("online_ordering.domain_name_server")}{" "}
                    <span className="url-text-style-orange">
                      {t("online_ordering.manage_dns")}
                    </span>{" "}
                    {t("online_ordering.page")}
                  </Typography>
                </Box>
                <Typography className="tablecell-text2">
                  {t("online_ordering.domain_para_1")}{" "}
                  <span className="url-text-style-red">
                    {" "}
                    {t("online_ordering.domain_para_2")}{" "}
                  </span>
                  {t("online_ordering.domain_para_3")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: "95%", textAlign: "right", marginTop: "32px" }}>
          <Button className="main-button" type="submit">
            {t("online_ordering.next")} <ArrowForwardIcon />{" "}
          </Button>
        </Box>
      </form>

      <DomainFinalModal finalModal={finalModal} setFinalModal={setFinalModal} />
    </Box>
  );
};

export default CustomizeDomain;
