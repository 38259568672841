import { Box, TextField, Typography } from "@mui/material";
import React from "react";
interface Component {
  colorValue: string;
  handleColorChange: (color: string) => void;
  label: string;
}
const ColorPicker = (props: Component) => {
  const { colorValue, handleColorChange, label } = props;
  return (
    <>
      <Typography className="web-settings-textfield-label">{label}</Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          border: "1px solid #ddd",
          borderRadius: 1,
          p: 1,
          backgroundColor: "#fff",
        }}
      >
        {/* Hex Value Input */}
        <TextField
          value={colorValue} // Your color state
          onChange={(e) => handleColorChange(e.target.value)}
          variant="standard"
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            flexGrow: 1,
            "& input": {
              fontSize: "16px",
              fontFamily: "monospace",
              pl: 0,
            },
          }}
        />

        {/* Color Preview/Picker Box */}
        <Box sx={{ position: "relative", width: "40px", height: "40px" }}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: colorValue,
              borderRadius: 0.5,
            }}
          />
          <input
            type="color"
            value={colorValue}
            onChange={(e) => handleColorChange(e.target.value)}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ColorPicker;
