import { Box, Typography } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";

import KdsListing from "./kdsListing";
import PosListing from "./posListing";
import KioskListing from "./kioskListing";

import "./deviceSetUp.css";
import { useQuery } from "@apollo/client";
import { DEVICE_LIST_FOR_KDS } from "../../graphicalQl/usequery/deviceManagementQuery";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";

import { useSelector } from "react-redux";
import { deviceListing } from "../../store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

function DeviceListing() {
  const { t } = useTranslation();
  const { tabId } = useSelector((state: any) => state.deviceListing);
  const dispatch = useDispatch();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [locationsListForKds, seLocationsListForKds] = useState<any[]>([]);
  const [locationsListForPos, seLocationsListForPos] = useState<any[]>([]);
  const [locationsListForKiosk, seLocationsListForKiosk] = useState<any[]>([]);
  const [collectedLocationIdsForKds, setCollectedLocationIdsForKds] = useState<
    any[]
  >([]);
  const [collectedLocationIdsForPos, setCollectedLocationIdsForPos] = useState<
    any[]
  >([]);
  const [collectedLocationIdsForKiosk, setCollectedLocationIdsForKiosk] =
    useState<any[]>([]);
  const [rowsForKds, setRowsForKds] = React.useState<any[]>([]);
  const [rowsForPos, setRowsForPos] = React.useState<any[]>([]);
  const [rowsForKiosk, setRowsForKiosk] = React.useState<any[]>([]);
  const [endCursorForKds, setEndCursorForKds] = useState<string | null>(null);
  const [startCursorForKds, setStartCursorForKds] = useState<string | null>(
    null
  );

  const [paginationModelForKds, setPaginationModelForKds] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForKds, setCurrentPageForKds] = React.useState(0);

  useEffect(() => {
    const filterdData =
      locationsListForKds &&
      locationsListForKds
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForKds(filterdData);
  }, [locationsListForKds]);

  useEffect(() => {
    const filterdData =
      locationsListForPos &&
      locationsListForPos
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForPos(filterdData);
  }, [locationsListForPos]);

  useEffect(() => {
    const filterdData =
      locationsListForKiosk &&
      locationsListForKiosk
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForKiosk(filterdData);
  }, [locationsListForKiosk]);

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,

          isChecked: true,
        })
      );
      seLocationsListForKds(requiredDataList);
      seLocationsListForPos(requiredDataList);
      seLocationsListForKiosk(requiredDataList);
    }
  }, [locationsData]);

  const filteredDataFromNullForKds = {
    businessId: +businessId,
    deviceType: "KDS",
    storeIds:
      collectedLocationIdsForKds.length > 0
        ? JSON.stringify(collectedLocationIdsForKds)
        : JSON.stringify([null]),
    first: !startCursorForKds ? paginationModelForKds.pageSize : null,
    after: endCursorForKds,
    before: startCursorForKds,
    last: startCursorForKds && paginationModelForKds.pageSize,
  };
  const filteredIdsFromKds = Object.fromEntries(
    Object.entries(filteredDataFromNullForKds).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: deviceListDataForKds,
    loading: deviceListLoading,
    error: deviceListError,
  } = useQuery(DEVICE_LIST_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKds,
    fetchPolicy: "network-only",
  });

  const [endCursorForPos, setEndCursorForPos] = useState<string | null>(null);
  const [startCursorForPos, setStartCursorForPos] = useState<string | null>(
    null
  );

  const [paginationModelForPos, setPaginationModelForPos] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForPos, setCurrentPageForPos] = React.useState(0);

  const shouldUpdatePaginationModelIsTrueForPos = (newModel: any) => {
    setPaginationModelForPos(newModel);
    setCurrentPageForPos(newModel.page);
  };

  const handleUpdatingValuesToNullForPos = (newModel: any) => {
    setStartCursorForPos(null);
    setEndCursorForPos(null);
    setCurrentPageForPos(0);
    setPaginationModelForPos({
      ...newModel,
      page: 0,
    });
  };

  const [endCursorForKiosk, setEndCursorForKiosk] = useState<string | null>(
    null
  );
  const [startCursorForKiosk, setStartCursorForKiosk] = useState<string | null>(
    null
  );

  const [paginationModelForKiosk, setPaginationModelForKiosk] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForKiosk, setCurrentPageForKiosk] = React.useState(0);

  const shouldUpdatePaginationModelIsTrueForKiosk = (newModel: any) => {
    setPaginationModelForKiosk(newModel);
    setCurrentPageForKiosk(newModel.page);
  };

  const handleUpdatingValuesToNullForKiosk = (newModel: any) => {
    setStartCursorForKiosk(null);
    setEndCursorForKiosk(null);
    setCurrentPageForKiosk(0);
    setPaginationModelForKiosk({
      ...newModel,
      page: 0,
    });
  };

  const shouldUpdatePaginationModelIsTrueForKds = (newModel: any) => {
    setPaginationModelForKds(newModel);
    setCurrentPageForKds(newModel.page);
  };

  const handleUpdatingValuesToNullForKds = (newModel: any) => {
    setStartCursorForKds(null);
    setEndCursorForKds(null);
    setCurrentPageForKds(0);
    setPaginationModelForKds({
      ...newModel,
      page: 0,
    });
  };

  useEffect(() => {
    if (deviceListDataForKds) {
      const { pairedDevicesList } = deviceListDataForKds;
      if (pairedDevicesList) {
        const { edges } = pairedDevicesList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              deviceName: eachStore.node.deviceName,
              kdsStationName: eachStore.node.kdsStationName,
              id: eachStore.node.pairingId,
              storeName: eachStore.node.store && eachStore.node.store.storeName,
              status: eachStore.node.status,
              deviceCode: eachStore.node.deviceCode,
              serialNumber: eachStore.node.serialNumber,
            };
          });
          setRowsForKds(filteredList);
        }
      }
    } else {
      setRowsForKds([]);
    }
  }, [deviceListDataForKds]);

  const filteredDataFromNullForPos = {
    businessId: +businessId,
    deviceType: "POS",
    storeIds:
      collectedLocationIdsForPos.length > 0
        ? JSON.stringify(collectedLocationIdsForPos)
        : JSON.stringify([null]),
    first: !startCursorForPos ? paginationModelForPos.pageSize : null,
    after: endCursorForPos,
    before: startCursorForPos,
    last: startCursorForPos && paginationModelForPos.pageSize,
  };
  const filteredIdsFromPos = Object.fromEntries(
    Object.entries(filteredDataFromNullForPos).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: deviceListDataForPos,
    loading: deviceListLoadingPos,
    error: deviceListErrorPos,
  } = useQuery(DEVICE_LIST_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromPos,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (deviceListDataForPos) {
      const { pairedDevicesList } = deviceListDataForPos;
      if (pairedDevicesList) {
        const { edges } = pairedDevicesList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              deviceName: eachStore.node.deviceName,
              kdsStationName: eachStore.node.kdsStationName,
              id: eachStore.node.pairingId,
              status: eachStore.node.status,
              storeName: eachStore.node.store && eachStore.node.store.storeName,
              deviceCode: eachStore.node.deviceCode,
              serialNumber: eachStore.node.serialNumber,
            };
          });
          setRowsForPos(filteredList);
        }
      }
    } else {
      setRowsForPos([]);
    }
  }, [deviceListDataForPos]);

  const filteredDataFromNullForKiosk = {
    businessId: +businessId,
    deviceType: "KIOSK",
    storeIds:
      collectedLocationIdsForKiosk.length > 0
        ? JSON.stringify(collectedLocationIdsForKiosk)
        : JSON.stringify("[null]"),
    first: !startCursorForKiosk ? paginationModelForKiosk.pageSize : null,
    after: endCursorForKiosk,
    before: startCursorForKiosk,
    last: startCursorForKiosk && paginationModelForKiosk.pageSize,
  };
  const filteredIdsFromKiosk = Object.fromEntries(
    Object.entries(filteredDataFromNullForKiosk).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: deviceListDataForKiosk,
    loading: deviceListLoadingKisok,
    error: deviceListErrorKiosk,
  } = useQuery(DEVICE_LIST_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKiosk,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (deviceListDataForKiosk) {
      const { pairedDevicesList } = deviceListDataForKiosk;
      if (pairedDevicesList) {
        const { edges } = pairedDevicesList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              deviceName: eachStore.node.deviceName,
              kdsStationName: eachStore.node.kdsStationName,
              id: eachStore.node.pairingId,
              status: eachStore.node.status,
              storeName: eachStore.node.store && eachStore.node.store.storeName,
              deviceCode: eachStore.node.deviceCode,
              serialNumber: eachStore.node.serialNumber,
            };
          });
          setRowsForKiosk(filteredList);
        }
      }
    } else {
      setRowsForKiosk([]);
    }
  }, [deviceListDataForKiosk]);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const PopoverOpen = Boolean(anchorEl);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(deviceListing("tabId", newValue));
  };

  const handleEndCursorForPos = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      deviceListDataForPos?.pairedDevicesList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForPos = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      deviceListDataForPos?.pairedDevicesList?.pageInfo.startCursor || null
    );
  };

  const handleEndCursorForKds = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      deviceListDataForKds?.pairedDevicesList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForKds = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      deviceListDataForKds?.pairedDevicesList?.pageInfo.startCursor || null
    );
  };

  const handleEndCursorForKiosk = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      deviceListDataForKiosk?.pairedDevicesList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForKiosk = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      deviceListDataForKiosk?.pairedDevicesList?.pageInfo.startCursor || null
    );
  };

  return (
    <Box sx={{ padding: "20px" }} className="overall-background">
      <Box>
        <Typography className="device-set-up-header">
          {t("devices.device_listing")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", typography: "body1", marginTop: "15px" }}>
        <TabContext value={tabId}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              sx={{ backgroundColor: "#F9F9F9" }}
              className="background"
              onChange={handleChange}
            >
              <Tab
                sx={{ marginRight: "5px" }}
                label={
                  <Typography
                    className={
                      tabId === "1"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.pos_listing")}
                  </Typography>
                }
                className={tabId === "1" ? "change-color" : "add-color"}
                value="1"
              />
              <Tab
                sx={{ marginRight: "5px" }}
                label={
                  <Typography
                    className={
                      tabId === "2"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.kds_listing")}
                  </Typography>
                }
                className={tabId === "2" ? "change-color" : "add-color"}
                value="2"
              />
              <Tab
                label={
                  <Typography
                    className={
                      tabId === "3"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.kiosk_listing")}
                  </Typography>
                }
                className={tabId === "3" ? "change-color" : "add-color"}
                value="3"
              />
            </TabList>
          </Box>
          <TabPanel value="2">
            <KdsListing
              rows={rowsForKds}
              seLocationsList={seLocationsListForKds}
              locationsList={locationsListForKds}
              Loading={deviceListLoading ? deviceListLoading : false}
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrueForKds
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNullForKds}
              paginationModel={paginationModelForKds}
              handleEndCursor={handleEndCursorForKds}
              handleStartCursor={handleStartCursorForKds}
              currentPage={currentPageForKds}
            />
          </TabPanel>
          <TabPanel value="1">
            <PosListing
              rows={rowsForPos}
              seLocationsList={seLocationsListForPos}
              locationsList={locationsListForPos}
              Loading={deviceListLoadingPos ? deviceListLoadingPos : false}
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrueForPos
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNullForPos}
              paginationModel={paginationModelForPos}
              handleEndCursor={handleEndCursorForPos}
              handleStartCursor={handleStartCursorForPos}
              currentPage={currentPageForPos}
            />
          </TabPanel>
          <TabPanel value="3">
            <KioskListing
              rows={rowsForKiosk}
              seLocationsList={seLocationsListForKiosk}
              locationsList={locationsListForKiosk}
              Loading={deviceListLoadingKisok ? deviceListLoadingKisok : false}
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrueForKiosk
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNullForKiosk}
              paginationModel={paginationModelForKiosk}
              handleEndCursor={handleEndCursorForKiosk}
              handleStartCursor={handleStartCursorForKiosk}
              currentPage={currentPageForKiosk}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default DeviceListing;
