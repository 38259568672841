import {
  Box,
  Button,
  Typography,
  Paper,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useRef } from "react";
import "./basicDetails.css";
import ImageDrawer from "../menu/image/imageDrawer";
import { useMutation } from "@apollo/client";
import TextFieldForDebounce from "../../utils/textFieldForDebounce";
import { DUPLICATE_LOCATION_CHECK } from "../../graphicalQl/mutation/locationMutation";
import { useTranslation } from "react-i18next";

interface BasicDetailsState {
  formik: any;
  editId: string | undefined;
  updateBasicDetails: () => void;
  setConditions: React.Dispatch<React.SetStateAction<boolean[]>>;
}
const initialValuesOfCreateLocationFormBasicDetails = {
  branchName: "",
  localName: "",
  businessInfo: "",
  locationType: "PHYSICAL",
  image: null,
  color: null,
};

const BasicDetails = (props: BasicDetailsState) => {
  const { t } = useTranslation();
  const { formik, editId, setConditions } = props;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [duplicateCheckOFLocation] = useMutation(DUPLICATE_LOCATION_CHECK, {
    onCompleted: (data) => {
      if (data) {
        const { duplicateLocationNameCheck } = data;
        if (duplicateLocationNameCheck) {
          const { duplicate } = duplicateLocationNameCheck;
          if (duplicate) {
            formik.setFieldValue("isDuplicate", true);
          } else {
            formik.setFieldValue("isDuplicate", false);
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
  });
  const handleImage = (value: string | null) => {
    formik.setFieldValue("image", value);
  };
  const handleColor = (value: string | null) => {
    formik.setFieldValue("color", value);
  };

  const handleBranchName = (name: string) => {
    if (name == "") {
      setConditions([true, true, true]);
    }

    formik.setFieldValue("branchName", name);
    const filteredAddresList = {
      locationId: editId && +editId,
      businessId: +businessId,
      locationName: name?.trim(),
    };

    const requiredFormat = Object.fromEntries(
      Object.entries(filteredAddresList).filter(([_, value]) => value !== null)
    );

    duplicateCheckOFLocation({
      variables: requiredFormat,
    });
  };
  const imageDrawerRef = useRef<any>();
  const handleClearButton = () => {
    // formik.setValues(initialValuesOfCreateLocationFormBasicDetails);
    // formik.setFieldValue("image", null);
    // formik.setFieldValue("color", null);
    formik.resetForm();
    if (imageDrawerRef.current) {
      imageDrawerRef.current.handleNull();
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Paper
        sx={{ borderLeft: "2px solid #AE9BE2", padding: "20px", width: "90%" }}
        elevation={2}
      >
        <Typography
          className="basic-details-heading"
          sx={{ marginLeft: "10px" }}
        >
          {t("locations.basic_details")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "10px",
              width: "100%",
            }}
          >
            <Box
              sx={{ width: "100%", display: "flex", flexDirection: "column" }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  width: "80%",
                  margin: "10px 0px 5px 0px",
                }}
              >
                {/* <TextField
                  onChange={(e) => {
                    handleBranchName(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                  name="branchName"
                  value={formik.values.branchName || ""}
                  color="warning"
                  sx={{ width: "50%" }}
                  label={<Typography>{t("locations.branch_name")}</Typography>}
                  variant="outlined"
                /> */}
                <TextFieldForDebounce
                  label={t("locations.branch_name")}
                  handleChange={handleBranchName}
                  handleBlur={formik.handleBlur}
                  name="branchName"
                  width="50%"
                  formik={formik}
                  debounceVariable="debounceVariable"
                  value={formik.values.debounceVariable}
                />

                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  name="localName"
                  value={formik.values.localName || ""}
                  color="warning"
                  sx={{ width: "48%" }}
                  label={<Typography>{t("locations.local_name")}</Typography>}
                  variant="outlined"
                />
              </Box>
              {(formik.touched.branchName && formik.errors.branchName) ||
              formik.values.isDuplicate ? (
                <Box>
                  <Typography
                    sx={{ marginBottom: "5px" }}
                    className="create-a-user-formik-label"
                  >
                    {formik.errors.branchName
                      ? t("error.required")
                      : t("error.already_existed")}
                  </Typography>
                </Box>
              ) : null}
              <Box sx={{ marginBottom: "10px" }}>
                <div
                  className="textarea-box"
                  style={{ width: "80%", marginTop: "1%" }}
                >
                  <textarea
                    id="textarea"
                    onChange={(e) => {
                      if (e.target.value.length <= 500) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    style={{ width: "100%" }}
                    name="businessInfo"
                    value={formik.values.businessInfo || ""}
                    placeholder={
                      t("locations.enter_business_information") || ""
                    }
                  >
                    {formik.values.businessInfo}
                  </textarea>
                  <div
                    id="charCount"
                    style={{
                      color: "#666",
                      fontSize: "12px",
                      width: "100%",
                      textAlign: "right",
                    }}
                  >
                    {formik.values.businessInfo &&
                      formik.values.businessInfo.length}
                    /500
                  </div>
                </div>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderRadius: "5px",
                alignItems: "center",
                marginRight: "25px",
              }}
            >
              <Box
                sx={{
                  marginLeft: "86px",
                  width: "150px",
                  height: "150px",
                  padding: "2px",
                }}
                className="create-product-img-con"
              >
                <ImageDrawer
                  ref={imageDrawerRef}
                  handleImage={handleImage}
                  handleColor={handleColor}
                  colorCodeFromApi={formik.values.color}
                  imageFromApi={formik.values.image}
                  heightValue={120}
                  imageType={"location"}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 0px 10px",
              width: "100%",
            }}
          >
            <FormControl sx={{ width: "26%" }}>
              <InputLabel
                className="create-a-use-role-label"
                id="assign-role-label"
              >
                {t("locations.location_type")}
              </InputLabel>
              <Select
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="locationType"
                label={t("locations.location_type")}
                value={formik.values.locationType}
                color="warning"
                labelId="assign-role-label"
                // label={<Typography>Assign Role</Typography>}
              >
                <MenuItem value="PHYSICAL">{t("locations.physical")}</MenuItem>
                <MenuItem value="MOBILE">{t("locations.mobile")}</MenuItem>
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                marginTop: "5px",
                alignItems: "center",
                marginRight: "25px",
              }}
            >
              {editId === undefined && (
                <Button
                  onClick={handleClearButton}
                  sx={{ textTransform: "none" }}
                  className="create-a-use-role-clear-button"
                  variant="outlined"
                  color="warning"
                >
                  {t("buttons.clear")}
                </Button>
              )}
              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                variant="contained"
                color="warning"
              >
                {editId === undefined ? t("product.next") : t("buttons.save")}
              </Button>
            </Box>
          </Box>
        </form>
      </Paper>
      <Box
        sx={{
          width: "90%",
          margin: "10px 0px 0px 0px",
        }}
      >
        <Typography className="basic-details-warning-text">
          {t("locations.your_location")}
          <span className="basic-details-warning-text-span">
            {t("locations.contact_support")}
          </span>
          ..
        </Typography>
      </Box>
    </Box>
  );
};

export default BasicDetails;
