// // // // Other imports...
// // // import React, { useState, useEffect } from "react";
// // // import { getDistance } from "geolib"; // Importing getDistance from geolib

// // // interface PostcodeData {
// // //   postcode: string;
// // //   latitude: number;
// // //   longitude: number;
// // // }

// // // const EditZone: React.FC = () => {
// // //   const [postcodes, setPostcodes] = useState<PostcodeData[]>([]);
// // //   const [postcodesWithinRadius, setPostcodesWithinRadius] = useState<string[]>(
// // //     []
// // //   );
// // //   const centerLatitude = 51.5074; // Example latitude (e.g., London)
// // //   const centerLongitude = -0.1278; // Example longitude (e.g., London)
// // //   const radius = 20000; // 2000 meters radius (2 kilometers)

// // //   useEffect(() => {
// // //     const fetchPostcodes = async () => {
// // //       try {
// // //         const response = await fetch("api/postcodes");
// // //         if (!response.ok) {
// // //           throw new Error("Failed to fetch postcode data");
// // //         }
// // //         const data = await response.json();
// // //         setPostcodes(data); // Assuming the data is an array of objects with 'postcode', 'latitude', and 'longitude' properties
// // //       } catch (error) {
// // //         console.error("Error fetching postcode data:", error);
// // //       }
// // //     };

// // //     fetchPostcodes();
// // //   }, []);

// // //   useEffect(() => {
// // //     const postcodesWithinRadius = postcodes
// // //       .filter(
// // //         (postcode) =>
// // //           getDistance(
// // //             { latitude: centerLatitude, longitude: centerLongitude },
// // //             { latitude: postcode.latitude, longitude: postcode.longitude }
// // //           ) <= radius
// // //       )
// // //       .map((postcode) => postcode.postcode);
// // //     setPostcodesWithinRadius(postcodesWithinRadius);
// // //   }, [postcodes]);

// // //   if (postcodesWithinRadius.length === 0) {
// // //     return <div>Loading...</div>; // Or any loading indicator
// // //   }

// // //   return (
// // //     <div>
// // //       <h2>
// // //         Postcodes within 2 kilometers of ({centerLatitude}, {centerLongitude}):
// // //       </h2>
// // //       <ul>
// // //         {postcodesWithinRadius.map((postcode) => (
// // //           <li key={postcode}>{postcode}</li>
// // //         ))}
// // //       </ul>
// // //     </div>
// // //   );
// // // };

// // // export default EditZone;

// // import React, { useState, useEffect } from "react";
// // const apiKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE";

// // const getZipCodesWithinRadius = async (
// //   lat: number,
// //   lng: number,
// //   radius: number,
// //   apiKey: string
// // ): Promise<string[]> => {
// //   const map = new google.maps.Map(document.createElement("div"));
// //   const service = new google.maps.places.PlacesService(map);

// //   const request = {
// //     location: new google.maps.LatLng(lat, lng),
// //     radius: radius,
// //     types: ["establishment"], // Set the type of place you want to search for
// //   };

// //   const zipCodes = new Set<string>();

// //   return new Promise((resolve, reject) => {
// //     service.nearbySearch(request, async (results: any, status) => {
// //       if (status === google.maps.places.PlacesServiceStatus.OK) {
// //         for (const result of results) {
// //           const placeDetailsRequest = {
// //             placeId: result.place_id,
// //             fields: ["address_components"],
// //           };

// //           service.getDetails(
// //             placeDetailsRequest,
// //             (placeResult: any, placeStatus) => {
// //               if (placeStatus === google.maps.places.PlacesServiceStatus.OK) {
// //                 if (placeResult.address_components) {
// //                   for (const component of placeResult.address_components) {
// //                     if (component.types.includes("postal_code")) {
// //                       zipCodes.add(component.long_name);
// //                     }
// //                   }
// //                 }
// //               }
// //             }
// //           );
// //         }
// //         // Delay to ensure all details requests are completed
// //         setTimeout(() => resolve(Array.from(zipCodes)), 2000);
// //       } else {
// //         reject(new Error("Error fetching places"));
// //       }
// //     });
// //   });
// // };

// // const EditZone: React.FC = () => {
// //   const [lat, setLat] = useState<number>(34.0522); // default latitude
// //   const [lng, setLng] = useState<number>(-118.2437); // default longitude
// //   const [radius, setRadius] = useState<number>(5000); // radius in meters
// //   const [nearbyZipCodes, setNearbyZipCodes] = useState<string[]>([]);
// //   const [error, setError] = useState<string | null>(null);

// //   const apiKey = "YOUR_GOOGLE_API_KEY";

// //   useEffect(() => {
// //     const fetchZipCodes = async () => {
// //       try {
// //         const zipCodes = await getZipCodesWithinRadius(
// //           lat,
// //           lng,
// //           radius,
// //           apiKey
// //         );
// //         setNearbyZipCodes(zipCodes);
// //       } catch (error) {
// //         setError((error as Error).message);
// //       }
// //     };

// //     fetchZipCodes();
// //   }, [lat, lng, radius, apiKey]);

// //   return (
// //     <div>
// //       <h1>Nearby Zip Codes Finder</h1>
// //       <div>
// //         <label>
// //           Latitude:
// //           <input
// //             type="number"
// //             value={lat}
// //             onChange={(e) => setLat(Number(e.target.value))}
// //           />
// //         </label>
// //       </div>
// //       <div>
// //         <label>
// //           Longitude:
// //           <input
// //             type="number"
// //             value={lng}
// //             onChange={(e) => setLng(Number(e.target.value))}
// //           />
// //         </label>
// //       </div>
// //       <div>
// //         <label>
// //           Radius (meters):
// //           <input
// //             type="number"
// //             value={radius}
// //             onChange={(e) => setRadius(Number(e.target.value))}
// //           />
// //         </label>
// //       </div>
// //       {error && <p style={{ color: "red" }}>{error}</p>}
// //       <div>
// //         <h2>
// //           Zip Codes within {radius} meters of ({lat}, {lng}):
// //         </h2>
// //         <ul>
// //           {nearbyZipCodes.map((zipCode, index) => (
// //             <li key={index}>{zipCode}</li>
// //           ))}
// //         </ul>
// //       </div>
// //     </div>
// //   );
// // };

// // export default EditZone;

// // src/components/ZipCodeSearch.tsx

// // import React, { useState } from "react";
// // import axios from "axios";

// // const apiKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE"; // Replace with your actual Google API key

// // interface Coordinates {
// //   lat: number;
// //   lng: number;
// // }

// // async function getCoordinates(
// //   zipCode: string,
// //   apiKey: string
// // ): Promise<Coordinates> {
// //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${apiKey}`;
// //   const response = await axios.get(url);
// //   const data = response.data;
// //   if (data.status === "OK") {
// //     const location = data.results[0].geometry.location;
// //     return { lat: location.lat, lng: location.lng };
// //   } else {
// //     throw new Error("Error fetching coordinates");
// //   }
// // }

// // async function getZipCodesWithinRadius(
// //   lat: number,
// //   lng: number,
// //   radius: number,
// //   apiKey: string
// // ): Promise<string[]> {
// //   const url = `https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=${lat},${lng}&radius=${radius}&key=${apiKey}`;
// //   const response = await axios.get(url);
// //   const data = response.data;
// //   const zipCodes = new Set<string>();
// //   if (data.status === "OK") {
// //     for (const result of data.results) {
// //       const placeId = result.place_id;
// //       const placeDetailsUrl = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${apiKey}`;
// //       const placeDetailsResponse = await axios.get(placeDetailsUrl);
// //       const placeDetails = placeDetailsResponse.data;
// //       if (placeDetails.result.address_components) {
// //         for (const component of placeDetails.result.address_components) {
// //           if (component.types.includes("postal_code")) {
// //             zipCodes.add(component.long_name);
// //           }
// //         }
// //       }
// //     }
// //   }
// //   return Array.from(zipCodes);
// // }

// // async function getZipCodesNearby(
// //   centerZipCode: string,
// //   radius: number,
// //   apiKey: string
// // ): Promise<string[]> {
// //   const { lat, lng } = await getCoordinates(centerZipCode, apiKey);
// //   return getZipCodesWithinRadius(lat, lng, radius, apiKey);
// // }

// // const EditZone: React.FC = () => {
// //   const [centerZipCode, setCenterZipCode] = useState("");
// //   const [radius, setRadius] = useState<number>(5000);
// //   const [zipCodes, setZipCodes] = useState<string[]>([]);
// //   const [error, setError] = useState<string | null>(null);

// //   const handleSearch = async () => {
// //     setError(null);
// //     try {
// //       const nearbyZipCodes = await getZipCodesNearby(
// //         centerZipCode,
// //         radius,
// //         apiKey
// //       );
// //       setZipCodes(nearbyZipCodes);
// //     } catch (error: any) {
// //       console.error("Error details:", error);
// //       if (axios.isAxiosError(error)) {
// //         // This is an error generated by Axios
// //         setError(`Axios error: ${error.message}`);
// //       } else {
// //         // Some other error
// //         setError(`An unexpected error occurred: ${error.message}`);
// //       }
// //     }
// //   };

// //   return (
// //     <div>
// //       <h1>Zip Code Search</h1>
// //       <div>
// //         <label>
// //           Center Zip Code:
// //           <input
// //             type="text"
// //             value={centerZipCode}
// //             onChange={(e) => setCenterZipCode(e.target.value)}
// //           />
// //         </label>
// //       </div>
// //       <div>
// //         <label>
// //           Radius (meters):
// //           <input
// //             type="number"
// //             value={radius}
// //             onChange={(e) => setRadius(Number(e.target.value))}
// //           />
// //         </label>
// //       </div>
// //       <button onClick={handleSearch}>Search</button>
// //       {error && <p style={{ color: "red" }}>{error}</p>}
// //       <div>
// //         <h2>Nearby Zip Codes</h2>
// //         <ul>
// //           {zipCodes.map((zipCode, index) => (
// //             <li key={index}>{zipCode}</li>
// //           ))}
// //         </ul>
// //       </div>
// //     </div>
// //   );
// // };

// // export default ZipCodeSearch;

// // import React, { useState } from "react";
// // // import "./Accordion.css";

// // interface AccordionItemProps {
// //   title: string;
// //   content: string;
// // }

// // const AccordionItem: React.FC<AccordionItemProps> = ({ title, content }) => {
// //   const [isActive, setIsActive] = useState(false);

// //   const toggleAccordion = () => {
// //     setIsActive(!isActive);
// //   };

// //   return (
// //     <div>
// //       <button
// //         className={`accordion ${isActive ? "active" : ""}`}
// //         onClick={toggleAccordion}
// //       >
// //         {title}
// //         <span className="accordion-icon">{isActive ? "\u2212" : "\u002B"}</span>
// //       </button>
// //       <div className="panel" style={{ maxHeight: isActive ? "200px" : "0" }}>
// //         <p>{content}</p>
// //       </div>
// //     </div>
// //   );
// // };

// // const EditZone: React.FC = () => {
// //   const accordionData = [
// //     {
// //       title: "Section 1",
// //       content:
// //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
// //     },
// //     {
// //       title: "Section 2",
// //       content:
// //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
// //     },
// //     {
// //       title: "Section 3",
// //       content:
// //         "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
// //     },
// //   ];

// //   return (
// //     <div>
// //       <h2>Accordion with symbols</h2>
// //       <p>
// //         In this example we have added a "plus" sign to each button. When the
// //         user clicks on the button, the "plus" sign is replaced with a "minus"
// //         sign.
// //       </p>
// //       {accordionData.map((item, index) => (
// //         <AccordionItem key={index} title={item.title} content={item.content} />
// //       ))}
// //     </div>
// //   );
// // };

// import React, { useState } from 'react';
// import {
//   Drawer,
//   Button,
//   List,
//   ListItem,
//   ListItemText,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle
// } from '@mui/material';

// const EditZone: React.FC = () => {
//   // State for Main Drawer
//   const [isMainDrawerOpen, setMainDrawerOpen] = useState(false);

//   // State for Popup
//   const [isDialogOpen, setDialogOpen] = useState(false);

//   // State for Drawer Inside Popup
//   const [isPopupDrawerOpen, setPopupDrawerOpen] = useState(false);

//   // Toggle Main Drawer
//   const toggleMainDrawer = (open: boolean) => {
//     setMainDrawerOpen(open);
//   };

//   // Toggle Dialog
//   const toggleDialog = (open: boolean) => {
//     setDialogOpen(open);
//   };

//   // Toggle Drawer Inside Popup
//   const togglePopupDrawer = (open: boolean) => {
//     setPopupDrawerOpen(open);
//   };

//   return (
//     <div>
//       <Button onClick={() => toggleMainDrawer(true)}>Open Drawer</Button>
//       <Drawer anchor="left" open={isMainDrawerOpen} onClose={() => toggleMainDrawer(false)}>
//         <List>
//           <ListItem button>
//             <ListItemText primary="Item 1" />
//           </ListItem>
//           <ListItem button>
//             <ListItemText primary="Item 2" />
//           </ListItem>
//         </List>
//       </Drawer>

//       <Button onClick={() => toggleDialog(true)}>Open Popup</Button>
//       <Dialog open={isDialogOpen} onClose={() => toggleDialog(false)}>
//         <DialogTitle>Popup Title</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             This is a sample popup content.
//           </DialogContentText>
//           <img
//             src="your-image-url.jpg"
//             alt="Click to open drawer"
//             style={{ width: '100%', cursor: 'pointer' }}
//             onClick={() => togglePopupDrawer(true)}
//           />
//           <Drawer
//             anchor="right"
//             open={isPopupDrawerOpen}
//             onClose={() => togglePopupDrawer(false)}
//             PaperProps={{ sx: { zIndex: 1301 } }} // Ensure the drawer is above the dialog
//           >
//             <List>
//               <ListItem button>
//                 <ListItemText primary="Popup Drawer Item 1" />
//               </ListItem>
//               <ListItem button>
//                 <ListItemText primary="Popup Drawer Item 2" />
//               </ListItem>
//             </List>
//           </Drawer>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => toggleDialog(false)} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// export default EditZone;

// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface Postcode {
//   postcode: string;
//   latitude: number;
//   longitude: number;
// }

// const GOOGLE_MAPS_API_KEY = "AIzaSyA7Qp4xkUBD3WermtlLjxcvTnovMCUMrq4";

// const EditZone: React.FC = () => {
//   const [postcodes, setPostcodes] = useState<Postcode[]>([]);
//   const [lat, setLat] = useState<number>(51.5074); // Default latitude (London)
//   const [lng, setLng] = useState<number>(-0.1278); // Default longitude (London)
//   const [radius, setRadius] = useState<number>(1000); // Default radius in meters
//   const [loading, setLoading] = useState<boolean>(false);
//   const [error, setError] = useState<string | null>(null);

//   const fetchPostcodes = async () => {
//     setLoading(true);
//     setError(null);
//     try {
//       // Step 1: Reverse geocode to get the main postcode for the given coordinates
//       const geocodeResponse = await axios.get(
//         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
//       );

//       if (geocodeResponse.data.status !== "OK") {
//         throw new Error(`Geocoding API error: ${geocodeResponse.data.status}`);
//       }

//       const addressComponents =
//         geocodeResponse.data.results[0]?.address_components;
//       if (!addressComponents) {
//         throw new Error("No address components found");
//       }

//       const mainPostcode = addressComponents.find((component: any) =>
//         component.types.includes("postal_code")
//       );

//       if (!mainPostcode) {
//         throw new Error("No postcode found for the given coordinates");
//       }

//       // Step 2: Use a postcode API to find nearby postcodes
//       const nearbyResponse = await axios.get(
//         `https://api.postcodes.io/postcodes/${
//           mainPostcode.long_name
//         }/nearest?radius=${radius / 1000}&limit=100`
//       );

//       if (nearbyResponse.data.status !== 200) {
//         throw new Error(`Postcodes.io API error: ${nearbyResponse.data.error}`);
//       }

//       const postcodesData: Postcode[] = nearbyResponse.data.result.map(
//         (item: any) => ({
//           postcode: item.postcode,
//           latitude: item.latitude,
//           longitude: item.longitude,
//         })
//       );

//       setPostcodes(postcodesData);
//     } catch (error) {
//       console.error("Error fetching postcodes:", error);
//       setError("Failed to fetch postcodes.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchPostcodes();
//   }, [lat, lng, radius]);

//   return (
//     <div>
//       <h1>Edit Zone</h1>
//       <div>
//         <label>
//           Latitude:
//           <input
//             type="number"
//             value={lat}
//             onChange={(e) => setLat(Number(e.target.value))}
//           />
//         </label>
//         <label>
//           Longitude:
//           <input
//             type="number"
//             value={lng}
//             onChange={(e) => setLng(Number(e.target.value))}
//           />
//         </label>
//         <label>
//           Radius:
//           <input
//             type="number"
//             value={radius}
//             onChange={(e) => setRadius(Number(e.target.value))}
//           />
//         </label>
//       </div>
//       <button onClick={fetchPostcodes} disabled={loading}>
//         {loading ? "Fetching..." : "Fetch Postcodes"}
//       </button>
//       {error && <p style={{ color: "red" }}>{error}</p>}
//       <ul>
//         {postcodes.map((postcode, index) => (
//           <li key={index}>
//             {postcode.postcode}: (Lat: {postcode.latitude}, Lng:{" "}
//             {postcode.longitude})
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default EditZone;

// import React, { useState } from "react";
// import axios from "axios";

// const EditZone = () => {
//   const [inputText, setInputText] = useState("");
//   const [description, setDescription] = useState("");
//   const [image, setImage] = useState("");

//   // Function to generate product description
//   const generateDescription = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.openai.com/v1/chat/completions",
//         {
//           model: "gpt-3.5-turbo", // Text model for description generation
//           messages: [
//             {
//               role: "user",
//               content: `Generate a description for: ${inputText}`,
//             },
//           ],
//           max_tokens: 100,
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
//           },
//         }
//       );
//       setDescription(response.data.choices[0].message.content.trim());
//     } catch (error) {
//       console.error("Error generating description:", error);
//     }
//   };

//   // Function to generate product image
//   const generateImage = async () => {
//     try {
//       const response = await axios.post(
//         "https://api.openai.com/v1/images/generations",
//         {
//           prompt: `Generate an image of ${inputText}`, // Text prompt for the image
//           n: 1,
//           size: "1024x1024", // Image resolution
//         },
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer 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`,
//           },
//         }
//       );
//       setImage(response.data.data[0].url); // DALL·E returns a URL to the generated image
//     } catch (error) {
//       console.error("Error generating image:", error);
//     }
//   };

//   return (
//     <div>
//       <h1>Product Description & Image Generator</h1>
//       <input
//         type="text"
//         value={inputText}
//         onChange={(e) => setInputText(e.target.value)}
//         placeholder="Enter product name"
//       />
//       <button onClick={generateDescription}>Generate Description</button>
//       <button onClick={generateImage}>Generate Image</button>

//       {description && (
//         <div>
//           <h2>Description:</h2>
//           <p>{description}</p>
//         </div>
//       )}

//       {image && (
//         <div>
//           <h2>Generated Image:</h2>
//           <img src={image} alt="Generated product" />
//         </div>
//       )}
//     </div>
//   );
// };

// export default EditZone;

// import React, { useState } from "react";
// import axios from "axios";

// const EditZone: React.FC = () => {
//   const [csvFile, setCsvFile] = useState<File | null>(null);
//   const [uploadMessage, setUploadMessage] = useState<string>("");
//   const [downloadUrl, setDownloadUrl] = useState<string | null>(null); // State to store the ZIP file URL

//   // Handle file selection
//   const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const file = event.target.files?.[0];
//     if (file) {
//       console.log(file.type, "file.type");

//       if (file.type === "text/csv") {
//         setCsvFile(file);
//         setUploadMessage("");
//         setDownloadUrl(null); // Reset download state when selecting a new file
//       } else {
//         setUploadMessage("Please upload a valid CSV file.");
//         setCsvFile(null);
//       }
//     }
//   };

//   // Handle file upload
//   const handleUpload = async () => {
//     if (!csvFile) {
//       setUploadMessage("No file selected");
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", csvFile);
//     formData.append("menu_id", "5");
//     formData.append("location_id", "44");
//     formData.append("domain", "toppinz.smartserver.solutions");

//     try {
//       // POST the CSV file and expect a ZIP file response
//       const response = await axios.post(
//         // "https://apidomain.bayforall.co.uk/v1/customer/generate_table_qrimages"
//         "https://apidomain.bayforall.co.uk/v1/customer/generate_table_qrimages?domain=toppinz.smartserver.solutions&location_id=44&menu_id=5",
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//           responseType: "blob", // Expect a binary response (ZIP file)
//         }
//       );

//       // Create a URL for the downloaded ZIP file
//       const url = window.URL.createObjectURL(
//         new Blob([response.data], { type: "application/zip" })
//       );
//       setDownloadUrl(url); // Set the download URL
//       setUploadMessage("Upload successful. You can now download the ZIP file.");
//     } catch (error) {
//       console.error("File upload/download error: ", error);
//       setUploadMessage("File upload failed. Please try again.");
//     }
//   };

//   // Handle file download
//   const handleDownload = () => {
//     if (downloadUrl) {
//       const link = document.createElement("a");
//       link.href = downloadUrl;
//       link.setAttribute("download", "tables_qr_codes.zip"); // Specify the filename
//       document.body.appendChild(link);
//       link.click(); // Trigger the download
//       document.body.removeChild(link); // Clean up the link
//     }
//   };

//   return (
//     <div>
//       <h2>Upload CSV File</h2>
//       <input type="file" accept=".csv" onChange={handleFileChange} />
//       {csvFile && <p>Selected file: {csvFile.name}</p>}
//       <button onClick={handleUpload}>Upload</button>
//       {uploadMessage && <p>{uploadMessage}</p>}

//       {/* Show the Download button only if the ZIP file URL is available */}
//       {downloadUrl && (
//         <button onClick={handleDownload}>Download ZIP File</button>
//       )}
//     </div>
//   );
// };

// export default EditZone;

import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Paper,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F38B08",
    },
  },
});

const DropZone = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const EditZone = () => {
  const [file, setFile] = useState<any>(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileDrop = useCallback((event: any) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    handleFile(droppedFile);
  }, []);

  const handleFileSelect = useCallback((event: any) => {
    const selectedFile = event.target.files[0];
    handleFile(selectedFile);
  }, []);

  const handleFile = (selectedFile: any) => {
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please select a CSV file");
      setFile(null);
    }
  };

  const handleUpload = () => {
    if (!file) {
      alert("Please select a file first");
      return;
    }

    setUploading(true);
    setUploadProgress(0);

    const interval = setInterval(() => {
      setUploadProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setUploading(false);
          setUploadSuccess(true);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 500);
  };

  const handleDownload = () => {
    // Implement your download logic here
    console.log("Downloading file...");
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: 400, mx: "auto", mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          File Upload
        </Typography>
        <DropZone
          onDrop={handleFileDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <CloudUploadIcon
            sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
          />
          <Typography variant="body1" gutterBottom>
            Drag and drop your CSV file here
          </Typography>
          <Typography variant="body2" color="textSecondary">
            or
          </Typography>
          <label htmlFor="file-input">
            <Button
              component="span"
              variant="outlined"
              color="primary"
              sx={{ mt: 2 }}
            >
              Select File
            </Button>
          </label>
          <HiddenInput
            id="file-input"
            type="file"
            accept=".csv"
            onChange={handleFileSelect}
          />
        </DropZone>
        {file && (
          <Typography variant="body2" sx={{ mt: 2 }}>
            Selected file: {file.name}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleUpload}
          disabled={!file || uploading}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
        {uploading && (
          <Box sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" sx={{ mt: 1, textAlign: "center" }}>
              {uploadProgress}% uploaded
            </Typography>
          </Box>
        )}
        {uploadSuccess && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body1"
              color="primary"
              sx={{ textAlign: "center", mb: 2 }}
            >
              File uploaded successfully!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
            >
              Download
            </Button>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default EditZone;
