import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  LinearProgress,
  Paper,
  ThemeProvider,
  createTheme,
  Modal,
} from "@mui/material";
import { styled, width } from "@mui/system";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";

const theme = createTheme({
  palette: {
    primary: {
      main: "#F38B08",
    },
  },
});

const DropZone = styled(Paper)(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  textAlign: "center",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const HiddenInput = styled("input")({
  display: "none",
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface State {
  openForImportPopup: boolean;
  handleCloseForImportPopup: () => void;
  menuId: string | undefined;
  domainName: string | null;
  subDomainName: string | null;
  locationId: string;
  downloadUrl: string | null;
  file: any;
  uploadProgress: number;
  uploading: boolean;
  failedMsg: boolean;
  uploadSuccess: boolean;
  setDownloadUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setFile: React.Dispatch<React.SetStateAction<any>>;
  uploadMessage: string;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadProgress: React.Dispatch<React.SetStateAction<number>>;
  setUploadSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setUploadMessage: React.Dispatch<React.SetStateAction<string>>;
  setFailedMsg: React.Dispatch<React.SetStateAction<boolean>>;
  setDownlaodFileName: React.Dispatch<React.SetStateAction<string>>;
  downlaodFileName: string;
}

const ImportPopup = (props: State) => {
  const {
    downlaodFileName,
    setDownlaodFileName,
    openForImportPopup,
    uploadMessage,
    handleCloseForImportPopup,
    domainName,
    locationId,
    menuId,
    subDomainName,
    downloadUrl,
    file,
    uploadProgress,
    failedMsg,
    uploading,
    uploadSuccess,
    setDownloadUrl,
    setFile,
    setUploading,
    setUploadProgress,
    setUploadSuccess,
    setUploadMessage,
    setFailedMsg,
  } = props;

  const handleFileSelect = useCallback((event: any) => {
    const selectedFile = event.target.files[0];
    setDownlaodFileName("");
    setUploading(false);
    setUploadProgress(0);
    setUploadSuccess(false);
    setFailedMsg(false);
    setUploadMessage("");
    setDownloadUrl(null);
    setFile(null);
    handleFile(selectedFile);
  }, []);

  const handleFile = (selectedFile: any) => {
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      alert("Please select a CSV file");
      setFile(null);
    }
  };

  const handleDownloadForSampleData = () => {
    // if (downloadUrl) {
    //   // Open a new window and trigger the download
    //   window.open(downloadUrl, "_blank");
    // }
    window.open("", "_blank");
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first");
      return;
    }
    setUploading(true);
    setUploadProgress(0);
    setUploadSuccess(false);
    const interval = setInterval(() => {
      setUploadProgress((prevProgress: any) => {
        if (prevProgress >= 90) {
          clearInterval(interval);

          return 90;
        }
        return prevProgress + 10;
      });
    }, 1000);

    const formData = new FormData();
    formData.append("file", file);
    if (menuId) {
      formData.append("menu_id", menuId);
    }
    formData.append("location_id", locationId);
    if (domainName) {
      formData.append("domain", domainName);
    } else {
      formData.append("domain", `${subDomainName}.smartserver.solutions`);
    }

    try {
      const response = await axios.post(
        "https://apidomain.bayforall.co.uk/v1/menu_setup/generate_table_qrimages",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob", // Correct for handling binary data
          timeout: 300000,
        }
      );

      console.log(response, "response");

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "application/zip" });
        const downloadUrl = URL.createObjectURL(blob);
        setDownloadUrl(downloadUrl);
        const getFileName = (header: any) => {
          const match = header.match(/filename[^;=\n]*=(['"]?)([^'";\n]+)\1/);
          return match ? match[2] : null;
        };
        const contentDisposition = response.headers["content-disposition"];

        const fileName = getFileName(contentDisposition);
        setDownlaodFileName(fileName);

        // Create a link and trigger download
        // const link = document.createElement("a");
        // link.href = downloadUrl;
        // link.setAttribute("download", "qr_codes_44.zip"); // Use the filename from Content-Disposition
        // document.body.appendChild(link);
        // link.click();
        // link.remove();

        setUploadProgress(100);
        setUploadSuccess(true);
      }
    } catch (error) {
      console.log(error, "error");
      setFailedMsg(true);
      setUploadMessage("File Import failed. Please try again.");
    } finally {
      clearInterval(interval); // Ensure the interval is cleared
      setUploading(false);
    }

    // try {
    //   const response = await axios.post(
    //     // "https://apidomain.bayforall.co.uk/v1/menu_setup/generate_table_qrimages",
    //     "https://apidomain.bayforall.co.uk/v1/menu_setup/generate_table_qrimages_cloudinary",
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //       // responseType: "blob",
    //       timeout: 300000,
    //       onUploadProgress: (progressEvent) => {
    //         if (progressEvent.total) {
    //           const percentCompleted = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );

    //           setUploadProgress(percentCompleted);
    //         }
    //       },
    //     }
    //   );

    //   console.log(response, "response");

    //   if (response.status === 200) {
    //     const { data } = response;
    //     console.log(data, "data");
    //     if (data) {
    //       const { image_url } = data;
    //       console.log(image_url);
    //       setDownloadUrl(image_url);
    //     }

    //     setUploadProgress(100);
    //     setUploadSuccess(true);
    //   }
    // } catch (error) {
    //   setFailedMsg(true);
    //   setUploadMessage("File Import failed. Please try again.");
    // } finally {
    //   clearInterval(interval); // Ensure the interval is cleared
    //   setUploading(false);
    // }
  };

  const handleDownload = () => {
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;

      link.setAttribute("download", downlaodFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // const handleDownload = () => {
  //   console.log(downloadUrl, "downloadUrl");
  //   if (downloadUrl) {
  //     const link = document.createElement("a");
  //     link.href = downloadUrl;

  //     // Ensure the file name has a .zip extension
  //     const fileName = downlaodFileName ? downlaodFileName : "downloaded_file";
  //     const zipFileName = fileName.endsWith(".zip")
  //       ? fileName
  //       : `${fileName}.zip`;

  //     link.setAttribute("download", zipFileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // };

  return (
    <Box>
      <Modal
        open={openForImportPopup}
        onClose={handleCloseForImportPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Box sx={{ textAlign: "center", marginBottom: "30px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,

                  color: "#333",
                }}
              >
                Import Tables/Seat Numbers for QR Menu Code Generation
              </Typography>
            </Box>
            <Box
              sx={{ maxWidth: 450, mx: "auto", mt: 2, fontFamily: "Poppins" }}
            >
              {/* <DropZone
                onDrop={handleFileDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
                />
                <Typography variant="body1" gutterBottom>
                  Select Your CSV file here
                </Typography>

                <label htmlFor="file-input">
                  <Button
                    component="span"
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2, textTransform: "none" }}
                  >
                    Select File
                  </Button>
                </label>
                <HiddenInput
                  id="file-input"
                  type="file"
                  accept=".csv"
                  onChange={handleFileSelect}
                />
              </DropZone> */}
              <DropZone
                onClick={() => document.getElementById("file-input")?.click()}
              >
                <CloudUploadIcon
                  sx={{ fontSize: 48, color: "primary.main", mb: 2 }}
                />
                <Typography variant="body1" gutterBottom>
                  Select Your CSV file here
                </Typography>

                <Button
                  component="span"
                  variant="outlined"
                  color="primary"
                  sx={{ mt: 2, textTransform: "none" }}
                >
                  Select File
                </Button>
                <HiddenInput
                  id="file-input"
                  type="file"
                  accept=".csv"
                  onChange={handleFileSelect}
                />
              </DropZone>

              {file && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Selected file: {file.name}
                </Typography>
              )}

              {uploading && (
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "center", mt: 1 }}
                >
                  Please wait while processing your request.
                </Typography>
              )}
              {!uploadSuccess && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ textTransform: "none", mt: 2 }}
                  onClick={handleUpload}
                  disabled={!file || uploading}
                >
                  {uploading ? "Uploading..." : "Upload"}
                </Button>
              )}

              {/* {uploading && (
                <Box sx={{ mt: 2 }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, textAlign: "center" }}
                  >
                    {uploadProgress}% uploaded
                  </Typography>
                </Box>
              )} */}
              {uploading && (
                <Box sx={{ mt: 2 }}>
                  <LinearProgress
                    variant="determinate"
                    value={uploadProgress}
                  />
                  <Typography
                    variant="body2"
                    sx={{ mt: 1, textAlign: "center" }}
                  >
                    {uploadProgress}% uploaded
                  </Typography>
                </Box>
              )}
              {uploadSuccess && (
                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ textAlign: "center", mb: 2 }}
                  >
                    Generated QR codes for tables/seats. Click download button.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ textTransform: "none" }}
                    startIcon={<DownloadIcon />}
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </Box>
              )}

              {failedMsg && (
                <Typography
                  variant="body1"
                  color="primary"
                  sx={{ textAlign: "center", mb: 2 }}
                >
                  {uploadMessage}
                </Typography>
              )}
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ marginTop: "15px" }}>
                  Download Sample CSV File for Reference
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center" }}>
              <Button
                onClick={handleDownloadForSampleData}
                sx={{
                  textTransform: "none",
                  fontSize: "12px",
                }}
                variant="text"
              >
                (Click here to view the required format for importing
                Tables/Seat Numbers)
              </Button>
            </Box>
          </ThemeProvider>
        </Box>
      </Modal>
    </Box>
  );
};

export default ImportPopup;
